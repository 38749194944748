.table-wrapper table {
  table-layout: fixed !important; /* rewrite inline styles */
  position: absolute;
}

.table-wrapper .ant-table-thead {
  position: sticky;
}

.table-wrapper .ant-table-thead .ant-table-cell {
  background: rgb(0, 150, 255);
  color: white;
}

.sticky-header .ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
