$primary-light: #DEEFF9;
$primary: #1F95D9;
$primary-dark: #1E3FAE;
$light-blue : #67A0E2;

$white: #FFFFFF;
$black: #000000;
$gray: #F2F2F2;
$midnight : #06123E;
$base-space: 15px;

$font: 'sf_pro_displayregular';