.Add__button {
  width: 103px;
  /* text-align: center; */
  white-space: initial;
  height: 33px;
  width: 132px;

  border-radius: 5px;
  border: 1px solid #ceeaf9;
  color: #2a9bdb;
  font-size: 11px;
  padding-left: 10px;
  position: relative;
  background-color: #ceeaf9;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.Table__list {
  margin-top: 14px;
  width: 100%;

  .ant-table {
    border: 1px solid #f2f2f2;
  }

  .ant-table-thead > tr > th {
    background-color: #486cdf;
    text-align: center;
    color: white;
    font-family: sans-serif;
    font-size: 11px;
    padding: 0px;
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 90px;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: white;
    color: blue;
  }
  .ant-table-tbody > tr > td {
    line-height: 16px;
    height: 27px;
    color: black;
    font-size: 11px;
    font-weight: 450;
    text-align: center;
    padding: 1px 2px 3px 2px;
  }
  .ant-table-column-sorters-with-tooltip {
    height: 37px;
  }
  .ant-table-column-sorters {
    padding: 16px;
    margin-top: -4px;
  }
}
tr:nth-child(even) {
  background: #ffffff;
}
tr:nth-child(odd) {
  background: #f2f2f2;
}

//for styling all tables in admin
.admin__table__wrapper {
  padding-left: 23px;
  margin-top: 14px;
}

//for odd and even coloring in table  //applies in all of the table across application
.ant-table {
  .ant-table-tbody > tr > td:first-child {
    text-align: left;
    // padding-left: 30px !important;
  }
  tr:nth-child(even) > {
    background: #ffffff;
  }
  tr:nth-child(odd) {
    background: #f2f2f2;
  }
}
.call_table .ant-table-tbody > tr > td {
  height: auto !important;
}

.attendance-table {
  .ant-table-tbody > tr > td:first-child {
    width: 5% !important;
  }
  .ant-table-tbody > tr > td:nth-child(2) {
    width: 15% !important;
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    width: 12% !important;
  }
  .ant-table-tbody > tr > td:nth-child(4) {
    width: 12% !important;
  }
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #486cdf;
}

.Table__list .ant-table {
  counter-reset: Count-Value;
  tr td:first-child:before {
    counter-increment: Count-Value;
    content: counter(Count-Value);
  }
}

.remove-counter .ant-table {
  counter-reset: unset;
  tr td:first-child:before {
    counter-increment: unset;
    content: unset;
  }
}

.clickRowStyl {
  background-color: #67a1e4 !important;
}
.ant-table-row.ant-table-row-level-0.clickRowStyl td span {
  color: #fff;
}
.organizer__table contact-table {
  .ant-table-column-sorters-with-tooltip {
    text-align: left;
  }
  .organizer__table .ant-table-tbody > tr > td {
    color: #303030;
    width: 48px !important;
    font-size: 11px;
    font-weight: 300;
    text-align: center;
    border: none;
    text-align: left;
  }
  .organizer__table .ant-table-thead > tr > th {
    text-align: left;
    background-color: #d8ddeb;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    color: #06123e;
    font-weight: bold;
    line-height: 6px;
    white-space: nowrap;
    border-top-right-radius: 0px;
  }
}

.contact-table.user-list-table {
  padding: 0px;
  margin: 0px;
}
