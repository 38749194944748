*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font;
  font-weight: 500;
  letter-spacing: 0.7px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.p {
  font-size: 12px;
}

.m-auto {
  margin: auto !important;
}

td.ant-table-column-sort {
  background: inherit !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.text-dark {
  color: #303030 !important;
}

// scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.ant-tree {
  background: inherit;
}

::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #f3f3f3;
}

.fc a {
  color: inherit;
  text-decoration: none;
}

.show-cursor {
  cursor: pointer;
}

#uzz {
  height: 100%;

  // overflow: hidden;
}

.user-wrapper-body {
  width: calc(100% - 299px);
  overflow: hidden;
}

.container {
  width: calc(100% - 72px);
  margin-left: 72px;
  display: block;
  height: auto;
}
.sidebars.email {
  height: 100%;
}
.organizer.email {
  height: 100%;
}
.sidebar-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  padding-left: 10px;
  height: 48px;
  display: flex;
  align-items: center;
  box-shadow: -4px 1px 3.6px rgb(169 164 164 / 11%),
    0px -6.4px 14.4px rgb(230 221 221 / 13%);
  margin-bottom: 0px;
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  box-shadow: inset 0px -0.7px 0px rgb(0 0 0 / 15%);
}

.layout-title {
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.ant-menu-inline {
  border-right: 0px !important;
}

.dashboard {
  display: flex;
  align-items: center;
  background-color: #a3ccf3;
  min-height: 100vh;
  overflow: auto;
}

.text-align-right {
  text-align: right;
}

.ck-editor__editable_inline {
  min-height: 250px;
}

.gutter-horizontal {
  // width: 5px !important;
  background: #ffffff;
  box-shadow: inset 1px 0px 0px rgba(199, 199, 199, 0.5);
  // // background: rgb(226, 226, 230);
  cursor: e-resize;
}

.unread-email {
  width: 8px;
  height: 8px;
  background-color: #1f95d9;
  border-radius: 50%;
  display: -ms-inline-flexbox;
  /* display: inline-flex; */
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  // margin-left: 3px;
  // margin-top: 5px;
}

html {
  scroll-behavior: smooth;
}

.disabled {
  pointer-events: none;
}

#notification {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 200px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transform: rotate(-90deg);
}

.navbar-icons {
  .ant-badge {
    width: 100%;
  }
  .ant-badge-count {
    right: 18px !important;
    min-width: 15px;
    height: 15px;
    line-height: 15px;
    padding: 0 3px !important;
    font-size: 10px;
    z-index: 9;
    top: 12px;
  }
}

.removeNavs {
  width: 100%;
  margin-left: 0;
}
.ck.ck-toolbar {
  border: 0;
  padding-left: 0px;
  // border-top: 1px solid var(--ck-color-toolbar-border) !important;
  border: none;
  margin-bottom: -9px;
  background-color: transparent;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: transparent;
  padding-left: 20px;
  border: none;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  outline: none;
  border-color: transparent;
  box-shadow: unset;
  padding-left: 20px;
}
.ck.ck-button.ck-button_with-text,
a.ck.ck-button.ck-button_with-text {
  padding: 0px;
}
.ck.ck-dropdown .ck-button.ck-dropdown__button {
  padding-left: 0px;
}
.bottom-borders {
  margin-bottom: 6px;
  border-bottom: 0.5px solid #ebebeb;
}
.outline-grayborders {
  border: 0.5px solid #ebebeb;
  padding: 0px 2px 0px 2px;
}

.ContactDetails__header {
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  box-shadow: inset 0px -1px 0px rgb(0 0 0 / 15%);
}
span.ant-input-affix-wrapper.ant-input-password {
  margin: 0px !important;
  padding: 0px !important;
  padding-right: 5px !important;
}
td {
  text-align: left !important;
}
th {
  text-align: left !important;
}

.form-required-false label {
  margin-left: 11.70001px;
}

.form-required-false-common label {
  width: calc(100% - 11.70001px);
  margin-left: 11.70001px;
}

.form-required-true-common label {
  width: 100%;
}

.text-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
