.move-to-dailydairy {
    display: flex;
    align-items: center;
    align-self: center;
    padding-left: 10px,
}


.daily-diary-icon {
    margin-left: 12px;
}
