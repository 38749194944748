.organizer {
  .ant-row .ant-col {
    width: 100%;
    padding: 0px !important;
    // padding-right: 8px;
    // padding-left: 8px;
  }

  .header__all {
    // margin-bottom: 10px;
    // padding-left: 10px;
    // display: flex;
    // height: 50px;
    // align-items: center;
    // border-bottom: 1px solid #eee;

    display: flex;
    font-weight: bold;
    width: 100%;
    justify-content: space-between;
    margin: 0px;

    .title {
    }
    button.calendar-create {
      background: white;
      color: rgba(197, 61, 56, 1);
      font-weight: 600;

      border: none;
    }
  }

  &__divider {
    border-right: 0;
    width: 100%;

    @media (min-width: 1024px) {
      border-right: 1px solid $gray;
      max-width: 46%;
    }
  }

  .gray__form {
    margin-bottom: 1rem;
    position: relative;
    &.meetingEventActive {
      background-color: #dfefe7;
    }
  }

  .switch-div {
    display: flex;
    align-items: center;
    button {
      margin-right: 5px;
    }
  }

  &__list {
    @media (max-width: 992px) {
      margin: 10px 0;
    }

    &--body {
      overflow-x: hidden;
      height: 72vh;
      margin: 5px 0;
      width: 100%;

      @media (max-width: 992px) {
        height: auto;
      }
    }

    &--header {
      font-size: 14px;
      font-weight: 600;
      color: #6d6d6d;
      text-transform: uppercase;
    }
  }

  &__card {
    &--body {
      margin-top: 7px;

      .card-title {
        font-weight: 600;
        font-size: 15px;
        margin: 3px 0;
      }

      .card-notes {
        font-size: 14px;
        font-weight: 400;
        margin: 2px 0;

        span {
          color: #887a96;
          cursor: pointer;
        }
      }

      .card-date {
        color: #6d6d6d;
      }

      .tooltips {
        position: absolute;
        top: 15px;
        right: 15px;
      }

      .mark-it-off {
        position: absolute;
        top: 11.5px;
        right: 40px;
        cursor: pointer;
      }
    }
  }
}

//Holiday
.organizerHoliday {
  &__top {
    display: block;
    justify-content: space-between;
    align-items: end;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  &__middle {
    display: flex;
    margin-bottom: 10px;

    &--wrap {
    }
  }
}

// Background Color
.gray__form {
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;

  &.gray-margin {
    margin-bottom: 0;
  }

  .ant-divider-horizontal {
    margin: 16px 0;
  }
}

// Goal form
.goals {
  .ant-divider-horizontal {
    margin: 0;
  }
}

//Appointments Froms
.appointments {
  .organizer__list--body {
    height: 85vh;

    @media (max-width: 992px) {
      height: auto;
    }
  }
}
.task-D-item {
  .ant-form-item-control-input {
    .ant-select-selection-item {
      color: black !important;
    }
  }
}
.switch_div_show_completed_tender_task {
  display: flex;
  justify-content: end;
  margin-top: 12px;
  margin-right: 10px;
}
.guest-actions {
  .guest-act {
    cursor: pointer;
  }
}

//Events
.events {
  &__button {
    margin-bottom: 10px;
  }
}

//meetings
.meetings {
  &__flex {
    display: flex;
    align-items: center;

    .header__all {
      margin-bottom: 0;
    }

    .btn-primary {
      width: auto;
      margin-left: 10px;
    }
  }

  &__table {
    .btn-primary {
      width: auto;
      margin-bottom: 10px;
    }
  }

  .agenda {
    .ant-input,
    .ant-input-number-input,
    .ant-input-number,
    .ant-picker,
    .ant-select-selector,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      padding: 2px 0;
      border-width: 0 0 1px 0;
      border-radius: 0 !important;
      border-right-width: 0px !important;
      height: 26px;
      background: transparent;

      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
        border-right-width: 0px !important;
        border-radius: 0;
      }
    }
    .ant-picker-focused,
    .ant-select-open,
    .ant-select-selector {
      box-shadow: none;
      outline: none;
      border-right-width: 0px !important;
      border-radius: 0;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      line-height: 21px;
    }

    &.selects .ant-select-selector {
      height: auto !important;
    }
  }

  .tableList {
    display: flex;
  }

  .meetingLists {
    width: 100%;
    transition: width 0.4s cubic-bezier(0.65, 0.005, 0.35, 0.995);

    &.small {
      width: 380px;
      padding-right: 10px;
      transition: width 0.4s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
  }

  .meetingsTable {
    width: 0%;
    transition: width 0.4s cubic-bezier(0.65, 0.005, 0.35, 0.995);

    &.small {
      width: calc(100% - 360px);
      padding-left: 10px;
      transition: width 0.4s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
  }
}

.todo__card--status,
.appointments__card--status {
  width: calc(100% - 43px);
}

.organizer.meetings {
  padding: 0px 7px;
  padding-top: 7px;
}

.organizer.fullCalendar {
  padding: 0px 7px;
}

.business_action_button_wrapper {
  justify-content: right;
  column-gap: 10px;
}
.business_action_button_wrapper {
  justify-content: right;
  column-gap: 10px;
  margin-top: 10px;
}
.myTask .ant-menu-submenu-title {
  background-color: #d8ddeb !important;
  padding: 0px !important;
  padding: 4px 7px !important;
  color: #06123e !important;
  font-weight: 700;
  font-size: 13px;
  height: 31px !important;
  line-height: 26px !important;
  margin: 0px !important;
  border-bottom: 1px solid #d9d9d9;
}
.myTask .ant-menu-submenu-title:hover {
  background: #67a1e4 !important;
  color: #fff !important;
}
li.ant-menu-item:hover {
  background: #f2f2f2 !important;
  color: #6d6d6d !important;
}
li.ant-menu-item.ant-menu-item-only-child.sidebars__menu--item.ant-menu-item-selected {
  background: #67a1e4 !important;
  color: #fff !important;
}
.myTask span.anticon.anticon-right.ant-collapse-arrow {
  top: 8px !important;
}
.myTask li.ant-menu-item.ant-menu-item-only-child:hover {
  background: #f2f2f2;
  color: #111;
}
.myTask .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #67a1e4 !important;
}

.organizer-sidebar .ant-col.ant-col-24 {
  padding: 0px !important;
}
.myTask #sub1\$Menu li {
  padding-left: 19npmpx !important;
  line-height: 30px;
  height: 28px;
  margin: 0px !important;
  color: #303030;
  font-size: 12px;
  line-height: 28px;
  font-weight: normal;
}
.myTask .ant-menu-submenu-open.ant-menu-submenu-selected ul#sub1\$Menu {
  height: calc(98.5vh - 220px);
  overflow-y: auto;
}

.organizer.meetings,
.all-header-wrapper {
  padding: 10px 7px;
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff !important;
  box-shadow: inset 0px -0.7px 0px rgba(0, 0, 0, 0.15) !important;
}

.organizer.fullCalendar {
  padding: 10px 7px;
}

.business_action_button_wrapper {
  justify-content: right;
  column-gap: 10px;
}
.business_action_button_wrapper {
  justify-content: right;
  column-gap: 10px;
  margin-top: 10px;
}
.organizer.fullCalendar {
  padding: 0;
}
.organizer.contacts .ant-row {
  //background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  // box-shadow: inset 0px -0.7px 0px rgba(0, 0, 0, 0.15);
}
.organizer.fullCalendar .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  box-shadow: inset 0px -0.7px 0px rgba(0, 0, 0, 0.15);
  padding: 4.8px 7px;
  margin: 0;
}

.full-calender-body .fc-daygrid.fc-dayGridMonth-view.fc-view {
  padding: 0px 7px;
  margin-top: 10px;
}
.fc-daygrid-event-harness {
  overflow: hidden;
  text-overflow: ellipsis;
}
.fc-event-time {
  color: #fff;
}
.fc-event-title-container {
  color: #fff;
}
.fc-today-button.fc-button.fc-button-primary {
  background: #d8ddeb;
  border: 0px;
  color: #2563aa !important;
  opacity: 1;
}
.full-calender-body .fc-button-group button {
  background: #d8ddeb !important;
  border: 0px !important;
  color: rgb(37, 99, 170) !important;
  font-weight: 500;
}
.panel-nav-wrapper label {
  text-align: left;
  background: none;
  border: 0px;
  padding: 0px;
  box-shadow: none;
  height: 28px;
  line-height: 28px;
}
.panel-nav-wrapper label span {
  color: #303030;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.7px;
}
.panel-nav-wrapper label:hover {
  background: #f2f2f2;
}
.panel-nav-wrapper label.ant-radio-button-wrapper-checked {
  background: #67a1e4;
  color: #fff !important;
  border: 0px;
  border-radius: 0px;
}
.panel-nav-wrapper label.ant-radio-button-wrapper-checked span {
  color: #fff;
}
.organizer__table table thead tr th {
  padding: 12px 7px !important;
}
.panel-nav-wrapper label span {
  padding-left: 21px;
}
.organizer.contacts .organizer__table table thead tr th {
  padding: 12px 7px !important;
  border-bottom: 1px solid #d8ddeb;
}
.organizer-checkbox {
  width: 100%;
  margin-top: 10px;
  margin-left: 15px;
}
.organizer.fullCalendar .header__all {
  padding: 10px 7px;
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  box-shadow: inset 0px -0.7px 0px rgba(0, 0, 0, 0.15);
}
.organizer__tables {
  padding: 0px 7px;
  margin-top: 15px;
}
.organizer__tables table thead {
  height: 31px;
}
.organizer__tables table thead tr th {
  padding: 0px 7px !important;
  background: #d8ddeb !important;
  color: #06123e !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
.organizer__tables table tbody tr td {
  padding: 0px 7px !important;
  height: 31px;
  font-size: 13px !important;
}
.organizer__tables1 {
  padding: 0px 7px;
  margin-top: 15px;
}
.organizer__tables1 table thead {
  height: 35px;
}
.organizer__tables1 table thead tr th {
  padding: 0px 7px !important;
  background: #d8ddeb !important;
  color: #06123e !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}
.organizer__tables1 table tbody tr td {
  padding: 0px 7px !important;
  height: 35px !important;
  font-size: 13px !important;
}
.next-organizer-table table thead tr th {
  background: #d8ddeb !important;
  font-size: 15px;
}
.next-organizer-table table tbody tr td {
  font-size: 14px;
}
.next-organizer-table1 table thead tr th {
  background: #d8ddeb !important;
  font-size: 13px;
}
.next-organizer-table1 table tbody tr td {
  font-size: 12px;
}
.call_table table tbody {
  height: 50px !important;
}
.excelTable table tbody {
  height: 130px;
}
.contactDetails__split--pagination .organizer__table.contact-table {
  padding: 0px !important;
  margin-top: 0;
  margin: 0px;
}
.organizer-sidebar .contact__Detailsfav {
  display: flex;
  align-items: center;
  border-right: 1px solid #ccc;
  width: 20px;
}
.organizer__list.todo__list {
  padding: 8px 6px;
  height: 48px;
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  box-shadow: inset 0px -0.7px 0px rgba(0, 0, 0, 0.15);
  .ant-row {
    display: flex;
    align-items: center;
    .ant-col.ant-col-sm-12.ant-col-lg-4 {
      padding: 0 !important;
      .ant-select-selector {
        padding-left: 0px !important;
      }
    }
    .ant-col.switch-div {
      font-weight: 700;
      font-size: 14px !important;
      color: #495057;
      margin-left: auto;
      text-align: right;
    }
  }
}
.todo__list button {
  margin-right: 5px;
}

.user-wrapper {
  .user-list-header {
    display: flex;
    align-items: center;
    padding: 10px 7px;
    background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff !important;
    box-shadow: inset 0px -0.7px 0px rgb(0 0 0 / 15%) !important;
    .organizerHoliday__middle {
      padding: 0px !important;
      margin: 0px !important;
    }
  }
  span.ant-input-group-wrapper.ant-input-search.ant-input-search-medium.form__groups {
    padding: 0px 7px;
    margin-top: 10px;
  }
}
.all-header-wrapper .meeting__flex {
  display: flex;
  align-items: center;
}
.emailDetails__body--chatwrap,
.task_table th {
  tr {
    td {
      &:nth-child(2) {
        width: auto !important;
      }
      &:nth-child(3) {
        width: auto !important;
      }
      &:nth-child(4) {
        width: auto !important;
      }
      &:nth-child(5) {
        width: auto !important;
      }
      &:nth-child(6) {
        width: auto !important;
      }
    }
  }
}
// .task__body {
//   table {
//     tr {
//       td {
//         &:nth-child(2) {
//           width: 8% !important;
//         }
//         &:nth-child(3) {
//           width: 12% !important;
//         }
//         &:nth-child(4) {
//           width: 10% !important;
//         }
//         &:nth-child(5) {
//           width: 8% !important;
//         }
//         &:nth-child(6) {
//           width: 8% !important;
//         }
//       }
//     }
//   }
//   // .task-table.openChat {
//   //   width: calc(100% - 557px);
//   // }
//   .chatmail__header--wrapper-hide.chatmail__image {
//     position: fixed;
//     top: 48px;
//     right: 0px;
//     background: #fafafa;
//     width: 35px !important;
//     height: calc(100% - 48px);
//     padding: 10px 0px;
//     text-align: center;
//     img {
//       width: 15px;
//     }
//   }
//   .emailDetails__body--chat {
//     transition: 0s;
//     top: 48px;
//     height: calc(100% - 48px);
//   }
// }

// calendar search pagintion
.search_events {
  margin-top: 5px;
  overflow: auto;
  height: 700px;
  scroll-behavior: auto;
  /* visibility: visible; */
}
@media only screen and (max-width: 1500px) {
  .task__body {
    .task-table.openChat {
      width: calc(100% - 417px);
    }
  }
}
// .global-internal-message-body{
//   .emailDetails__body--chatwrap {
//       height: calc(100% - 82px);
//   }
//   .emailDetails__body--chatwrap.isOpenReplyChat {
//     height: calc(100% - 300px);
//   }
// }

.complain-table {
  // height: 40vh;

  overflow-x: scroll;
  span.ant-select-selection-item {
    position: relative;
    top: 2px;
  }
  .ant-table-thead tr th {
    position: sticky;
    top: 0;
    z-index: 99;
  }
  .ant-table-thead tr th:nth-child(5) {
    text-align: left;
  }
  .ant-table-tbody {
    overflow-y: auto;
    max-height: 700px;
  }
  .call_table .ant-table-tbody > tr > td {
    height: auto !important;
  }

  .ant-table-tbody > tr > td:first-child {
    width: 13% !important;
  }
  .ant-table-tbody > tr > td:nth-child(2) {
    width: 8% !important;
  }
  .ant-table-tbody > tr > td:nth-child(3) {
    width: 9% !important;
  }
  .ant-table-tbody > tr > td:nth-child(4) {
    width: 14% !important;
  }
  .ant-table-tbody > tr > td:nth-child(5) {
    width: 10% !important;
  }
  .ant-table-tbody > tr > td:nth-child(6) {
    width: 16% !important;
  }
  .ant-table-tbody > tr > td:nth-child(6) {
    text-align: left;
  }
  .ant-table-tbody > tr > td:nth-child(7) {
    width: 25% !important;
  }
}
button.view-complain-remark {
  background: none;
  border: 0;
  margin-right: 8px;
  color: green;
  font-size: 15px;
}
.complain-action-wrapper button:first-child {
  margin-left: auto;
}
textarea#remarks {
  color: #000;
  font-size: 14px;
}
@media only screen and (max-width: 1450px) {
  .complain-table {
    .ant-table-tbody > tr > td:first-child {
      width: 15% !important;
    }
    .ant-table-tbody > tr > td:nth-child(5) {
      width: 25% !important;
    }
    // .attendance-table{
    //   .ant-table-tbody > tr > td:first-child {
    //     width: 5% !important;
    //   }
    // }
  }
}
.task-table {
  tbody {
    tr:hover td {
      background-color: #67a1e4 !important;
      color: #fff !important;
    }
    tr.ant-table-placeholder td {
      background: none !important;
    }
    .task-tr.active {
      background-color: #67a1e4 !important;
      td {
        color: #fff !important;
      }
    }
  }
}
.task-side-panel {
  height: calc(100vh - 172px);
}

.contact-document-wrapper {
  display: flex;
  span.anticon.anticon-paper-clip {
    padding-left: 0px;
    padding-right: 5px;
  }
}

.fc_daygrid_nepali_date {
  position: absolute;
  top: 3.5px;
  left: 2px;
  font-weight: normal;
  font-size: 12.5px;
  font-family: "sf_pro_displayregular";
  color: #242424;
}
.fc-toolbar-chunk:nth-child(2) {
  // color:red !important;
  display: flex;
}

.fc-header-toolbar {
  .fc-toolbar-chunk {
    .fc-toolbar-title {
      font-size: small;
    }
  }
}

.nepali_month_header {
  margin-top: 2px;
  font-size: small;
}

.event-business {
  background-color: #f4b183;
}

.event-personal {
  background-color: #8daadb;
}

// @media only screen and (max-width: 1920px) {
//   .task-side-panel {
//     height: calc(100vh - 171px);
//   }
// }

// @media only screen and (max-width: 1400px) {
//   .task-side-panel {
//     height: calc(100vh - 170px);
//   }
// }
span.ant-input-group-wrapper.ant-input-search.ant-input-search-medium.form__group_email_search.width {
  width: 200%;
}
// .merge--contact {
//   padding-right: 7px;
//   text-align: left;
//   font-weight: 700;
//   font-size: 12px;
//   text-overflow: ellipsis;
//   white-space: break-spaces;
//   display: -webkit-box;
//   line-height: 19px;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
// }

.merge--contact {
  padding-right: 2px;
  // margin-left: -4px;
  font-weight: 700;
  font-size: 17px;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: break-spaces;
  display: -webkit-box;
  line-height: 19px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.merge--contact--reference {
  padding-right: 2px;
  margin-left: -4px;
  font-weight: 700;
  font-size: 17px;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: break-spaces;
  display: -webkit-box;
  line-height: 19px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.merge--contact--duedate {
  font-weight: 600;
  font-size: 13px;
}
span.merge--contacts--reference {
  font-size: 12px;
  font-weight: 700;
}

.dashboards__todolists.leave_absent_listss {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.evaluation_comments {
  padding: 0 0.8em;
}

.evaluation_comments h5 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0.4em 0.4em;
  background: #f6f2f2;
}

.evaluation_comments textarea {
  resize: none;
}
