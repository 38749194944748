.navbars {
  background: $midnight;
  // border-top-right-radius: 33px;
  height: 100%;
  position: fixed;
  top: 0;
  width: 72px;

  &__wrapper {
    padding: 15px 0;
    height: 98vh;
    display: flex;
    flex-direction: column;
    // justify-content: space-around;
    align-items: center;
    position: relative;
    width: 71px;

    &--image {
      padding-bottom: $base-space;
      position: relative;
      margin-bottom: 11px;

      &::after {
        width: 100%;
        height: 1px;
        border: 1px solid $gray;
        position: absolute;
        content: " ";
        bottom: 0;
        left: 0;
      }
    }
    &--avatar {
      width: 51px;
      height: 51px;
      border-radius: 50%;
      overflow: hidden;

      img {
        max-width: 100%;
      }

      &.active {
        border: 2px solid #486cdf;
      }
    }
    &--logOut {
      width: 51px;
      height: 43px;
      border-radius: 1px;
      background-color: #cccbcb;
      right: -24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px;
      font-size: 11px;
      color: #6d6d6d;
      z-index: 1;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);

      &::before {
        width: 100%;
        height: 1px;
        border: 1px solid #f1f1f1;
        position: absolute;
        content: " ";
        top: -15px;
        left: 0;
      }

      &::after {
        position: absolute;
        content: " ";
        bottom: 0;
        left: 0;
        // border-radius: 10px;
        height: 0;
        width: 100%;
        z-index: -1;
        background-color: $primary;
        transition: height 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      }

      &:hover {
        color: $white;

        &::after {
          height: 100%;
        }
      }
    }
  }
  .icons {
    transform: translateY(-4px);
    height: 84vh;
    min-height: 40vh;
    // padding-top: 11px;
    overflow-y: auto;

    ::-webkit-scrollbar-thumb {
      border-radius: 15px !important;
      background: transparent !important;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      // height: 58px;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      padding: 8px 10px;
      text-align: center;
      transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      position: relative;
      z-index: 1;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        background-color: #d8ddeb;
        transition: width 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
        z-index: -1;
      }

      img {
        width: 27px;
        height: auto;
      }

      &--name {
        color: #afafaf;
        font-size: 10px;
        margin-top: 3px;
        transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      }

      &:hover::before {
        width: 100%;
      }

      &.active {
        background-color: #f4f4f4;

        img {
          // filter: sepia(100%) hue-rotate(190deg) saturate(500%);
          filter: invert(0%) sepia(35%) saturate(6728%) hue-rotate(223deg)
            brightness(9%) contrast(100%);
        }

        .icons__wrapper--name {
          color: $midnight;
        }
      }

      &:hover &--name,
      &.active &--name {
        color: $midnight;
      }
    }
  }
}

.navbarsRight {
  background: $white;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  width: 65px;

  &__wrapper {
    padding: 30px 0 0 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__top,
  &__bottom {
    width: 100%;
  }

  &__top &__Iconwrap {
    border-bottom: 1px solid #ddd;
  }

  &__Iconwrap {
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ddd;
    cursor: pointer;
    transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    position: relative;

    &::before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 0%;
      background: $primary;
      z-index: -1;
      transition: width 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }

    span {
      display: block;
      font-size: 11px;
      font-weight: 600;
    }
    .anticon {
      font-size: 16px;
      margin-bottom: 5px;
      color: $primary;
    }

    .ant-image {
      width: 17px;
    }

    &:hover {
      color: $white;
      border-top: 1px solid $primary;

      &::before {
        width: 100%;
      }

      .anticon {
        color: $white;
      }

      .ant-image img {
        filter: brightness(0) invert(1);
      }
    }

    &.active {
      color: $white;
      background-color: $midnight;
      border-top: 1px solid $primary;

      .anticon {
        color: $white;
      }

      .ant-image img {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.setting_header {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 700;
}
//scrollable navbar
.navbar-icons::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: transparent !important;
}

.nav__show {
  display: none;

  @media screen and (max-height: 633px) {
    display: block;
  }
}

.nav__hide {
  display: block;
  @media screen and (max-height: 633px) {
    display: none;
  }
}

.btn-nav {
  background-color: transparent;
  color: white;
  text-align: center;
  width: 100%;
  border: none;

  &:link,
  &:visited {
    background-color: transparent;
    color: white;
    border: none;
  }
  &:hover {
    background-color: transparent;
    border: none;
  }
  &:active,
  &:focus {
    background-color: transparent;
    border: none;
  }
  &::after {
    content: none;
  }
}

.popOverWrapper {
  height: 75vh;
}

.popOver {
  position: unset;
}

//RESPONSIVE NAVBAR MEIDAQUERY

.logout {
  @media screen and (min-height: 472px) and (max-height: 472px) {
    display: none;
  }
}
.navbars .icons__wrapper:hover img {
  filter: brightness(0%) !important;
}

.navbars .icons__wrapper:hover .icons__wrapper--name {
  color: #000;
}
.taskIcon {
  color: #afafaf;
  font-size: 24px;
}
a.icons__wrapper:hover span svg {
  color: #100b17;
}
.navbar-icons .ant-badge-count {
  right: 18px !important;
  min-width: 20px;
  height: 20px;
  line-height: 15px;
  padding: 0 3px !important;
  font-size: 7px;
  z-index: 9;
  top: 12px;
}
