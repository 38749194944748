.ant-list-header {
  background: lightgreen !important;
}

.ant-table {
  font-size: 12px !important;
}

.ant-table-thead > tr > th {
  height: 5px !important;
  padding: 4px !important;
}

.ant-table-tbody > tr > td {
  height: 5px !important;
  padding: 4px !important;
}
