.login {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image:linear-gradient(180deg, #cef3ff 0%, #ffd9d9 100%);

    &__box {
        background-color: $white;
        padding:17px;
        border-radius: 10px;
        width: 260px;

        &--image {
            width: 55px;
            margin: 0 auto;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    &__form {
        padding-top: 12px;
    }
}
input#basic_username {
    padding: 0;
}
.loading_check_daily_attendence{
    margin-top: 5px;
    .ant-spin-dot-spin{
        margin-left: 30px;
        // display: flex;
        // justify-content: center;
    }
    .ant-spin-text{
        margin-left: 40px;
    }
    // display: flex;
    // justify-content: center;
}