.hrSystem_sidebody_list {
  cursor: pointer;
  &:hover:not(.active) {
    background-color: rgb(242, 239, 239);
    zoom: 1.03;
  }
}
.hr_System_sideBody_row:nth-of-type(odd) {
  background-color: #ffffff;
}

.hr_System_sideBody_row:nth-of-type(even) {
  background-color: #f2f2f2;
}

.sidebar_job_title_hrsys {
  &:hover {
    color: "black";
  }
}

.all-reviews {
  background: #5883b5;
  margin-bottom: 5px;
}

.review-at-top {
  animation: translateWidth 550ms ease-in-out backwards;
}

// add
.jobInteviewNum .ant-input-number {
  width: 100%;
}

.jobInterviewForm label {
  width: 100%;
}

.hrSystem_search_btn span {
  display: flex;

  svg {
    font-size: 11px;
  }
}

.hrSystem_job_interview-row {
  padding: 6px 6px 0px 6px;
  background: #eef2f8;

  div:nth-child(odd) {
    background: #fafafa;
    margin-bottom: 6px;
    padding: 6px;
  }

  div:nth-child(even) {
    background: #fafafa;
    margin-bottom: 6px;
    padding: 6px;
  }
}
.hrAffix .ant-affix {
  display: flex;
  justify-content: space-between;
  padding: 0 6px;
  background: #f2f2f2;
}
// .hrSystem_job_interview-row div:nth-child(odd) {
//   background: lightgrey;
// }

// .hrSystem_job_interview-row div:nth-child(even) {
//   background: lightblue;
// }

// .hr_sidebars:hover .ant-dropdown-menu-item-active {
.hr_sidebars:hover {
  color: #ffffff;
  background: #67a1e4;
}

.hrSystem_search .ant-input-affix-wrapper {
  padding: 4px 0;
}

.jobInterview_date {
  .ant-picker {
    padding: 0 9px 0 0;
  }
}

@keyframes translateWidth {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
