
    .contact__column__wrapper{

        display:flex;
        flex-direction: column;
    }
    .contact__row__properties{

        flex-direction:row
    }

    .contact_discussion__input{
        margin-top: 6px;
        display: flex;
        flex-direction: row;
    }
    .contact__discussion__label{
        font-size: 14px;
        color:#303030;
        font-weight: 600;
    }

    .contact__bottom__buttons{
        float:right;
    }

    .contact__bottom__buttons__style{
        color:white;
        background-color: #1f95d9;
    }

    .contact__input__style{
        border-radius: 6px;
        background-color: white;
    }

.contact__bottom__items{
    padding:0px 7px 0px 7px;
}

.contract__row__wrapper{
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 10px;

    .contact__discussion__input{
      margin-left: 0px;
    }

    @media (min-width: 768px) {
      display:flex;
      margin-bottom: 0px;

      .contact__discussion__input{
        margin-left: 15px;
      }
    }
  }

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//     border-radius: 5px !important;
//     // border-width: 0 0 1px 0;   
// }