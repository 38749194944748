.chat--messageBox {
  .chat-box-head-wrapper {
    display: flex;
    .chat-action-btn {
      display: flex;
      column-gap: 13px;
      margin-left: auto;
      .btn__chat--cancel:hover {
        background: none !important;
      }
    }
  }
  // #replyChatBox {
  //   position: relative;
  //   .tox-editor-header {
  //     display: none;
  //   }
  // }
}
.chat-box-footer {
  background: #fafafa !important;
  position: absolute !important;
  width: 100% !important;
}
.chat-box-footer-wrapper {
  display: flex;
  .chat-action-btn {
    display: flex;
    column-gap: 15px;
    margin-left: auto;
    .btn__chat--cancel:hover {
      background: none !important;
    }
  }
}
.replyChatHeight {
  display: block;
  line-height: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;

  &.active {
    height: 300px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.replyChatBox {
  position: relative;
  .tox-editor-header {
    display: none;
  }

  &.showHeader {
    .tox-editor-header {
      display: block;
    }
  }
}

.chat-button-wrapper {
  background-color: #fff;

  div {
    width: 100%;
    height: 100%;
    .ant-btn.btn.btn-internalChat {
      width: 100%;
      height: 100%;
      text-align: left;
      background-color: #fff;
      color: #000000;
      opacity: 0.5;
      display: flex;
      padding-top: 30px;
    }
    .ant-btn.btn.btn-internalChat:hover {
      border: 0px;
      border: 1px solid #d9d9d9;
    }
  }
}
.btn_wrap--buttons {
  display: flex;
  align-items: center;
  .chat-box-editor-header-btn-wrapper {
    display: flex;
    align-items: center;
    .btn__chat-editor-header {
      margin-right: 10px;
      margin-top: 7px;
    }
  }
  .chat-box-attachment-bth-wrapper {
    margin-top: 6px;
  }
}
.chat-box-send-wrapper {
  .ant-btn-group.ant-dropdown-button {
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    column-gap: 15px;
    button.ant-btn.ant-btn-default {
      margin: 0px !important;
      border: 0px;
      padding: 0px !important;
      height: auto;
      background: none;
      color: #fff;
    }
  }
}
