.file-action-btn-wrapper {
  display: flex;
  column-gap: 10px;
  .action-btn-item {
    span {
      font-size: 14px;
      color: #2e5cc5;
    }
  }
}
.text-center-overflow-wrapper {
  display: flex;
  width: 100%;
  .first-overflow {
    max-width: 60%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
//global drawer with drawer
.global-body-with-drawer-wrapper {
  .global-body-flex {
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .global-body {
      width: calc(100% - 350px);
      transition: 0.5s;

      .global-body-header {
        background: #fafafa;
        height: 48px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        width: 99%;
        justify-content: space-between;

        h3 {
          color: #303030;
          font-size: 18px;
          padding: 0px 10px;
          margin: 0px;
          font-weight: 700;
        }
      }

      .global-body-info-wrapper {
        padding: 7px 7px;
      }

      &.hideDrawer {
        width: 100%;
      }
    }

    .global-custom-drawer-wrapper {
      width: 350px;
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      border-left: 1px solid #ddd;
      transition: 0.5s;

      .global-custom-drawer-header {
        background: #fafafa;
        height: 48px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;

        h3 {
          font-size: 16px;
          font-weight: 600;
          color: #06123e;
          margin-bottom: 0px;
          padding-left: 10px;
        }
      }

      &.hideDrawer {
        transform: translateX(350px);
      }

      .global-custom-box-wrapper {
        .drawer-form-wrapper {
          .drawer-form-row {
            .drawer-form-group {
              label {
                display: block;
                width: 100%;
                text-align: left;
                margin-bottom: 5px;
              }

              .ant-select.ant-select-borderless.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
                border: 1px solid #d9d9d9;
              }
            }
          }
        }
      }
    }
  }
}

.global-table-wrapper {
  .global-columns-wrapper {
    .global-columns-items {
      .global-columns-flex {
        display: flex;
        height: 35px;
        background: #d8ddeb;
        width: 100%;
        align-items: center;
        padding: 0px 7px;

        .global-column-item {
          h5 {
            margin: 0px;
            font-size: 12px;
            font-weight: 600;
            color: #000;
          }
          &.text-center {
            text-align: center;
          }
        }

        &.w-4 {
          .global-column-item {
            width: calc(100% / 4);
          }
        }

        &.w-5 {
          .global-column-item {
            width: calc(100% / 5);
          }
        }

        &.w-6 {
          .global-column-item {
            width: calc(100% / 6);
          }
        }

        &.w-7 {
          .global-column-item {
            width: calc(100% / 7);
          }
        }
      }
    }
  }

  .global-rows-wrapper {
    .global-rows-items {
      overflow-y: auto;
      .global-rows-flex {
        .global-rows-item {
          display: flex;

          &:hover {
            background: #ddd !important;
            cursor: pointer;
          }
          &:nth-last-of-type(odd) {
            background: #f2f2f2;
          }
          .global-rows-info-wrapper {
            padding: 0px 7px;
            font-size: 12px;
            color: #000;
            min-height: 40px;
            display: flex;
            align-items: center;
            &.text-center {
              justify-content: center;
              text-align: center;
            }
          }
          &.active {
            background: #67a1e4 !important;
            &:hover {
              background: #67a1e4 !important;
            }
            .global-rows-info-wrapper {
              color: #fff !important;
              padding-right: 7px;
            }
            .file-action-btn-wrapper {
              .action-btn-item {
                span {
                  color: #fff;
                }
              }
            }
          }
          &.w-4 {
            .global-rows-info-wrapper {
              width: calc(100% / 4);
            }
          }
          &.w-5 {
            .global-rows-info-wrapper {
              width: calc(100% / 5);
            }
          }

          &.w-6 {
            .global-rows-info-wrapper {
              width: calc(100% / 6);
            }
          }
          &.w-7 {
            .global-rows-info-wrapper {
              width: calc(100% / 7);
            }
          }
        }
      }
    }
  }
}

.global-single-detail-wrapper {
  padding: 15px 10px;
  .global-single-detail-item {
    display: flex;
    flex-flow: row wrap;
    min-height: 38px;
    align-items: center;
    border-top: 1px solid #f5f5f5;
    &:last-child {
      border-bottom: 1px solid #f5f5f5;
    }
    .global-single-detail {
      width: 120px;
      p {
        font-size: 13px;
        color: #000;
        font-weight: 600;
        margin-bottom: 0px;
      }
    }
    .global-single-detail-info {
      width: calc(100% - 120px);
      p {
        font-size: 12px;
        color: #000;
        margin-bottom: 0px;
      }
      .file-action-btn-wrapper {
        display: flex;
        column-gap: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1450px) {
  .global-body-with-drawer-wrapper .global-body-flex .global-body {
    width: calc(100% - 300px);
  }
  .global-body-with-drawer-wrapper
    .global-body-flex
    .global-custom-drawer-wrapper {
    width: 300px;
  }
}
@media only screen and (max-width: 1250px) {
  .global-body-with-drawer-wrapper .global-body-flex .global-body {
    width: calc(100% - 250px);
  }
  .global-body-with-drawer-wrapper
    .global-body-flex
    .global-custom-drawer-wrapper {
    width: 250px;
  }
}
