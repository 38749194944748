.dashboard {
  display: flex;
  align-items: initial;
}
.dashboards {
  padding: 15px 60px 0 60px;
  margin-right: 65px;

  ul.dashboard-notification-lists {
    margin-left: 20px;
    list-style: circle;
    height: calc(100vh - 195px);
    overflow-y: auto;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn {
        width: 15px;
        height: 15px;
        line-height: 9px;
        font-size: 10px;
        font-weight: 600;
        cursor: pointer;
        background-color: #e0e0e2;
        &:hover {
          background-color: #e0e0e2;
        }
        &:first-child {
          margin-right: 5px;
        }
        &:focus {
          box-shadow: unset;
          border: 0px;
        }
        &.mark-read {
          border-color: #ebecf0;
          position: relative;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.marked {
            span {
              width: 6px;
              height: 6px;
              background-color: #ee2280;
              border-radius: 4px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .addTransform {
    transform: rotateY(180deg);
  }

  .visibilityHidden {
    visibility: hidden;
  }

  .dashboard__flip {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    .dashboards__row--front,
    .dashboards__row--back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .dashboards__row--back {
      background-color: #fff;
      transform: rotateY(180deg);
    }
  }

  &__row {
    height: 100%;
  }

  &__wraps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title {
    color: #303030;
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 10px 0;
    line-height: 1;

    &--name {
      color: #1e3fae;
    }
  }

  &__sub {
    color: #303030;
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 5px 0;
  }

  &__bellIcon {
    height: 48px;
    width: 48px;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);

    &:hover {
      background-color: $primary;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  &__body {
    border-radius: 8px;
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 15px;

    .btn-primary {
      width: auto;
    }

    &Wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid $gray;
    }

    &--title {
      font-size: 17px;
      margin-bottom: 0;
      font-weight: 700;
    }

    &--main {
      padding: 15px 0 0 0;
    }
  }

  &__mailInfo {
    &--title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 18px;

      .ant-image {
        margin-right: 10px;
      }
    }

    .dashboards__wraps {
      margin-bottom: 0;
    }

    &--Count {
      font-size: 22px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 0;

      &.red {
        color: #e9733e;
      }

      &.blue {
        color: #486cdf;
      }

      &.green {
        color: #3ec2a7;
      }
    }

    &--CountTitle {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
      color: #6d6d6d;
    }
  }

  &__todolist {
    &s {
      list-style: none;
      margin-bottom: 0;

      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;
      // height: 305px;

      li {
        font-size: 14px;
        color: #303030;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .ant-image {
          width: 18px;
          cursor: pointer;
        }

        .d-flex {
          display: flex;
          align-items: center;
          flex-flow: row wrap;
          width: 100%;
          .dashboards__todolists--div {
            width: calc(100% - 120px);
          }
          .badge.badge-outline--default {
            margin-left: auto;
            color: #fff;
            width: 80px;
            display: flex;
            justify-content: center;
          }
        }

        .dashboards__todolists--div {
          padding-right: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        &.checked {
          .dashboards__todolists--div {
            text-decoration: line-through;
            color: #129f5d;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1470px) {
    .requisition_body {
      height: 215px;
    }
    .requisition1 {
      height: 220px !important;
    }
  }

  &__favLinks {
    a {
      background: #e4e9fa;
      border-radius: 50px;
      color: #486cdf;
      font-size: 14px;
      padding: 4px 8px;
      display: flex;
      overflow: hidden;
      align-items: center;
      position: relative;

      span,
      .ant-image {
        z-index: 10;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0%;
        background-color: #486cdf;
        z-index: 1;
        transition: width 0.45s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      }

      .ant-image {
        margin-right: 7px;
        width: 18px;
      }

      &:hover {
        color: $white;

        &::before {
          width: 100%;
        }

        .ant-image img {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  &__tenderlist {
    .ant-btn {
      padding: 0;
      height: 26px;
    }

    .ant-select {
      width: 100px;
      margin-left: 10px;

      .ant-select-selector {
        height: 26px;
        line-height: 1;
      }

      .ant-select-selection-item {
        line-height: 26px;
      }
    }

    .dashboards__todolists {
      height: 390px;

      li {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--left {
      h5 {
        color: #303030;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 1.2;
      }

      span {
        color: #a4a4a4;
        font-size: 13px;
      }
    }

    &--right {
      display: flex;
      align-items: center;

      h6 {
        font-size: 14px;
        color: #6d6d6d;
        font-weight: 400;
        margin-bottom: 0;
        line-height: 1;
        margin-right: 5px;
      }

      span {
        border-radius: 34px;
        background-color: #e9e9e9;
        padding: 3px 8px;
        color: #6d6d6d;
      }
    }
  }

  &__visitors {
    .dashboards__todolists {
      height: 172px;
    }
    .ant-tabs-nav-list .ant-tabs-tab {
      padding: 5px 0 10px 0;
    }

    &--none {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 68px;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #6d6d6d;
      }
    }
  }

  &__events {
    .ant-btn {
      padding: 0;
      height: 26px;
    }

    &--list {
      &.specialDay {
        border-top: 1px solid $gray;
        padding-top: 10px;
      }

      &Title {
        // color: #303030;
        font-size: 15px;
        font-weight: 500;
      }

      .ant-image {
        width: 13px !important;
        vertical-align: text-bottom;
        margin-right: 5px;
      }

      .birthday {
        color: #e9733e;
      }

      .aniversary {
        color: #d7443e;
      }

      .day {
        color: #486cdf;
      }
    }
    &--date {
      // color: #6d6d6d;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.dashboards__body--content {
  display: flex;
}
.dashboards__visitors.dashboards__body {
  .ant-tabs-nav-list {
    width: 100%;
  }
}
.dashboards__bodyWrap {
  padding-bottom: 0px;
}
.dashboards__bodyWrap1 {
  padding-bottom: 6px !important;
}
.tender-days-select-option {
  margin-left: 0px !important;
  width: 59px !important;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85) !important;
  opacity: 1 !important;
  font-weight: 800;
  .ant-select-selector {
    border: 0px !important;
    width: auto !important;
  }
}
.dashboards__events.dashboards__body {
  height: 93%;
}
.dashboards__visitors {
  .dashboards__todolists {
    max-height: 179px;
    overflow-y: auto;
    padding: 0px;
    .dashboards__visitors {
      height: 100%;
      .organizer__list {
        height: 100%;
        .organizer__list--body {
          height: 100%;
          overflow: hidden;
          overflow-y: auto;
          padding: 0px;
          margin: 0;
          .gray__form {
            padding: 0px;
          }
        }
      }
    }
  }
}

img.ant-image-img.data-no-img {
  width: 89% !important;
}

.honor-wrapper {
  .dashboards__events .dashboards__todolists {
    height: 100%;
  }
}
.email_notify_dashboard {
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
  .ant-image {
    // margin-right: 10px;
  }
}
.dashboard_title_header_tender {
  display: flex;
  justify-content: space-between;
  h5 {
    color: #303030;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.2;
    align-items: right;
  }
  .due_date_dashboard {
    margin-right: 50px;
  }
}
.open_menu_card_title_dashboard {
  // border: none;
  margin-left: 5px;
  .ant-dropdown-trigger {
    border: none;
    margin-top: 5px;
  }
}
.new_menu > .ant-btn:first-child {
  display: none;
}
.new_menu .ant-btn-default .ant-btn {
  display: none;
}
.new_menu {
  ant-btn-default {
    display: none;
  }
  .ant-dropdown-trigger {
    border: none;
  }
}
// .filter_option_dashboard{
//   margin-left:2px;
// }
.email_board {
  .dashboards__wraps {
    margin-top: 10px;
  }
}
.todolist-wrapper {
  .ant-skeleton-content .ant-skeleton-title {
    margin-top: 5px;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 18px;
  }
}

.flip-card {
  background-color: transparent;
  // width: 100%;
  // height: 200px;
  // border: 1px solid #f1f1f1;
  margin-bottom: 14px;
  // perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: white;
  color: black;
  border-radius: 10px;
}

.flip-card-back {
  background-color: white;
  color: white;
  transform: rotateY(180deg);
  border-radius: 10px;
}

.honor-wrapper {
  .slick-slide div {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }
  .honor__card--details {
    display: flex;
  }
  .honor__card--image {
    margin-left: auto;
  }
  p.author {
    width: 100%;
    text-align: center;
  }
}
.dashboards__todolist.dashboards__body {
  height: 423px;
  // overflow: auto;
}
// .list1 {
//   height: 153px !important;
// }
.list2 {
  height: 240px !important;
}
.list3 {
  height: 269px !important;
}
.private-1 {
  width: 100% !important;
}
.diary {
  width: 99.5% !important;
}
.dashboards__noticeboard.dashboards__body {
  height: 250px;
  // overflow: auto;
}
.dashboards__visitors--none {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.dashboard-notification-lists {
  margin: 0 !important;
}
.history-btn {
  background: #f5f5f5;
  color: #000;
  border: 0;
  font-size: 16px;
  padding: 3px 10px;
  border-radius: 4px;
  &.active {
    background: green;
    color: #fff;
  }
}
.upcoming-btn {
  background: #f5f5f5;
  color: #000;
  border: 0;
  font-size: 16px;
  padding: 3px 10px;
  border-radius: 4px;
  margin-right: 10px;
  &.active {
    background: green;
    color: #fff;
  }
}
.forthcoming-btn {
  background: #f5f5f5;
  color: #000;
  border: 0;
  font-size: 16px;
  padding: 3px 10px;
  border-radius: 4px;
  margin-right: 10px;
  &.active {
    background: green;
    color: #fff;
  }
}
@media only screen and (max-width: 1470px) {
  .forthcoming-btn,
  .upcoming-btn,
  .history-btn {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 1300px) {
  .forthcoming-btn,
  .upcoming-btn,
  .history-btn {
    font-size: 13px !important;
  }
}

.title_req {
  font-size: 16px;
}
.forthcoming-btn {
  background: #f5f5f5;
  color: #000;
  border: 0;
  font-size: 16px;
  padding: 3px 10px;
  border-radius: 4px;
  margin-right: 10px;
  &.active {
    background: green;
    color: #fff;
  }
}
//Attandance css udpate
.calander_body {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
}
.table_not_scroll_x {
  height: auto;
}
.searchDate {
  width: 150px;
}
// .attandance_tab{
// margin-left: 50px;
// }

.ant-select.select_users.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
  // font-size: 15px;
  // margin-left: 5px;
  // width: 100%;
}
label.select_username {
  /* margin: 4px; */
  /* color: #008000; */
  margin: 5px 35px;
  font-size: 1em;
  white-space: nowrap;
  position: absolute;
  left: 0em;
}
.red-cross-section {
  color: red;
}
.software-attendence__filter {
  button.ant-switch {
    background: #67a1e4;
  }
  button.ant-switch.ant-switch-checked {
    background: red;
  }
}
.goal-list-wrapper {
  ul {
    li {
      list-style: none;
      line-height: 40px;
      border-bottom: 1px solid #f2f2f2;
      position: relative;
      display: flex;
      align-items: center;

      &::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid #ddd;
        left: 0;
        top: 10px;
      }
      span {
        font-size: 18px;
        color: #000;
        margin-left: 30px;
      }
      button {
        margin-left: auto;
        background: none;
        color: #67a1e4;
        font-size: 14px;
        font-weight: 400;
        border: 0px;
      }
    }
  }
}
.today-target-wrapper {
  ul {
    li {
      list-style: none;
      line-height: 40px;
      border-bottom: 1px solid #f2f2f2;
      position: relative;
      display: flex;
      align-items: center;

      &::after {
        position: absolute;
        content: "";
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        border: 1px solid #ddd;
        left: 0;
        top: 10px;
      }
      span {
        font-size: 18px;
        color: #000;
        margin-left: 30px;
      }
      button {
        margin-left: auto;
        background: none;
        color: #67a1e4;
        font-size: 14px;
        font-weight: 400;
        border: 0px;
      }
    }
  }
}
.goal-action-wrapper {
  display: flex;
  column-gap: 10px;
  margin-top: 5px;
  button {
    background: #f2f2f2;
    color: #777;
    border: 0px;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 4px;
  }
}

.dashboards__todolists--reference {
  color: white;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  background: #708ff5;
  padding: 0 4px;
  margin-right: 6px;
  // width: 200px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.calender-wise {
  display: flex;
  align-items: center;
  form.ant-form.ant-form-horizontal {
    width: 30%;
  }
  .attendence-filter {
    margin-left: auto;
  }
  .name-attendence__filter {
    margin-right: 20px;
  }
}
.ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search
  input {
  border: 0px !important;
}
a.tender-view-btn {
  span {
    background: none;
    color: green;
  }
}
.late_login_popup_modal {
  .ant-modal-content {
    border-radius: 16px !important;
    .ant-modal-body {
      padding: 20px 20px;
    }
  }
}
.late_Reason_Attendence_Submit {
  width: 90px;
  appearance: none;
  backface-visibility: hidden;
  background-color: #33416e;
  border-radius: 10px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 1px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  margin-left: 15px;
}
.late_login_popup_modal {
  .button_submit {
    margin-top: 12px;
    display: flex;
    justify-content: end !important;
  }
}
.late_attendence_popup_header {
  color: #495057;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.late_reason_submit {
  margin-top: 10px !important;
}
.time_format_login_popup {
  display: flex;
  justify-content: center;
}

.dashboard_title_header_tender.absent-late-name {
  padding: 10px;
  margin-left: 12px;
}
.absent-late-reason {
  margin-right: 25px;
}
.leave_absent_list {
  height: 157px;
  list-style: none;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}
.dashboards__todolist li:last-child {
  margin-bottom: 0;
  padding: 10px;
}

.dashboards__todolists li:last-child:not(.exclude-last-child) {
  margin-bottom: 0;
  padding: 3px;
}
.absentlist_fullname {
  margin-left: -20px;
}

//islocked css Update
.islocked-change-space {
  padding: 0px;
  margin-left: 0px;
  margin-right: 8px;
}
.card-title-notice {
  font-weight: 600;
  font-size: 15px;
  margin: 3px 0;
}
.card-title-notices {
  font-size: 14px;
  font-weight: 400;
  margin: 2px 0;
}

.header-section-notice-board {
  /* margin: 17px; */
  border-radius: 8px;
  background-color: #ffffff;
  padding: 3px;
  margin-bottom: 15px;
}

// .dashboards__tenderlist--lefts span {
//   color: #000;
//   font-size: 20px;
// }
.left1-title {
  display: flex;
  width: auto;
}
.left1-title-main {
  width: 90%;
  // maxHeight: "calc(2em * 2)", // Set the maximum height to two lines of text
  overflow: hidden; // Hide the overflow
  text-overflow: ellipsis; // Add ellipsis when the text overflows
  // whiteSpace: "nowrap", // Prevent the text from wrapping
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.dashboards__events--listTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dashboards__noticeborad--list {
  color: #ba817e;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  background: #708ff5;
  padding: 0 4px;
  margin-right: 6px;
  width: 200px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.notice-board-list {
  width: calc(100% / 4 - 6px);
}
.notice-board-list {
  width: 50% !important;
}

.notice-board-wrapper {
  .dashboard_title_header_tender.absent-late-name {
    margin: 0px !important;
    padding: 10px 0px !important;
  }
  ul.dashboards__todolists.leave_absent_list {
    div {
      li.dashboards__tenderlist--left {
        display: flex;
        justify-content: space-between;
        div {
          width: 50% !important;
          // width:calc(100% / 4 - 20px);
          padding: 5px 0px;
        }
      }
    }
  }
}
.dashboard_title_main {
  display: flex;
}
.hover-item:hover {
  background-color: #f4f4f4;
}
.space-at {
  padding-right: 10px;
}

.dashboard_notice_board_top {
  display: flex;
  height: 30px;
  // width:90%;
  align-items: center;
  .dashboard_notice_title {
    border-right: 1px black;
    font-weight: 600;
    font-size: 13px;
    // margin-top:4px;
  }
  .marquee_tag_dashboard {
    // margin-top:3px;
    // margin-left:0px 10px 0px 10px !important;
  }
}
.title_dashboard_notice {
  margin: 0px 10px 0px 10px !important;
  font-weight: 600;
  .description_dashboard_notice {
    font-weight: normal !important;
  }
}
.dashboards__mailInfo--Count.red {
  text-align: center;
}
.dashboards__mailInfo--Count.blue {
  text-align: center;
}
.dashboards__mailInfo--Count.green {
  text-align: center;
}
.dashboard-email-section {
  text-align: center;
}
.requisition {
  height: 340px;
}
@media only screen and (max-width: 1467px) {
  .requisition {
    height: 405px;
  }
}
@media only screen and (max-width: 1173px) {
  .requisition {
    height: 434px;
  }
}
@media only screen and (max-width: 1462px) {
  // .dashboards__tenderlist .dashboards__body .tour2 {
  .tour2 {
    height: 530px !important;
  }
}
// .ant-table-measure-row {
//   display: none;
// }

div.ant-typography,
.ant-typography p {
  margin-bottom: 0em;
}

.fc-event-holiday {
  white-space: pre-line;
}

.dashboard_task-daily .ant-table-column-sorters, 
.dashboard_task-daily > .ant-table-column-sorters-with-tooltip > .ant-table-column-sorters > span:first-child  {
  width: 100%;
}