.permissions_lists {
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #eee;
  padding-top: 5px;
  .title {
    font-weight: bold;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    padding-left: 10px;
  }
  .current-permissions {
    font-size: 13px;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 10px;
    margin-left: 20px;
  }
}

a {
  text-decoration: none;
}
.organizer.fullCalendar .layout-title {
  padding: 0px 7px !important;
}
.contact-table {
  margin: 0px 7px;
  margin-top: 15px;
}
.company-table-wrapper {
  margin-right: 0px;
  margin-left: 0px;
}
.contact-table table thead {
  height: 31px;
}
.contact-table table thead tr th {
  padding: 0px 7px !important;
  background: #d8ddeb !important;
  color: #06123e;
  font-weight: 600;
}
.contact-table table tbody tr td {
  padding: 0px 7px !important;
  height: 31px;
  font-size: 11px;
  color: #303030;
}
.add-row-btn {
  margin-top: 15px;
  margin-left: 7px;
  margin-bottom: 0px !important;
}

.admin__body {
  width: calc(100% - 299px);
}
span.ant-upload-span {
  height: 30px;
}
.line-spacing-role {
  margin-left: -16px;
  padding: 8px;
  line-break: auto;
}
.designationList-part {
  width: 20%;
}
body > iframe {
  display: none;
}
