.chatmail {
  // margin-bottom: 10px;
  // height: 44px;

  &__text {
    width: 72% !important;
    white-space: nowrap;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  &__image {
    line-height: 17px;
  }

  &__header {
    &--wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      height: 40px;
      padding: 14px;
      background: #f5f5f5;
      padding-right: 9px;
    }
    &--leftcontent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &--rightcontent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .chatmail__image {
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}
.email {
  display: flex;
  //min-height: 100vh;
  &Info {
    width: 300px;
    border-right: 1px solid $gray;
    //min-height: 100vh;
    // padding: 20px 0 5px 0;
    // transition: width 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);

    &.full-row {
      width: 100%;
      border-right: 0px;
      position: relative;
      transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      // @media (max-width: 1100px) {
      // 	width: 267px !important;
      // 	transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      // }

      .ant-tabs-tab {
        // margin: 0 0 0 15px;
      }
      .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
      .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
        width: 100%;
      }
    }
    .ant-tabs > .ant-tabs-nav {
      background: #fafafa;
      height: 32px;
    }

    &__form {
      padding: 0px 15px;
      display: flex;
      align-items: center;

      form {
        width: 100%;
      }

      &--search {
        position: relative;

        .ant-input {
          background-color: #e9e9e9;
          border: 1px solid #e9e9e9;
          padding: 0 33px 0 10px !important;
        }

        .ant-avatar {
          position: absolute;
          right: 0;
          background: transparent;
          color: #6d6d6d;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      .tooltips {
        margin: 0 0 12px 12px;
        font-size: 20px;
      }
    }
  }
  &List {
    &__sort {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // background-color: $gray;
      // padding: 10px 20px;

      &--label {
        font-size: 12px;
        color: #a7a3a3;
        min-width: 45px;
      }

      &--select {
        margin-left: 5px;
      }
    }

    &__info {
      display: flex;
      // justify-content: space-between;
      // align-items: center;

      color: #a4a4a4;
      font-size: 12px;
      // margin-bottom: 3px;
    }

    &__right {
      width: 100%;
      &--details {
        display: flex;
        justify-content: space-between;
        line-height: 14px;
      }

      .email-title-icon {
        .text-left {
          width: 2px;
          margin-right: 4px;
          .ant-image-img {
            width: 10px;
            height: 8px;
          }
          .star {
            height: 10px;
          }
        }
        .text-right {
          margin-left: 10px;
          font-size: 12px;
          // max-width: 203px;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          // overflow: hidden;

          @media (max-width: 1100px) {
            //max-width: 181px !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          p {
            margin: 0;
            font-size: 12px;
            font-family: inherit;
          }

          &--description {
            margin-left: 10px;
            line-height: 18px;
            color: #000;
            font-weight: 500;
            opacity: 0.8;
          }
        }
        .text-title {
          max-width: 85%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    &__lists {
      height: calc(100vh - 125px);
      overflow-y: auto;
      padding-bottom: 15px;
    }

    &__card {
      border-bottom: 2px solid $gray;
      font-weight: 400;
      padding: 5px 2px 0px 2px;
      cursor: pointer;
      transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);

      &--date {
        color: #949494;
        white-space: nowrap;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background-color: rgba($gray, 1);
      }

      &--smallTitle {
        font-weight: bold;
        color: #1f1f1f;
        display: flex;
        align-items: center;
        .text-right {
          font-size: 14px !important;
        }
        .isNotIcon {
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background-color: $primary;
          margin-right: 4px;

          &.active {
            background-color: #129f5d;
          }
        }
      }

      &--title {
        color: #303030;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
        display: flex;
        align-items: center;

        &.isSeen {
          font-weight: 600;
        }
      }

      &--desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &--refComp {
        color: #3879c5;
        font-weight: 700;
        font-size: 11px !important;
        display: flex;
        white-space: nowrap;
        column-gap: 2px;
        overflow: hidden !important;
        // margin-top: 5px;
      }
      &--badge {
        .badge-gray {
          padding: 2px 5px;

          img {
            transform: translateY(-2px);
          }
        }
      }

      .emailList__right--details {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.active {
        background-color: #67a1e4;
        border-bottom: 2px solid #e3f2fb;

        .emailList__card--title,
        .emailList__info {
          color: #fafafa;
        }
        .unread-email {
          background-color: #fafafa;
        }
        .emailList__card--date {
          color: #fafafa;
          white-space: nowrap;
        }

        .emailList__card--refComp {
          color: #164f92;
          &.text-right {
            padding-top: 2px;
          }
        }
      }
    }
  }
  .emailList__compName {
    color: #164f92;
    font-size: 13px;
    font-weight: 500;
    margin-left: 10px;
  }

  &Details {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);

    &.show {
      width: calc(100% - 300px);
      transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
    .chatmail__header--wrapper-hide {
      height: 100%;
      padding: 14px;
      padding-left: 12px;
      background: #fafafa;
      align-items: flex-start;
      position: fixed;
      right: 0px;
    }

    &__header {
      padding: 0px 0px;
      background-color: $gray;
      display: flex;
      align-items: center;
      width: 100%;
      overflow-y: auto;

      &--icon {
        text-align: center;
        padding: 0 5px;
        cursor: pointer;
        width: 65px;

        &:first-child {
        }

        &.iconBorder {
          border-right: 1px solid #e2e2e2;
        }

        p,
        .ant-dropdown-link {
          color: #303030;
          font-size: 10px;
          font-weight: 400;
          margin: 0;
          display: block;
          white-space: nowrap;
        }

        .ant-image {
          width: 17px;
        }

        &:hover {
          p,
          .ant-dropdown-link {
            color: $primary;
          }
          img {
            filter: sepia(100%) hue-rotate(190deg) saturate(500%);
          }
        }

        &.disabled:hover,
        &.disabled {
          cursor: pointer;
          p,
          .ant-dropdown-link {
            color: #ddd;
          }
          img {
            filter: sepia(500%) saturate(0%);
          }
        }

        &.active,
        &.active:hover {
          p,
          .ant-dropdown-link {
            color: #129f5d;
          }

          img {
            filter: sepia(100%) hue-rotate(443deg) saturate(350%);
          }
        }

        &.active-blue,
        &.active-blue:hover {
          p,
          .ant-dropdown-link {
            color: $primary;
          }

          img {
            filter: sepia(100%) hue-rotate(190deg) saturate(500%);
          }
        }

        .ant-dropdown-link > .anticon.anticon-down,
        .ant-dropdown-trigger > .anticon.anticon-down {
          font-size: 7px;
        }
      }
    }

    &__body {
      &--title {
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
        padding: 0px 9px 0px 9px;
        height: 32px;

        .email-title-start {
          display: flex;
          align-items: center;
          .email-title {
            font-size: 14px;
            // white-space: nowrap;
            letter-spacing: normal;
            font-weight: bold;
            margin-left: 15px;
            display: flex;
            flex-flow: row wrap;
            padding-left: 8px;
            height: 32px;
            position: relative;
            cursor: pointer;
            &--show {
              margin-left: -18px;
              white-space: break-spaces;
              display: -webkit-box;
              line-height: 17px;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              //overflow: hidden;
              display: block;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            &--nowrap {
              display: none;
              @media (max-width: 900px) {
                white-space: break-spaces;
                display: -webkit-box;
                line-height: 19px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }
        }

        h3 {
          font-size: 12px;
          font-weight: 600;
          margin: 0;
        }
        h4 {
          font-size: 12px;
          color: #616161;
        }

        &Icon {
          // margin-left: 20px;

          a {
            // &:hover {
            // 	img {
            // 		filter: brightness(0);
            // 	}
            // }
          }
        }
      }

      &--titleIcon {
        display: flex;
        align-items: center;
        cursor: pointer;
        right: 0;
        height: 100%;

        &:hover {
          img {
            filter: sepia(100%) hue-rotate(190deg) saturate(500%);
          }
        }
      }

      &--infos {
        &Main {
          padding: 0px 10px 0px 10px;
          border-top: 1px solid $gray;
          border-bottom: 1px solid $gray;
          background: #f5f5f5;
          font-size: 12px;
          // height: 68px;
          // @media (max-width: 1100px) {
          // 	width: 1000px;
          // }
          display: flex;
          flex-flow: row wrap;
          align-items: center;
          .email-from-date {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }
          .email-to-cc {
            display: flex;
            align-items: center;
            width: 100%;
            .email-to {
              width: 35%;
            }
            .email-cc {
              width: 65%;
            }
          }
        }

        .main__contents {
          border-bottom: 1px solid $gray;
          margin-bottom: 10px;
        }

        .badge-link {
          padding: 15px 0;

          .badge {
            padding: 10px;

            .ant-image {
              margin-right: 5px;
            }
          }
        }
        .main_content {
          margin: 0;
          color: #303030;
          font-size: 13px;
          font-weight: 500;
          line-height: 22px;
          padding-right: 10px;
          list-style-position: inside;
          dl,
          ol,
          ul {
            padding-inline-start: 40px;
          }
        }
      }

      &--chat {
        // margin: 15px 0 0 0;
        // border: 2px solid $gray;
        position: fixed;
        right: 0;
        bottom: 0;
        transition: all ease-out 1s;
        width: 28.3% !important;
        // animation: moveInRight 1s ease-out;

        // padding: 10px;

        &.hide {
          display: block;
          @media (max-width: 775px) {
            width: 322px !important;
            position: absolute;
            height: 100%;
            right: 0;
            background: #fffffffa;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }

      .chat--messageBox {
        position: absolute;
        right: 0px;
        width: 100%;
        background: #fff;

        padding-left: 1px;
        padding-right: 4px;
        // border-top: 1px solid #d9d9d9;
        // padding-top: 10px;
        bottom: -3px;
        .form__group {
          margin: 0;

          &--table {
            border: none;
          }
        }
        // .ck.ck-editor .ck-editor__top .ck-sticky-panel .ck-toolbar {
        //   position: absolute;
        //   bottom: 0px;
        //   height: 26px;
        // }
        // .ck.ck-dropdown .ck-button.ck-dropdown__button {
        //   font-size: 12px;
        // }
        // .ck.ck-icon {
        //   font-size: 8px;
        // }
        // .ck.ck-button,
        // a.ck.ck-button {
        //   margin: 0px;
        // }
        // .ck-editor__editable_inline {
        //   height: 100px !important;
        //   overflow-y: scroll;
        //   min-height: 100px;
        // }

        .form__group .ant-picker,
        .form__group input,
        .form__group .ant-select-selector {
          min-height: 25px !important;
        }

        .ant-select-multiple .ant-select-selection-item {
          font-size: 12px;
          height: 20px;
          line-height: 16px;
        }

        .box-type {
          font-size: 11px;
          font-weight: 600;
          margin-right: 4px;
        }

        .replyTo {
          position: absolute;
          top: 0;
          left: 65px;
          background: #ffeec8;
          padding: 1px 10px;
          border-radius: 30px;
          z-index: 1;

          a {
            color: #606060;
            padding-left: 10px;
            z-index: 10;
          }
        }

        .ant-form-item-label {
          text-align: left;
        }

        .btn-wrap {
          display: flex;
          position: absolute;
          width: 100%;
          float: right;
          bottom: 3px;
          justify-content: flex-end;
          align-items: center;
          background: #fafafa;

          // .ant-space-item{

          // 	margin:0px;
          // 	width:8px;
          // }
          &.active {
            bottom: 25px;
          }
          button {
            // margin-left: 10px;
            // height: 25px;
            // font-size: 13px;
            // line-height: 15px;
            border: none;

            width: 33px;
            padding: 0px !important;

            &:hover {
              color: blue;
              // background: transparent;
            }
            &:active {
              color: blue;
            }
          }
        }
        .ant-checkbox-wrapper {
          span {
            color: white !important;
          }
        }
      }
      .email_internal_chatbox {
        .ant-space-item {
          img {
            cursor: pointer;
            margin-top: 5px !important;
          }
        }
      }

      &--chatwrap {
        overflow-y: auto;
        background-color: white;

        &.showHeight {
          transition: 0.8s;
        }

        &.showHeight {
          // height: 511px;
        }

        .chatItem {
          border-radius: 4px;

          &.active {
            animation: moveInWrapper 0.5s ease-out;
          }
          &.animateAll {
            // animation: moveInWrapper 0.5s ease-out;
          }

          &:nth-child(even) {
          }

          &__head {
            padding-bottom: 5px;
            display: flex;
            justify-content: space-between;
            // align-items: center;
          }

          &__left {
            display: flex;
            // align-items: center;
            width: 55%;
            &--content {
              width: 100%;
            }

            .ant-badge {
              margin-right: 10px;

              .ant-badge-count {
                // transform: translate(20%, 115%);
                background-color: #3879c5;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                padding: 0px;
                line-height: 2.5;
                font-size: 8px;
                text-align: center;
                transform: translate(25%, 101%);
              }
            }

            .ant-image {
              height: 40px;
              width: 40px;
              overflow: hidden;
              border-radius: 50%;

              img {
                width: 100%;
                height: auto;
                object-fit: cover;
              }
            }
          }

          &__right {
            &--icons {
              text-align: right;
              display: flex;
              justify-content: flex-end;
              align-items: end;
              margin-bottom: 3px;

              .ant-image {
                margin-right: 10px;

                &:last-child {
                  margin-right: 5px;
                }

                img {
                  width: 8px;
                }
              }
            }

            &--date {
              font-size: 10px;
              color: #616161;
              font-weight: 500;
            }
          }

          &__title {
            color: #67a1e4;
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            line-height: 1;
          }

          &__mailTo {
            color: #3d3d3d;
            font-size: 10px;
            font-weight: 500;
          }

          &__body {
            p {
              color: #303030;
              font-size: 13px;
              font-weight: 400;
              margin: 0;
              letter-spacing: 0.2px;
              white-space: normal;
              word-break: break-word;
              div {
                white-space: initial !important;
              }
            }
          }
        }
      }

      .infosMain {
        display: flex;
        margin-bottom: 0px;
        // align-items: center;
        line-height: 13px;
        // &.active {
        //   margin-bottom: 23px;
        // }
        // margin-bottom: 5px;

        // &:last-child {
        // 	margin-bottom: 0px;
        // }

        span {
          // font-size: 13px;
          font-weight: 400;
          // color: #303030;

          &.details {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
            -webkit-box-orient: vertical;
          }

          &.title {
            color: #0f0f0f;
            width: 42px;
            font-weight: 600;
            font-size: 12px;
          }
        }

        a {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          white-space: nowrap;
          color: #3d3d3d;
          text-decoration: none;
        }
      }

      .meetingInfos {
        &.height {
          height: calc(100vh - 130px);
        }
        width: calc(100% - 37px);
        position: relative;
        height: calc(100vh - 111px);
        overflow-y: hidden;
        &.business {
          height: calc(100vh - 193px);
        }

        .email-attachment {
          padding: 8px 10px;
          height: 40px;
        }

        .ant-divider-horizontal {
          margin: 0 0 10px 0;
        }

        &__table {
          margin-top: 15px;
        }

        .table__title {
          margin: 15px 0;
          color: #303030;
          font-size: 13px;
          font-weight: 600;
        }

        &__table {
          .ant-table-thead > tr > th {
            font-size: 11px;
            background-color: #486cdf;
            padding: 10px;
            color: $white;
          }

          .ant-table-summary tr:nth-child(even) {
            background: #f2f2f2;
          }

          .ant-table-summary tr:nth-child(odd) {
            background: $white;
          }

          .ant-table-tbody > tr > td,
          .ant-table tfoot > tr > td {
            font-size: 11px;
            padding: 10px;
            color: #303030;
          }

          .ant-table tfoot > tr > td {
            color: $primary;
          }

          .ant-table-row-level-1 .ant-table-cell {
            color: #6d6d6d;
          }

          .ant-pagination {
            display: none;
          }
        }
      }

      .mailMeeting {
        border-radius: 8px;
        margin-top: 15px;
        background-color: #ecf0ff;
        padding: 10px;

        &__header {
          border-bottom: 1px solid #ddd;
          padding-bottom: 5px;
          display: flex;
          align-items: center;

          h6 {
            color: #486cdf;
            font-size: 14px;
            font-weight: 600;
            margin: 0;
          }

          .ant-image {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;

            img {
              max-width: 100%;
              height: auto;
            }
          }
        }

        &__body {
          padding-top: 5px;
          font-weight: 400;

          &--margin {
            margin-bottom: 10px;
          }

          &--info {
            color: #6d6d6d;
            font-size: 11px;
          }

          &--innertitle {
            color: #486cdf;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 1;
            margin-bottom: 8px;
          }

          &--details {
            color: #000000;
            font-size: 12px;

            .label__active {
              color: #129f5d;
            }

            .label__inActive {
              color: #d7443e;
            }
          }
        }
      }
    }
  }

  &__icon {
    position: absolute;
    bottom: 20px;
    right: 30px;
    display: flex;

    .ant-badge {
      height: 40px;
      width: 40px;
      margin-left: 20px;
      background: $primary;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      border-radius: 3px;
      color: $white;

      .ant-avatar-square {
        margin: 0;

        img {
          filter: brightness(2);
        }
      }
    }

    .ant-badge-count {
      background-color: #3ec2a7;
    }
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    font-size: 12px;
    border: none;
  }
}

.instant-msg-box {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
  .box-type {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: #eee;
    border-radius: 5px;
  }
}

.email-type {
  // background: #eee;
  // padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mail-quick-message {
  display: flex;
  height: 100%;
  .mail-message {
    width: 65%;
    //border-right: 1px solid rgba(199, 199, 199, 0.5);
    transition: all ease-in-out 0.3s;
    .ant-btn-group > .ant-btn:first-child:not(:last-child),
    .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
      border: none;
      padding-left: 5px;
      padding-right: 4px;
      box-shadow: 0 0 black;
    }
    .ant-btn-group > .ant-btn:last-child:not(:first-child),
    .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
      border: none;
      background: transparent;
      // transition: width 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
    .rotate {
      transform: rotate(-42deg);
      margin-bottom: 8px;
    }
  }
  .quick-message-part {
    width: 35%;
    transition: all ease-in-out 0.3s;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 1;
    // transition: width 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    .ant-checkbox {
      margin-top: 26px;
    }
  }
  .compose-internal-message-wrapper {
    position: fixed;
    height: calc(100% - 124px);
    overflow: hidden;
    width: calc(100% - 65%);
    overflow-y: auto;
  }
  @keyframes make {
    0% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .emailDetails__header {
    .btn__chat img {
      filter: invert(62%) sepia(8%) saturate(4000%) hue-rotate(160deg)
        brightness(83%) contrast(80%);
      height: 22px;
      margin-top: -5px;
    }
  }
  .contact__content__wrapper {
    display: flex;
    flex-flow: row wrap;
    .ant-row {
      width: 100%;
    }
  }
  .text_input_reference {
    .ant-select-selection-item {
      align-items: baseline !important;
    }
  }
  .email-text-input {
    display: flex;
    padding-left: 19px;
    border-bottom: 1px solid #e6e6e6;
    min-height: 1px;
    width: 100%;
    min-height: 32px;
    .ant-form-item-control-input {
      min-height: auto;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      max-height: 120px;
      //overflow-y: auto;
    }
    .ant-select-selection-item {
      line-height: 33px;
    }
    .email-text {
      color: #6b6b6b;
      width: 113px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .ant-select-multiple .ant-select-selection-item {
      border-radius: 10px;
      font-size: 12px;
    }
    // .ant-select-selector {
    // 	border: 0;
    // }
    .ant-input {
      border: 0px;
      &:focus {
        box-shadow: unset;
        border-color: transparent;
      }
    }
    .form__group {
      width: calc(100% - 5%);
      margin-bottom: 0 !important;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: none;
      border-color: transparent;
    }
    &.active {
      animation-name: make;
      animation-duration: 0.5s;
      transition: opacity 0.7s cubic-bezier(0.42, 0.46, 0.45, 0.99),
        transform 0.7s cubic-bezier(0.42, 0.46, 0.45, 0.99);
    }
  }

  .email-text-input-internal {
    display: flex;
    padding-left: 19px;
    border-bottom: 1px solid #e6e6e6;
    min-height: 1px;
    width: 100%;
    min-height: 32px;
    .ant-form-item-control-input {
      min-height: auto;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      max-height: 120px;
      //overflow-y: auto;
    }
    // .ant-select-selection-item {
    //   line-height: 33px;
    // }
    .email-text {
      color: #6b6b6b;
      width: 113px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .ant-select-multiple .ant-select-selection-item {
      border-radius: 10px;
      font-size: 12px;
    }
    // .ant-select-selector {
    // 	border: 0;
    // }
    .ant-input {
      border: 0px;
      &:focus {
        box-shadow: unset;
        border-color: transparent;
      }
    }
    .form__group {
      width: calc(100% - 5%);
      margin-bottom: 0 !important;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: none;
      border-color: transparent;
    }
    &.active {
      animation-name: make;
      animation-duration: 0.5s;
      transition: opacity 0.7s cubic-bezier(0.42, 0.46, 0.45, 0.99),
        transform 0.7s cubic-bezier(0.42, 0.46, 0.45, 0.99);
    }
  }

  .text1 {
    .email-text {
      width: 40px !important;
    }
  }

  .email-text-input-ref {
    display: flex;
    padding-left: 19px;
    border-bottom: 1px solid #e6e6e6;
    min-height: 1px;
    width: 100%;
    min-height: 32px;
    .ant-form-item-control-input {
      min-height: auto;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      max-height: 120px;
      //overflow-y: auto;
    }
    .email-text {
      color: #6b6b6b;
      width: 113px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .ant-select-multiple .ant-select-selection-item {
      border-radius: 10px;
      font-size: 12px;
    }
    .ant-input {
      border: 0px;
      &:focus {
        box-shadow: unset;
        border-color: transparent;
      }
    }
    .form__group {
      width: calc(100% - 5%);
      margin-bottom: 0 !important;
      overflow: auto;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      box-shadow: none;
      border-color: transparent;
    }
    &.active {
      animation-name: make;
      animation-duration: 0.5s;
      transition: opacity 0.7s cubic-bezier(0.42, 0.46, 0.45, 0.99),
        transform 0.7s cubic-bezier(0.42, 0.46, 0.45, 0.99);
    }
  }
  .email_compose__header {
    color: #ffffff;
    width: 100%;
    height: 46px;
    padding: 10px;
    background-color: #ebebeb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--title {
      font-weight: bold;
      margin: 0;
    }
    .ant-image-img {
      width: auto;
    }
    .chat-img {
      margin-right: 10px;
    }
  }
}

.email-attachments {
  .email-title {
    font-weight: bold;
    margin-top: 20px;
  }
  img {
    height: 100px;
    width: 100px;
  }
  &.quick-msg-attach {
    overflow: hidden;
    font-size: 12px;
    height: 28px;
    border-top: 1px solid rgb(103 161 228 / 0.1);
    padding-top: 10px;
    margin-top: 10px;
    .email-pdf {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .anticon {
        font-size: 12px;
      }
      .instance-attachment {
        display: flex;
        align-items: center;
        .anticon-paper-clip {
          color: #212121;
        }
        .email-attach-name {
          max-width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 5px;
          color: #2563aa;
        }
      }
    }
  }
}

.top-header-email {
  display: flex;
  align-items: center;
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  box-shadow: inset 0px -1px 0px rgb(0 0 0 / 15%);
  margin: 0px !important;
  h4 {
    margin: 0px !important;
  }
  .form__group {
    margin-bottom: 0px !important;
    input {
      min-height: 30px !important;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
    border: unset;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 5px !important;
    height: 28px;
  }
  .ant-select {
    font-weight: bold;
    font-size: 12px;
  }
  .ant-select-arrow {
    font-size: 12px;
  }
}

.mail-second-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #d8ddeb;
  height: 35.5px;
  padding: 0 10px;
  cursor: pointer;
  .show-email-menu-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 35%;
    cursor: pointer;
  }
  .selection-mail-type {
    display: flex;
    align-items: center;

    width: 100%;
    .selected-left {
      display: flex;
      align-items: center;
    }
  }
}
.mail-third-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7px;
  input {
    border: 0px;
  }
  .ant-input-focused,
  .ant-input:focus {
    box-shadow: unset;
  }
  .contact__Detailsfav {
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    span {
      padding-right: 10px;
    }
  }
  .side__search__wrapper {
    .contact__header__btn-primary {
      height: 20px;
      width: 20px;
      display: flex !important;
      padding: 4px;
      border: 0px;
      color: white;
      align-items: center;
      background: #c53d38;
      span {
        font-size: 11px;
      }
    }
  }
}

.mail-main-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .main-title {
    font-size: 18px;
    font-weight: bold;
    flex: 2;
  }
  .refresh-header {
    height: 25px;
    width: 25px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .rotate {
    animation: rotate 1s;
    // transform: scaleY(0);
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(-180deg);
    }
  }
}

.email__footer {
  padding: 10px;
  background-color: #fff;
  text-align: right;
  button {
    height: 32px;
    border-color: rgba(0, 0, 0, 0.09) !important;
    color: #8a8a8a !important;
    box-shadow: unset;
  }
  .email-send-as {
    button {
      background: #3987fb !important;
      color: #fff !important;
      border-left-color: #2065cd !important;
    }
  }
}

.mail-leave-details {
  .leave-progerss {
    background: #eef2f8;
    padding: 5px 10px;
    margin: 10px;
    .ant-steps-item-container {
      display: flex;
      align-items: center;
      .ant-steps-item-icon {
        width: 25px;
        height: 25px;
        font-size: 14px;
        line-height: 25px;
      }
      .ant-steps-item-title {
        font-size: 14px;
      }
    }
  }
  .leave-details {
    background: #eef2f8;
    margin: 0 10px;
    padding: 10px;
    .leave-col {
      // margin-bottom: 15px;
      .leave-input {
        height: 32px;
        background: #fafafa;
        color: #333333;
        padding: 0 8px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 13px;
      }
    }
    .leave-details-footer {
      text-align: right;
      button {
        padding: 4px 8px;
        min-width: 68px;
        height: 24px;
        border-radius: 2px;
        color: #fff;
        font-size: 13px;
        line-height: 12px;
        &.leave-reject {
          background: #c4314b;
        }
        &.leave-accept {
          background: #13a10e;
          margin-left: 10px;
        }
      }
    }
  }
  .leave-types {
    margin: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title:after,
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title:after {
    background-color: #c4c4c4;
  }
}
.mail_procurement_details {
  .procurement_progress {
    background: #eef2f8;
    border-radius: 12px;
    padding: 5px 10px;
    margin: 10px;
    .ant-steps-item-container {
      // display: flex;
      //   align-items: center;
      .ant-steps-item-icon {
        width: 30px;
        height: 30px;
        // font-size: 14px;
        // line-height: 25px;
      }
      .ant-steps-item-title {
        font-size: 14px;
      }
      .ant-steps-item-description {
        font-size: 11px;
      }
    }
  }
  .procurement_details {
    background: #eef2f8;
    border-radius: 12px;
    margin: 0 10px;
    padding: 10px;
    .procurement_col {
      margin-bottom: 15px;
      .procurement_input {
        min-height: 32px;
        background: #fafafa;
        color: #333333;
        padding: 0 8px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 13px;
      }
    }
    .ant-form-item-label {
      text-align: left;
    }
  }
  .procurement_details_footer {
    // text-align: right;
    button {
      padding: 4px 8px;
      min-width: 68px;
      height: 24px;
      border-radius: 2px;
      color: #fff;
      font-size: 13px;
      line-height: 12px;
      &.procurement_reject {
        background: #c4314b;
      }
      &.procurement_approved {
        background: #13a10e;
        margin-left: 10px;
      }
    }
  }
  .procurement_accountant_management_form {
    label {
      font-weight: 600;
    }
  }
}

.procurement_table_approval {
  .ant-table-content {
    table {
      tr {
        td:nth-child(2) {
          max-width: 30px;
        }
      }
    }
  }
}
.mail_loan_details {
  .loan_progress {
    background: #eef2f8;
    border-radius: 12px;
    padding: 5px 10px;
    margin: 10px;
    .ant-steps-item-container {
      // display: flex;
      //   align-items: center;
      .ant-steps-item-icon {
        width: 30px;
        height: 30px;
        // font-size: 14px;
        // line-height: 25px;
      }
      .ant-steps-item-title {
        font-size: 14px;
      }
      .ant-steps-item-description {
        font-size: 11px;
      }
    }
  }
  .loan_details {
    background: #eef2f8;
    border-radius: 12px;
    margin: 0 10px;
    padding: 10px;
    .loan_col {
      margin-bottom: 15px;
      .loan_input {
        min-height: 32px;
        background: #fafafa;
        color: #333333;
        padding: 0 8px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 13px;
      }
    }
    .ant-form-item-label {
      text-align: left;
    }
  }
  .loan_details_footer {
    text-align: right;
    button {
      padding: 4px 8px;
      min-width: 68px;
      // height: 24px;
      border-radius: 2px;
      color: #fff;
      &.loan_reject {
        background: #c4314b;
      }
      &.loan_approved {
        background: #13a10e;
        margin-left: 10px;
      }
    }
  }
}

// .ck.ck-reset_all, .ck.ck-reset_all *{

// position: absolute;
// bottom: 0px;
// // }
// .ck.ck-editor {
//   position: relative;
//   width: 100%;
//   height: 129px;
//   p {
//     margin: 0px;
//   }
// }
.reply-Of {
  background: #67a1e4;
  padding-left: 16px;
  padding-top: 1px;
  color: white;
  font-size: 12px;
  padding: 9px 13px;
}
// .emailChat__left {
// 	position: relative;

// 	@media (max-width: 1100px) {
// 		overflow-x: scroll;
// 	}
// }

.emailReference__title {
  display: flex;
  @media (max-width: 1070px) {
    flex-direction: column;
  }
}
.email-star {
  padding: 0px 10px 0px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  height: 27px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes moveInWrapper {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    transform: translate(0);
  }
}
.emailList {
  width: 299px;

  border-right: 1px solid rgba(0, 0, 0, 0.1);
  @media (max-width: 1100px) {
    width: 255px !important;
    transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
  }
}

.emailLeft {
  width: calc(100% - 299px);
  @media (max-width: 1100px) {
    width: calc(100% - 255px) !important;
    transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
  }
}
.split {
  &__chat {
    position: relative;
  }
}

//for tags in emailist

.tags {
  padding-bottom: 3px;

  overflow-x: auto;
}
//quick add email scss
.quick_email_add_title {
  height: 40px;
  display: flex;
  justify-content: center;
  // background-color:  #33416e;
  h3 {
    color: #495057;
    font-weight: 600;
  }
}
.quick-addEmail {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: #b3b3b3;
  font-weight: 500;
  align-items: center;
  font-size: 12px;
  margin-bottom: 12px;
  .input-box {
    .ant-select-selector {
      border: none !important;
    }
  }
}
.official_quick_add {
  // border-right: 1px solid rgba(0, 0, 0, 0.15);
  max-width: 92.42px;
  .ant-form-item-control-input {
    .ant-select-selector {
      .ant-select-selection-item {
        font-weight: 750 !important;
        margin-left: -8px;
      }
    }
  }
}

.quick_email_add_top_email {
  .ant-form-item-control-input-content {
    .ant-input {
      color: #67a1e4 !important;
      margin-left: -4px;
    }
  }
}
.first_row_email_add {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  .inline-quick-email {
    flex: 0 0 17.7% !important;
  }
}
.quick_add_email_modal {
  .ant-modal-content {
    border-radius: 16px !important;
    .ant-modal-body {
      padding: 20px 20px;
    }
    .ant-modal-close {
      margin-right: 11px;
      margin-top: 5px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 8.5px !important;
  }
}
.advance_search_email {
  .ant-modal-content {
    border-radius: 16px !important;
    .ant-modal-body {
      padding: 20px 20px;
    }
    .ant-modal-close {
      margin-right: 11px;
      margin-top: 5px;
    }
  }
  .ant-modal-header {
    border-radius: 16px 16px 0px 0px !important;
    display: flex;
    justify-content: center;
  }
  .ant-modal-close {
    margin-top: -1px !important;
    margin-right: -2px !important;
  }
  .advance-btn-search-wrapper {
    margin-top: 15px;
  }
}
.button_add_task {
  appearance: none;
  backface-visibility: hidden;
  background-color: #33416e;
  border-radius: 10px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 35px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 1px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  margin-left: 15px;
}
.button_report {
  appearance: none;
  backface-visibility: hidden;
  background-color: #33416e;
  border-radius: 10px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 12px;
  font-weight: 500;
  height: 25px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 1px 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}
.quick_add_email {
  margin-top: -10px;
  display: flex;
  justify-content: end !important;
  margin-bottom: -8px;
}
.disable_button {
  cursor: not-allowed;
}

.input-box {
  width: 100%;
}
.mail-listing {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 9px 9px 9px 16px;
  word-wrap: break-word;
  box-shadow: inset 0px 0px 0px rgba(199, 199, 199, 0.25),
    inset 0px -0.5px 0px rgba(199, 199, 199, 0.25);

  &:hover {
    background-color: #c7c7c7 !important;
    cursor: pointer;
  }
  &--address {
    font-weight: 500;
    color: #6d6d6d;
    transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    display: flex;
    flex-wrap: wrap;
    line-height: 20px;
    word-break: break-all;
    word-wrap: break-word;
    width: 30%;
  }
  &--person {
    color: #000000;
    margin-right: 7px;
    width: 30%;
  }
  &--company {
    color: #3879c5;
    font-size: 14px;
    font-weight: 600;
    width: 30%;
  }
}
.table-active {
  background-color: #c7c7c7 !important;
}
.hover {
  cursor: pointer;
}
.list-group {
  box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.image-uploader {
  .ant-upload-list-item-info > span {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

// .test {
//   .ck.ck-toolbar > .ck-toolbar__items {
//     display: none;
//   }
//   &.active {
//     .ck.ck-toolbar > .ck-toolbar__items {
//       display: block;
//     }
//   }
//   .ck-editor__editable_inline {
//     max-height: 79vh;
//     overflow: scroll;
//     border-top: 1px solid #e6e6e6 !important;
//     margin-top: 7px;
//   }
// }
.test {
  .tox .tox-toolbar-overlord {
    display: none;
  }
  &.active {
    .tox .tox-toolbar-overlord {
      display: block;
    }
  }
}
.internal-ckEditor {
  .tox .tox-toolbar-overlord {
    display: none;
  }
  &.active {
    .tox .tox-toolbar-overlord {
      display: block;
    }
  }
}

.PaginationEmail {
  max-width: 203px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.infinite-scroll-component {
  overflow-x: hidden !important;
}

.ck.ck-reset_all,
.ck.ck-reset_all * {
  padding-left: 1px;
}
.side__search__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
  .anticon.anticon-plus > svg {
    width: 11px;
    height: 11px;
  }
}
.emailFilter {
  margin-bottom: 2px;
  cursor: pointer;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0px;
}
button.ant-btn.ant-btn-primary.ant-btn-background-ghost.save_icon_btn {
  border: 0;
  margin: 0;
  padding: 0;
}
img.save__icon {
  filter: invert(62%) sepia(8%) saturate(4000%) hue-rotate(160deg)
    brightness(83%) contrast(80%);
  margin-top: 22px;
}
.dropdown-attatchmemnt {
  background: #ffffff;
  border: 0.25px solid rgba(6, 18, 62, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(6, 18, 62, 0.2);
  border-radius: 15px;
  position: absolute;
  right: -3px;
  padding: 10px 15px;
  width: 260px;
  top: -5px;
  .attachment--info-wrapper {
    display: flex;
    align-items: center;
    column-gap: 5px;
    flex-flow: row wrap;
    width: 100%;
    .attachment--file-name {
      display: flex;
    }
    .single-attachment-action-wrapper {
      display: flex;
      a img {
        width: 16px;
      }
    }
  }
  .attachment-download-all-wrapper {
    margin-top: 10px;
    display: block;
    text-align: center;
    .attachment-download-all-btn {
      background: #f5f7f9;
      border: 1px solid #eceef0;
      box-sizing: border-box;
      border-radius: 2px;
      font-size: 12px;
      padding: 2px 10px;
      color: #000;
      font-weight: 600;
    }
  }
}
.view_all_detail_button {
  margin-left: 2px;
  // margin-top: -2px;
  .ant-btn-sm {
    height: 17px !important;
    // padding:0px 5px 0px 5px !important;
    border-radius: 10px !important;
    color: blue !important;
    font-size: 12px;
    display: flex;
    justify-content: center;
    background-color: #67a1e4;
    color: #fff !important;
  }
  span {
    cursor: pointer;
    color: #67a1e4;
    text-decoration: underline;
  }
}
.collapse_email_list {
  .example-collapse-email {
    display: flex;
    flex-flow: wrap;
    // width: 95%;
    // justify-content: row;
  }
}
.more_email_visible {
  margin-top: 3px;
}
.header__attachments {
  // .ant-dropdown.ant-dropdown-placement-bottomRight {
  //   background: #ffffff;
  //   border: 0.25px solid rgba(6, 18, 62, 0.5);
  //   box-sizing: border-box;
  //   box-shadow: 0px 0px 4px rgba(6, 18, 62, 0.2);
  //   border-radius: 4px;
  //   position: absolute;
  //   right: -27px;
  //   padding: 10px 15px;
  //   width: 300px;
  //   top: 10px;
  //   // min-height: 100px;
  // }
  margin-left: auto;
  .email-attachment {
    //border: 0.25px solid rgba(6, 18, 62, 0.5);
    background: #67a1e4 !important;
    box-sizing: border-box;
    //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 14px;
    padding: 1px 11px !important;
    margin-bottom: 3px;
    margin-top: 2px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5px 0px 5px;

    column-gap: 5px;
    .emailDetails__body--headerAttach {
      display: flex;
      align-items: center;
      column-gap: 3px;
      .attachment_lenght_count {
        font-family: SF Pro Display;
        font-style: normal;
        font-weight: 550 !important;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
      }
      .attach-icon {
        height: 11px;
      }
      span.attachement__chervon_down {
        filter: brightness(0) invert(1);
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.email_details_reply_popup {
  .meetingInfos {
    height: 300px !important;
  }
  .subject-wrapper {
    font-weight: 700;
  }
  .reference_listing_email_reply_details {
    padding: 0px 9px 0px 9px;
  }
}
.hover.aa__icon span {
  font-size: 16px;
  display: flex;
  height: 19px;
  align-items: center;
  justify-content: flex-end;
}

.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}
.tox .tox-statusbar {
  display: none !important;
}

/** attachment dropdown **/
.attachmentItem {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 6px;
  align-items: center;
  word-break: break-all;
  overflow: hidden;
}
.attachmentItem:last-child {
  margin-bottom: 0px;
}
.attachmentItem img {
  filter: brightness(3);
  width: 20px;
}
.attachmentItem a {
  color: #2563aa;
  font-size: 12px;
  line-height: 17px;
  width: 78%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.attach-filename {
  max-width: 82%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
span.anticon.anticon-ellipsis svg {
  transform: rotate(90deg);
}

/** end of attachment dropdown **/
.sub-menu-wrapper {
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    span:nth:child(2) {
      width: 80% !important;
    }
    .all-menu-list {
      .badge.badge--notificationSmall {
        position: static;
        margin-top: 14px;
      }
    }
    .all-menu-list:hover {
      background: none !important;
      color: #6d6d6d !important;
    }
  }
  i {
    order: 3;
    margin-left: auto;
  }
}
.sub-menu-wrapper.active {
  background: #d8ddeb;
}
.side-padding {
  .ant-menu-item-active {
    background-color: red;
  }
}

.emailList__card--title.email-title-icon .text-left .ant-image img {
  width: 12px !important;
  height: 11px;
  margin-left: -1px;
}
.emailList__card {
  &:hover {
    .email-list-checkbox-wrapper {
      // display: none;
      position: absolute;
      // left: 3px;
      // bottom: 9px;
      width: 10px;
      height: 10px;
      display: flex;
      border-radius: 50%;
    }
    // .text-left{
    //   display:none;
    // }
    // margin-right: 4px;
  }
  input[type="checkbox"]:checked {
    // height:20px;
    // width:20px;
  }
}

// .emailList__card false

.emailDetails__body--chat .chatItem__wrapper .chatItem__head {
  display: flex;
  justify-content: space-between;
}
.emailDetails__body--chatwrap .chatItem {
  padding: 0px 5px;
}
.emailDetails__body--chatwrap .chatItem__wrapper .ant-badge {
  width: 39px;
}
.emailDetails__body--chatwrap .chatItem__wrapper .ant-badge img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.chatInfo__wrapper {
  width: 90% !important;
  background-color: #f5f5f5;
  padding: 4px 12px;
  border-radius: 15px;
  position: relative;
  border-top-left-radius: 0px !important;
  margin-right: auto;
  padding-bottom: 15px;
}
.chatInfo__wrapper.active {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 0px !important;
}
.chatItem:first-child {
  margin-top: 5px;
}
.chatItem .chatInfo__wrapper.active {
  background: #d6e9ff;
  margin-left: auto;
  margin-right: 0px;
}
.chatInfo__wrapper.active .chatItem__title {
  color: #67a1e4;
}
.chatInfo__wrapper.active .chatItem__body p {
  color: #616161 !important;
  font-size: 14px;
  font-weight: 500;
}
.chatInfo__wrapper.active .chatItem__right--date span {
  color: #616161;
}
// .emailDetails__body--chatwrap .chatItem.active .chatInfo__wrapper{
//   background:#67a1e4 !important;
// }
// .chatItem.active .chatItem__body p {
//   color: #fff !important;
// }

// .chatItem.active .chatItem__right--date span {
//   color: #fff !important;
// }
.ant-badge-count {
  min-width: 15px;
  height: 15px;
  width: 15px;
  font-size: 10px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.emailDetails__body--chatwrap .chatItem__wrapper sup {
  top: 5px;
  left: 14px;
}
.chatHead_wrap {
  display: flex;
  // align-items: center;
  padding-bottom: 15px;
}
.chatHead_wrap .chatItem__right {
  margin-left: auto;
  display: flex;
  column-gap: 5px;
}
.onHover__cc__to_details {
  position: absolute;
  left: 100%;
  background: #dbdbdb;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  padding: 0px 3px;
  top: 21px;
  left: 13px;
  // min-width: 100px;
  // max-width: 70%;
  display: none;
  z-index: 1111;
  border-radius: 5px;
  line-height: 15px;
  .chatItem__mailTo {
    line-height: 12px;
  }
}
span.chatItem__mailTo {
  width: 100%;
  overflow: hidden;
}
.chatItem__left--content:hover .onHover__cc__to_details {
  display: block;
}
.on__hover_chat_image {
  display: block;
  transition: 0.8s;
}
.on__hover_chat_image img {
  width: 100% !important;
  height: 100px !important;
  border-radius: 2px !important;
  object-fit: cover !important;
}
.onHover__cc__to_details .chatItem__mailTo {
  font-size: 10px;
  display: inline-block;
}
.chatInfo__wrapper.active .chatItem__right--date div {
  color: #616161;
}
.emailDetails__body--chatwrap
  .chatItem__head
  .ant-badge
  .ant-image:hover
  .ant-badge
  .onHover__cc__to_details {
  display: block !important;
  z-index: 11;
}
.ant-popover-inner {
  padding: 6px;
}
.emailDetails__body--chatwrap .chatInfo__wrapper .chatItem__right--date {
  display: flex;
  column-gap: 0px;
}

.email-attachments.quick-msg-attach.chat_attachment {
  width: 10%;
  margin-top: 0px;
  height: 20px !important;
  justify-content: left !important;
  column-gap: 2px;
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding-top: 0px !important;
}
.email-attachments.quick-msg-attach.chat_attachment img:first-child {
  margin-bottom: 4px;
}
ul.chat_attachment_list {
  padding-bottom: 0px !important;
  margin-bottom: 0;
}
.chat_attachment_list .email-pdf.chat_attachment_item {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.chatInfo__wrapper ul.chat_attachment_list {
  border-top: 1px solid #ccc;
}
.chat_attachment_item .instance-attachment {
  margin-left: 5px;
}
.chatInfo__wrapper.active ul.chat_attachment_list {
  border-top: 1px solid #dccc;
}
.email-pdf.chat_attachment_item a {
  font-size: 11px;
}
.chatInfo__wrapper .email-pdf.chat_attachment_item a {
  color: #303030;
}
.chatInfo__wrapper.active .email-pdf.chat_attachment_item a {
  color: #f4f4f4;
}
.chatInfo__wrapper .chatItem__body {
  align-items: top;
}
.to__cc__icon_wrapper {
  margin-right: 3px;
  float: left;
}

span.to_icon {
  font-size: 7px;
  font-weight: 800;
  background: #bdb9b9;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  margin-top: 3px;
  padding: 1px 3px;
  border-radius: 3px;
}
span.cc_icon {
  font-size: 9px;
  font-weight: 800;
  background: #c53d38;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  margin-top: 3px;
}

.delivery__detect p {
  margin: 0;
  font-size: 8px !important;
  text-align: right;
  color: #6d6d6d;
  transform: rotate(23deg);
  font-weight: 600;
}
.delivery_icon_wrapper {
  display: flex;
  justify-content: right;
}
.delivery_icon_wrapper p:last-child {
  margin-left: -3px;
  margin-top: 1px;
}

.chatInfo__wrapper.active
  .delivery__detect
  .delivery_icon_wrapper
  p:last-child {
  margin-left: -4px;
}

.sidebars.email .sidemenu-scroll {
  overflow-x: hidden;
}
.sidebars.email .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0px !important;
  padding-left: 35px !important;
}
.sidebars.email .ant-menu-submenu {
  margin-bottom: 0px;
  box-shadow: none;
}
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline.other__option_menu {
  display: none;
}
.chatInfo__wrapper.false .delivery_icon_wrapper p {
  margin-left: -5px;
}
.mail-side-menu span svg {
  height: 11px;
  margin-left: 6px;
}
.mail-counter {
  margin-left: auto;
}
.emailFilter {
  // display: none;
}
.emailDetails__header.top-header-email {
  overflow: hidden;
}
.loader_wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 99;
  align-items: center;
  height: 100%;
}
.loader_wrapper img {
  height: 150px !important;
  width: auto !important;
}
// @media only screen and (max-width: 1900px){
//   .emailDetails__body--chatwrap {
//       height: 75vh;
//   }
// }
// @media only screen and (min-width: 1901px){
//   .emailDetails__body--chatwrap {
//     height: 83vh;
// }
// }
// @media only screen and (max-width: 1300px) {
//   .emailDetails__body--chatwrap.showHeight {
//       height: 30vh !important;
//   }
//   .emailDetails__body .meetingInfos.height {
//       height: calc(100vh - 138px);
//   }
// }

.emailInfo.full-row .top-header-email {
  padding: 3px 10px;
  height: 48px;
  position: relative;
  top: 0;
  width: 298px;
}
.chatItem.active .onHover__cc__to_details span {
  color: #000 !important;
}
.inbox-btn-wrapper {
  display: flex;
}
.emailDetails__header.top-header-email {
  overflow: hidden;

  //width: calc(100% - 255px + 71px);
  height: 48px;
}
.emailInfo.full-row .top-header-email {
  padding: 3px 10px;
  height: 48px;
  width: 298px;
}
.mail-third-menu {
}
div#uzz {
  display: block;
}
.dashboard {
  position: relative;
  top: 0;
}
.main__body {
  display: block;
  overflow: hidden;
}

.dashboard__body--infos {
  width: 100%;
  &.chatVisible {
    width: calc(100% - 543px);
  }
  overflow-y: auto;
  .gutter.gutter-horizontal {
    display: none;
  }
  .email-description {
    overflow: auto;
    position: relative;
  }
}

.emailDetails__body--infos {
  width: calc(100% - 38px);
  &.chatVisible {
    width: calc(100% - 543px);
  }
  overflow-y: auto;
  .gutter.gutter-horizontal {
    display: none;
  }
  .email-description {
    overflow: auto;
    position: relative;
  }
}

.emailDetails__body--chat,
.chatmail__header--wrapper-hide.chatmail__image {
  top: 118.5px;
  height: calc(100% - 110px);
}
.emailDetails__body--chat.isCC,
.chatmail__header--wrapper-hide.chatmail__image.isCC {
  top: 126px;
  height: calc(100% - 124px);
  transition: none;
}

.chat-read-unread-icon-wrapper {
  width: 7%;
  display: flex;
  align-items: center;
}
.message_body_wrapper {
  width: 90%;
}
.chat-read-unread-icon-wrapper span svg {
  font-size: 14px;
  color: #918e8e;
}
.my-chat-info-wrapper .chat-cc-info {
  color: #6d6d6d;
}
.arrowfont-icon {
  font-size: 11px;
}
.chat-action-wrapper {
  background: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
  transition: 0.4s;
  flex-flow: row wrap;
}
.chat-action-wrapper a {
  margin: 0px !important;
}
.chat__replayIcon_wrapper {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  margin-right: 10px;
}
.chatInfo__wrapper:hover .chat__replayIcon_wrapper {
  display: flex !important;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  opacity: 1;
}
.chat-action-wrapper {
  position: absolute;
  right: 11px;
  bottom: -7px;
  z-index: 99;
}

.chat-action-wrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-action-wrapper:hover {
  height: 50px !important;
  border-radius: 4px;
  width: 75px;
  padding: 0px 4px;
}
.chat-action-wrapper:hover .noneIcon {
  transition: 1s;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.chat-action-wrapper .noneIcon {
  display: none;
  transition: 1s;
}
.chat-action-wrapper:hover {
  // height: 55px !important;
  height: 93px !important;
  border-radius: 10px;
  width: 90px;
  animation: fadeIn 0.6s;
  -webkit-animation: fadeIn 0.6s;
  -moz-animation: fadeIn 0.6s;
  -o-animation: fadeIn 0.6s;
  -ms-animation: fadeIn 0.6s;
  opacity: 1;
  padding: 0px;
  overflow: hidden;
}
.emailDetails__body--chatwrap .chatItem__right--icons .ant-image img {
  width: 10px;
  margin-left: auto;
}

.chat-action-wrapper .ant-image:hover span {
  color: #67a1e4;
}
.chat-action-wrapper .ant-image:hover {
  background: #f2f2f2;
}
.chatReply-desc {
  font-size: 11px;
  transition: 0.8s;
  color: #6d6d6d;
}
.chat-action-wrapper:hover .ant-image {
  justify-content: left !important;
  width: 100%;
  padding: 3px 7px;
  padding-bottom: 5px;
}
.chat-action-wrapper .ant-image:hover img {
  -moz-filter: grayscale(100%) sepia(76%) brightness(91%) hue-rotate(167deg)
    saturate(415%) contrast(102%) invert(3%);
  filter: grayscale(100%) sepia(76%) brightness(76%) hue-rotate(167deg)
    saturate(415%) contrast(102%) invert(3%);
  -webkit-filter: grayscale(100%) sepia(52%) brightness(125%) hue-rotate(167deg)
    saturate(411%) contrast(100%) invert(1%);
  -moz-filter: grayscale(100%) sepia(76%) brightness(76%) hue-rotate(167deg)
    saturate(415%) contrast(102%) invert(3%);
}
.reply-counter-item {
  color: #67a4e4 !important;
  font-weight: 700;
  font-size: 10px;
}
.chat-date-item {
  margin-right: 3px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.my-chat-info-wrapper {
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;

  white-space: nowrap;
}

.email-attachments.quick-msg-attach.chat-attachment-item {
  padding: 0;
  margin: 0;
  margin-right: auto;
  height: auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  width: 45px;
  background: #fff;
  border-radius: 15px;
  grid-column-gap: 4px;
  grid-column-gap: 4px;
  grid-column-gap: 4px;
  column-gap: 4px;
  margin-top: 5px;
  height: 16px !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  padding-left: 3px;
}
img.attachmentImage-chat {
  padding: 0px !important;
  width: 8px !important;
}
.email-attachments.quick-msg-attach.chat-attachment-item
  a.ant-dropdown-trigger
  span
  img {
  padding-bottom: -32px !important;
  margin-bottom: 3px;
}
.emailDetails__body--chatwrap {
  height: calc(100% - 40px);
}
.emailDetails__body--chatwrap.showHeight {
  height: calc(100% - 300px - 48px - 35px);
}
.emailDetails__body--chatwrap.showSmallHeight {
  height: calc(100% - 40px - 42px);
}
span.anticon.anticon-arrow-down.attach-download-icon {
  margin-left: auto;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}
span.attachment_lenght_count {
  color: #164f92;
  font-weight: 500;
}
ul.chat_attachment_list.instantchat-attachment-wrapper
  .email-attachments.quick-msg-attach.chat-attachment-item {
  position: absolute;
  bottom: 8px;
}
ul.chat_attachment_list.instantchat-attachment-wrapper {
  height: 15px;
  width: 100%;
}
.dropdown-attatchmemnt.instantHover {
  left: -30px !important;
}
.message_body_wrapper ol,
.message_body_wrapper ul {
  margin-left: 14px;
}
//responsive
@media only screen and (max-width: 2200px) {
  .emailDetails__body--chat {
    width: 543px !important;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
}
@media only screen and (max-width: 1920px) {
}
@media only screen and (max-width: 1500px) {
  .emailDetails__body--chat {
    width: 403px !important;
  }
  .emailDetails__body--chatwrap .chatItem__left {
    display: flex;
    width: 55%;
  }
  .no-selected-wrapper {
    height: calc(100% - 100px);
    width: calc(100% - 71px - 299px);
    font-size: 42px;
  }
}

@media only screen and (max-width: 1100px) {
  .emailInfo.full-row .top-header-email {
    width: 254px;
  }

  .emailDetails__body--chat {
    top: 112px;
  }
}
.no-selected-wrapper {
  position: fixed;
  height: calc(100% - 100px);
  width: calc(100% - 71px - 299px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 54px;
  color: #ccc;
  overflow: hidden;
  z-index: 99;
  margin: auto auto;
  flex-flow: row wrap;
}
.no-data-wrapper {
  font-size: 30px !important;
  text-align: center;
  padding: 60px 0px;
  color: #ccc;
}

@media only screen and (max-width: 1200px) {
  .meetingInfos {
    width: calc(96.5% - 2px);
  }
  .emailDetails__body--chat {
    top: 112px;
  }
  .enailDetails__body--chat.isCC {
    top: 124px;
  }

  .no-selected-wrapper {
    width: calc(100% - 71px - 249px);
    font-size: 32px;
  }
}
@media only screen and (max-width: 1130px) {
  .emailDetails__body--chatwrap .chatItem__left {
    width: 30%;
  }
}
@media only screen and (max-width: 1040px) {
}
@media only screen and (max-width: 950px) {
}
@media only screen and (max-width: 800px) {
}
@media only screen and (max-width: 700px) {
}
iframe.meetingInfos {
  width: 100% !important;
}
p.main_content {
  margin: 0px !important;
  padding: 0px !important;
}

.instant-scroller {
  .ant-row.ant-form-item.form__group {
    height: 35px;
    overflow-y: auto;
  }
}
.ant-upload.ant-upload-select.ant-upload-select-text img {
  height: 14px;
}
.email-title--show .subject-wrapper {
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap !important;
  max-width: 98%;
  display: block;
  position: relative;
}
.email-title--show .subject-wrapper::after {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  line-height: 0;
}
.email-title--show .subject-wrapper::before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  line-height: 0;
}

.email-from-date {
  height: 15px;
}
.email-to-cc {
  // height: 15px;
}
.emailList__card--comp span {
  padding: 0px 2px;
}

.mailbox__info {
  padding: 0px 3px;
  .emailList__card--title.email-title-icon .ant-image img {
    margin-top: -2px;
  }
  .emailList__card--smallTitle.email-title-icon .ant-image img {
    margin-top: -2px;
  }
  .email-title-icon .ant-image img {
    margin-top: -2px;
  }
}
img.ant-image-img.replyAll__Icon {
  filter: brightness(1.5);
  width: 15px;
}
.emailDetails__header--icon.archive__header--icon.false {
  margin-left: 1px;
}
.auto_reference_class {
  .anticon-audit {
    opacity: 0.5;
  }
}
.emailDetails__header--icon {
  .zoom_in_property {
    zoom: 150%;
  }
  .zoom_show_button {
    background-color: #fff;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    box-shadow: rgb(213 217 217 / 50%) 0 2px 5px 0;
    box-sizing: border-box;
    color: #0f1111;
    cursor: pointer;
    display: inline-block;
    font-family: "Amazon Ember", sans-serif;
    font-size: 11px;
    line-height: 20px;
    // padding: 0 10px 0 11px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: 75px;
  }
}
.mail-iframe-wrapper {
  // zoom: 200%;
  // position: relative;
  // position: absolute;
  // left: 0px;
  // transform: scale(2);
}

.loader--action-wrapper {
  position: relative;
  .Statusloader {
    position: absolute;
    z-index: 111;
    top: 7%;
    left: 32%;
  }
}
.inbox-counter-wrapper {
  margin-left: 45px;
}
.mailbox-child-title-wrapper {
  display: flex;
  align-items: center;
  padding-left: 5px;
}
li.ant-menu-item.sidebars__body--item span {
  width: 100%;
}

span.mail-child-counter {
  margin-left: auto;
  text-align: right;
  font-size: 12px;
  color: #67a1e4;
}
span.ant-avatar.ant-avatar-square.ant-avatar-image.inbox--icon img {
  width: 20px;
}
.emailDetails__header--icon:hover img.ant-image-img.replyAll__Icon {
  filter: sepia(625%) hue-rotate(189deg) saturate(403%) brightness(1.6)
    contrast(1);
}
#replyChatBox {
  .ant-row.ant-form-item.form__group.ant-form-item-has-success {
    height: 40px;
    overflow-y: auto;
  }
}
.emailFilter.search-filter-icon {
  background: #f2f2f2;
  padding: 0px;
  margin: 0px;
  margin-right: 5px;
  padding: 0px 4px;
  border-radius: 2px;
}
// .email-title--show span {
//   text-overflow: ellipsis;
//   width: 360px;
//   display: block;
//   overflow: hidden;
//   line-height: 16px;
// }
.email-info-header-wrapper {
  position: relative;
  .email-header-drop-wrapper {
    .drop-button {
      background: #fff;
      padding: 0px 10px;
      border-radius: 10px;
      font-size: 9px;
      font-weight: 700;
      margin-left: 6px;
      height: 13px;
      display: flex;
      align-items: center;
    }
    .email-drop-down-list-wrapper {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 13px;
      .email-drop-down-list {
        display: block !important;
        min-width: 120px;
        position: absolute;
        //top:5px;
        background: #dbdbdb;
        padding: 5px;
        z-index: 99;
        max-width: 250px;
        box-shadow: 0 0 2px 1px rgb(0 0 0 / 10%);
        .test21 {
          margin-top: 5px !important;
          margin-bottom: 5px;
        }
        .test21:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.email-to-cc {
  .email-drop-down-list-wrapper {
    .email-drop-down-list {
      top: 0px !important;
    }
  }
}
.email-to {
  .email-drop-down-list-wrapper {
    .email-drop-down-list {
      left: -40px;
    }
  }
}

.email-header-drop-wrapper:hover .email-drop-down-list-wrapper {
  display: block;
}
.emailReference__title {
  display: block;
  width: 100%;
}
.plusIcon {
  margin-left: 2px;
}
.emailList__card--refComp.text-right.tags {
  width: 100%;
  margin-left: -20px;
}
.emailList__card--comp {
  margin-right: 3px;
}

.custom-tooltip {
  position: absolute;
  background: #dbdbdb;
  border-radius: 4px;
  color: #111;
  padding: 1px 3px;
  max-width: 250px;
  z-index: 99;
  font-size: 12px;
  font-weight: 500;
  top: 20px;
  white-space: break-spaces;
  text-align: left;
  line-height: 15px;
  display: block;
  box-shadow: 0 0 1px 2px rgb(0 0 0 / 10%);
}
.custom-tooltip::before {
  content: "";
  border: solid;
  border-color: #dbdbdb transparent;
  border-width: 10px 4px 0 6px;
  left: 11%;
  position: absolute;
  transform: rotate(60deg);
  top: -4px;
  display: block;
}

.reference-dropdown-wrapper .reference-count-wrapper {
  background: #d8ddeb;
  padding: 0px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 12px;
  font-size: 9px;
  color: #111;
}
.reference-dropdown-wrapper .reference-dropdown {
  display: none;
  .reference-dropdown-bg {
    position: absolute;
    z-index: 99;
    background: #dbdbdb;
    width: 250px;
    padding: 5px;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 30%);
    top: 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    row-gap: 7px;
    .emailList__card--ref {
      // width: calc(100% / 3 - 5px);
      width: 74px;
      display: block;
      width: 74px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .reference-tags {
      background: #fff !important;
    }
  }
}
.reference-dropdown-wrapper:hover .reference-dropdown {
  display: block;
}
.emailList__card.active {
  .emailList__card--desc.text-right--description {
    color: #fff;
    opacity: 1;
  }
}
.junk-wrapper img {
  width: 71%;
}
.ant-modal-content {
  .organizer__table.person-table {
    table tr td,
    table tr th {
      text-align: left;
    }
  }
}
.system-error-wrapper span {
  color: red;
}
.junk-icon {
  width: 75%;
}
.test21 div {
  font-weight: 300;
  font-size: 12px;
}
.meetingInfos.noInternalMessage {
  width: 100% !important;
}
a.compose-email-header span {
  color: #afafaf;
  font-size: 14px;
}
.emailDetails__header--icon:hover a span {
  filter: sepia(100%) hue-rotate(190deg) saturate(500%);
}
.compose-email-row {
  .tox.tox-tinymce {
    height: 100% !important;
  }
}
.compose-email-row-58-31,
.compose-email-row-31-58 {
  .tox.tox-tinymce {
    height: 96% !important;
  }
}
.compose-email-row-86-31,
.compose-email-row-31-86 {
  .tox.tox-tinymce {
    height: 92% !important;
  }
}
.compose-email-row-86-58,
.compose-email-row-58-86 {
  .tox.tox-tinymce {
    height: 89% !important;
  }
}
.compose-email-row-58-58 {
  .tox.tox-tinymce {
    height: 94% !important;
  }
}

.compose-email-row-86-86 {
  .tox.tox-tinymce {
    height: 85% !important;
  }
}
.compose-editor-wrapper {
  position: fixed;
  width: 100%;
  height: calc(100% - 206px);
  padding-bottom: 10px;
}
.compose-editor-wrapper.internalmessage-active {
  width: calc(100% - 35%);
}
.internal-attachment-wrapper--info {
  .email-text.attachemnt---text {
    width: 12% !important;
  }
  .ant-row.ant-form-item.form__group.ant-form-item-has-success {
    width: calc(100% - 12%);
    overflow-y: auto;
  }
}

.mail-quick-message {
  input#dynamic_form_nest_item_subject {
    padding: 0;
  }
  .email-text-input {
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
    .ant-select-show-search.ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        display: flex;
        width: 100%;
        max-height: 86px;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }
  }
}
span.anticon.anticon-close-circle.ant-input-clear-icon svg {
  width: 18px;
  height: 19px;
}
.tender-ref-model {
  .email-title {
    max-height: 39px;
    overflow-y: auto;
  }
  .tag-not-selected p {
    margin: 0;
    font-size: 11px;
    text-align: center;
    color: #ddd;
    line-height: 12px;
    font-weight: 300;
  }
}
.tender-ref-model {
  .contactDetails__header {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    height: auto;
    min-height: 30px;
  }
  .tender-ref-header-wrapper {
    display: flex;
    flex-flow: column-reverse;
  }
  .email-title {
    padding: 5px 10px;
    .emailList__card--refComp.text-right.tags {
      margin-left: 0;
      padding: 0;
      display: flex;
      flex-flow: row wrap;
      row-gap: 5px;
      .emailList__card--ref {
        margin-bottom: 0px;
      }
    }
  }
}
.add__user__form {
  .add-new-email-form-btn {
    margin-left: auto;
  }
}
.search-wrapper {
  span.anticon.anticon-close-circle.ant-input-clear-icon {
    margin-right: 5px;
  }
}
input#dynamic_form_nest_item_participantTos,
input#dynamic_form_nest_item_participantCCs,
input#dynamic_form_nest_item_mailMessageReferenceDtos,
input#dynamic_form_nest_item_subject {
  padding: 0px !important;
  margin: 0px !important;
}
.compose-subject-wrapper {
  .form__group {
    // width: calc(100% - 250px) !important;
  }
  .priority-wrapper {
    height: 30px;
    width: 250px;
  }
  input {
    height: 34px !important;
  }
}
.bg-none {
  background: none !important;
}

// acknowlegement
.d-none {
  display: none;
}
.acknowledge-check-wrapper {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  .acknowledge-icons-wrapper {
    display: none;
    animation: fadeIn 0.6s;
    -webkit-animation: fadeIn 0.6s;
    position: relative;
    .acknowledge-icons {
      ul {
        padding: 0px;
        list-style: none;
        background: #fff;
        width: 20px;
        height: 20px;
        box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 50%);
        border-radius: 50%;
        justify-content: center;
        display: flex;
        margin-bottom: 0px;
        flex-flow: row wrap;
        transition: 0.4s;
        li {
          label {
            span {
            }
            img {
              width: 13px;
            }
          }
          input {
            display: none;
          }
        }
        &:hover {
          height: 46px !important;
          border-radius: 3px;
          li {
            transition: 0.8s;
            display: block;
            span {
              display: block;
            }
          }
        }
      }
    }
  }
  &:hover {
    .acknowledge-icons-wrapper {
      display: block;
    }
  }
}

@media only screen and (max-width: 1500px) {
  // .compose-subject-wrapper{
  //   .form__group{
  //     width: calc(100% - 27%) !important;
  //   }
  // }
  .emailDetails__body--infos {
    &.chatVisible {
      width: calc(100% - 403px);
    }
  }
}
@media only screen and (max-width: 1400px) {
  // .compose-subject-wrapper{
  //   .form__group{
  //     width: calc(100% - 29%) !important;
  //   }
  // }
}
@media only screen and (max-width: 1300px) {
  // .compose-subject-wrapper{
  //   .form__group{
  //     width: calc(100% - 32%) !important;
  //   }
  // }
}
@media only screen and (max-width: 1200px) {
  // .compose-subject-wrapper{
  //   .form__group{
  //     width: calc(100% - 34%) !important;
  //   }
  // }
}
.leave-input.p-0 {
  padding: 0px;
}
.composer-form {
  span.ant-select-selection-search {
    input {
      border: 0px !important;
    }
  }
}
.email-description {
  position: relative;
}
.highlight-wrapper {
  position: absolute;
}

button.ant-btn.email-send-as.save-continue {
  &:hover {
    background: none !important;
  }
  .btn__chat {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.85);
  }
}
button.ant-btn.email-send-as.save-continue {
}
.compose-action-wrapper {
  display: flex;
  column-gap: 8px;
  button {
    padding: 0px !important;
    margin: 0px !important;
  }
  .ant-btn-group.ant-dropdown-button.email-send-as {
    padding: 0px !important;
    margin: 0px !important;
  }
}
span.ant-select-selection-search input {
  border: 0px !important;
}
button.ant-btn.email-send-as.send-close {
  margin-left: 11px !important;
  margin-top: 16px !important;
}
button.ant-btn.email-send-as.save-continue {
  padding: 0px !important;
  margin: 0px !important;
  .btn__chat {
    background: none !important;
    font-size: 16px;
  }
}
.ant-space-item {
  .switch-divv {
    margin-left: 10px;
    color: black;
  }
}
.compose-reference-select-dropdown {
  .ant-select-item {
    padding: 0px !important;
    &:last-child {
      .compose-reference-wrapper {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .compose-reference-wrapper {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    .compose-reference-title {
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      width: 35%;
      span {
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .compose-reference-description {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 15px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      width: 35%;
      span {
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .compose-reference-code {
      width: 30%;
      display: flex;
      align-items: center;
      padding-left: 15px;
      span {
        color: black;
      }
    }
    .compose-references-code {
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      width: 15%;
      span {
        color: #67a1e4;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .compose-references-title {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 15px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      width: 35%;
      span {
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .compose-references-description {
      width: 50%;
      display: flex;
      align-items: center;
      padding-left: 15px;
      span {
        width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.emailList-item {
  position: relative;
  .email-list-checkbox-wrapper {
    display: none;
    // position: absolute;
    // // left: 3px;
    // // bottom: 9px;
    // width: 10px;
    // height: 10px;
    // // display: flex;
    // border-radius:50%;
  }
  // &:hover{
  // display: flex;
  // }
  input.email-list-checkboxes {
    width: 15px;
    height: 15px;
  }
  .emailCardCheckBox {
    position: absolute;
    // left: 3px;
    // bottom: 9px;
    width: 10px;
    height: 10px;
    display: flex;
    border-radius: 2px;
  }

  .email-list-label {
    border: 1px solid #ccc;
    width: 100%;
    height: 100%;
    position: relative;
    background: #fff;
    position: relative;
  }
  .email-list-checkbox {
    display: none;
    &:checked + .email-list-label:before {
      content: "";
      position: absolute;
      display: inline-block;
      transform: rotate(38deg);
      height: 13px;
      width: 9px;
      border-bottom: 3px solid #648544;
      border-right: 3px solid #648544;
      left: 1px;
      bottom: 2px;
    }
  }
}

.multiple-selection-wrapper {
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: center;
  display: flex;
  align-items: center;
  span {
    font-size: 45px;
    font-weight: 500;
    color: #ccc;
  }
}

.email-list-menu-action-wrapper {
  position: fixed;
  z-index: 99;
  width: 200px;
  box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
  background: #fff;
  list-style: none;
  border-radius: 6px;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  li {
    padding: 7px 10px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    .right-click-menu-item {
      display: flex;
      column-gap: 10px;
      align-items: center;
      width: 100%;
      img {
        width: 20px;
      }
      p {
        font-size: 15px;
        margin: 0px;
      }
    }
    &:hover {
      background: #fafafa;
    }
  }
}
.send_to_all {
  color: black;
  // margin-left:490px;
  position: absolute;
  // right: 50px;
  right: 100px;
}
.email-to-Bcc {
  display: flex;
  width: 100%;
  .title {
    color: #0f0f0f;
    width: 42px;
    font-weight: 600;
    font-size: 12px;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}
//   .emailList * , .navbars *,.emailDetails__header *,.top-header-email , .emailDetails__body--title *{
//     visibility: hidden;
//   }
//   .emailDetails__body--title ,.emailDetails__body--infos * {
//     visibility: visible;
//   }
//   .emailDetails__body {
//     padding: 30px;
//     position: absolute;
//     left: 0;
//     right: 0;
//     margin: auto;
//     top: 0;
//   }
//   .emailList{
//     border:none;
//   }
//   .emailDetails__body--infos {
//     page-break-after: always;
//   }
// }

// @media print {
//   #scrollableDiv{
//    width: 100%;
//    height: 100%;
//   }
// .visible-print{
//   display: block;
//   width: auto;
//   height: auto;
//   overflow: visible;
//  }
// }
.selectAll_emailList_section {
  position: absolute;
  z-index: 1;
  top: 120.8px;
  left: 5px;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// zoom in out property

.zoom_in_property {
  margin-right: 30px;
  span {
    height: 20px;
  }
  // .ant-btn-circle{
  //   height: 20px !important;
  //   width: 20px !important;
  // }
  .ant-btn-round.ant-btn-sm {
    height: 20px !important;
    width: 20px !important;
  }
  // height:10px;
  .zoominout_button {
    button {
      cursor: pointer;
      background-color: none;
      width: 20px;
      height: 10px;
    }
  }
}
.zoom_In_Out {
  // height:1px;
  margin-right: 60px;
  border-right: 1px solid #e2e2e2;
}

//emaildetails page more email view modal scss
.email_list_modal {
  border-bottom: 1px solid #ebebeb;
  color: #495057;
  font-weight: 600;
  display: flex;
  justify-content: center;
}
.email_list_modal_view {
  display: block;
}
.popup_modal {
  display: flex;
}
.more_emails_modal {
  .ant-modal-content {
    border-radius: 12px !important;
  }
  .ant-modal-body {
    max-height: 300px;
    overflow: scroll;
  }
}
.more_emails_popup {
  margin-top: 10px;
  border-top: 1px solid #ebebeb;
}
//email log issue display model
.email_log_model {
  .ant-modal-content {
    border-radius: 16px !important;
  }
  .ant-modal-header {
    border-radius: 16px 16px 0px 0px !important;
    .ant-modal-title {
      color: #495057 !important;
      font-weight: 700 !important;
      display: flex;
      justify-content: center;
    }
  }
}
.popup_modal_internal_email {
  .ant-modal-body {
    padding: 0px !important;
  }
}

.internal_message_popup_email_section {
  display: flex;
  justify-content: space-between;
  background-color: #67a1e4;
  height: 30px;
  // padding-top: 5px;
  padding: 5px 5px 5px 5px;
  .chat-box-send-wrapper {
    cursor: pointer;
    .ant-btn-group.ant-dropdown-button {
      padding: 0px !important;
      margin: 0px !important;
      display: flex;
      column-gap: 15px;
      button.ant-btn.ant-btn-default {
        margin: 0px !important;
        border: 0px;
        padding: 0px !important;
        height: auto;
        background: none;
        color: #fff;
      }
    }
  }
  .btn__chat--cancel:hover {
    background: none !important;
  }
  .btn__chat--cancel {
    cursor: pointer;
  }
}
.attachment_check_advance_search_email {
  margin-bottom: 5px;
}
.form__group_email_search {
  button {
    display: none;
  }
}
.auto_referencing_popup {
  .ant-modal-content {
    border-radius: 12px;
  }
}
.modal_auto_referencing {
  padding: 0px 30px 0px 30px;
  .form_input_subject {
    // .ant-form-item-control{
    //   display: flex;
    //   justify-content: end;
    // }
    .ant-form-item-control-input {
      margin-left: 53px;
    }
  }
  .form_input_reference {
    .ant-form-item-control-input {
      margin-left: 7px;
    }
  }
  .reference_listing_table {
    margin-top: 20px;
    .ant-table-cell {
      button {
        background: none;
      }
    }
  }
  .button_save_auto_reference {
    margin-top: 5px;
    display: flex;
    justify-content: end;
    .ant-btn {
      border-radius: 12px;
    }
  }
  .header_auto_referencing {
    display: flex;
    justify-content: center;
    color: #495057;
    font-weight: 600;
    font-size: 15px;
  }
  .auto_reference_table {
    max-height: 250px;
    overflow: scroll;
  }
}
.clear_icon_advance_search {
  // color:red;
  // align-items: center;
  height: 20px;
  cursor: pointer;
  color: rgb(124, 123, 123);
  margin-right: 2px;
  .ant_close_icon {
    position: relative;
    bottom: 4.2px;
  }
}
.reply_modal_mail_details {
  .ant-modal-header {
    // .ant-modal-title{
    display: flex;
    justify-content: start;
    background-color: rgb(26, 136, 226);
    border-radius: 12px 12px 0px 0px;
    // }
    .ant-modal-title {
      color: white;
    }
  }
  .ant-modal-content {
    border-radius: 12px;
  }
}
.popup_modal_internal_email {
  .ant-modal-content {
    border-radius: 12px;
  }
  .internal_message_popup_email_section {
    border-radius: 12px 12px 0px 0px;
  }
}
.predefine_modal_popup {
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-header {
    border-radius: 12px 12px 0px 0px;
  }
}
.textarea_quick_message {
  .ant-input {
    border: none !important;
    &:hover {
      border: none !important;
    }
  }
  .ant-form-item-control-input-content {
    padding-left: 11px;
  }
}
.quick_message_predefined_button {
  height: 20px;
  width: 20px;
  display: flex !important;
  padding: 4px;
  border: 0px;
  color: white;
  align-items: center;
  background: #2841b3;
  margin-left: 5px;
  span {
    font-size: 11px;
  }
  padding-bottom: 0.1px;
  &:hover {
    background: #2841b3;
  }
}
.predefined_message_table {
  // .ant-table-container{
  .ant-table-body {
    max-height: 200px !important;
  }
  // }
}
//
