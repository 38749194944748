.contactDetails {
	&__allheader {
		width: 100%;
		padding-top: 4px;
		height: 31px;
		background: #ffffff;
		border: 1px solid #ebebeb;
		border-left: none;
		display: flex;
		padding: 3px 10px 3px 3px;

		.form__group {
			margin: 0px;
			border-left: 0px;
		}

		.side__search__wrapper {
			display: flex;
			justify-content: space-around;
			/* margin-left: 59px; */
			float: right;
		}
	}
}

//for fixed header in ContactView

.header {
	padding-top: 8px;
	display: flex;
	position: fixed;
	width: 43%;
	float: left;
	top: 0px;
	background: rgb(255, 255, 255);
}

//for viewPortion in Contact
.tooltip {
	display: none;
	cursor: pointer;
	@media (max-width: 1037px) {
		white-space: break-spaces;
		display: -webkit-box;
		line-height: 19px;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin-left: 7px;
	}
}

.noToolTip {
	display: block;
	@media only screen and (max-width: 1037px) {
		display: none;
	}
}

//document Module Header Visibilty

.visibility-hidden {
	visibility: hidden;
}

.w-100 {
	color: #06123e !important;

	font-size: 14px !important;
	font-weight: 600;
}

.background-grey {
	background-color: #ebebeb;
	padding:10px;
}

.bold-label--text {
	color: rgba(51, 51, 51, 1);
	font-weight: 600;
}
.display-none {
	display: none;
}
