.profile {
  padding: 13px 60px;
  overflow: hidden;

  .ant-upload-list-picture-card-container {
    width: 157px;
    height: 157px;
    border-radius: 50%;
  }
  .ant-upload.ant-upload-select-picture-card {
  }

  &__title {
    color: #303030;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 10px 0;
  }

  &__wrapper {
    display: flex;
  }

  &__wrap {
    border-radius: 8px;
    background-color: #ffffff;
    padding: 20px 20px 0 20px;
  }

  &__info {
    margin-right: 20px;
    width: 422px;
    height: auto;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 1.1px 3.3px #c2dbf8;
    border-radius: 2px;

    &--sister {
      width: 100% !important;
    }
    &--image {
      height: 104px;
    }

    &--toptext {
      h4 {
        color: #000000;
        font-size: 22px;
        font-weight: 600;
        margin: 0;
      }
      span {
        color: #424242;

        font-size: 16px;
        font-weight: 400;
      }
    }

    &--top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 181px;
      align-items: center;

      .ant-image {
        width: 104px;
        height: 104px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
      }
      .ant-image-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .ant-btn {
        font-size: 18px;
        border: none;
        padding-right: 10px;
        text-align: center;
        box-shadow: none;
        line-height: 1;
      }
    }

    &--body {
      width: 387px;
      height: 532px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .ant-input .ant-input-number-input,
    .ant-input-number {
      padding: 2px 0;
      border-width: 0 0 1px 0;
      border-radius: 0;
      border-right-width: 0px !important;
      height: 26px;
    }
    &--footer {
      text-align: right;
      padding: 5px 55px;
      height: auto;
    }
    &--headerbutton {
      text-align: right;
      height: 0px;
    }
  }

  &__bodyTitle {
    color: #616161;
    font-size: 12px;
    font-weight: normal;
    line-height: 32px;
    display: block;
    font-weight: 500;
  }

  &__bodyInfo {
    color: #3879c5;
    font-size: 14px;
    font-weight: 600;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 361px;
    height: 40px;
    left: 0px;
    top: 0px;
    background: #ffffff;
    box-shadow: inset 0px 0.5px 0px rgba(199, 199, 199, 0.25),
      inset 0px -0.5px 0px rgba(199, 199, 199, 0.25);

    &--data {
      padding-top: 10px;
    }
    &--label {
      padding-top: 10px;
    }
  }

  &__rowedit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 361px;
    height: 40px;
    left: 0px;
    top: 0px;

    &--data {
      padding-top: 3px;
      width: 249px;
    }
    &--label {
      padding-top: 10px;
      width: 44%;
    }
  }

  &__edit {
    .ant-row.ant-form-item {
      margin: 0;
      width: 100%;
    }

    &--flex {
      display: flex;
      align-items: center;
      height: 39px;
    }

    .ant-input,
    .ant-input-number-input,
    .ant-input-number {
      padding: 2px 0;
      border-width: 0 0 1px 0;
      border-radius: 0;
      border-right-width: 0px !important;
      height: 26px;

      &:focus,
      &:active {
        box-shadow: none;
        outline: none;
        border-right-width: 0px !important;
        border-radius: 0;
      }
    }

    .ant-input-number-focused {
      box-shadow: none;
      outline: none;
      border-right-width: 0px !important;
      border-radius: 0;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-picker,
    .ant-form-item-control-input-content,
    .ant-input-number {
      // height: 26px;
      width: 100%;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 26px;
    }

    .ant-picker-input > input {
      font-size: 10px;
    }

    label.ant-checkbox-wrapper {
      margin-right: 5px;
      line-height: 30px;
    }
  }

  &__details {
    width: calc(100% - 370px);
    background: #ffffff;
    box-shadow: 0px 1.1px 3.3px #c2dbf8;
    border-radius: 2px;

    .ant-tabs-nav-list .ant-tabs-tab {
      padding: 0 9px 12px 9px;
      margin-right: 18px;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
      font-size: 15px;
    }

    &--body {
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
      height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &--footer {
      text-align: center;
      padding: 5px 0;
    }
  }
}

.documents_edit {
  .ant-row.ant-form-item {
    margin-bottom: 25px !important;
  }
}

//officilainformation
.profiledetails__rowedit--data {
  width: 50%;
  font-weight: normal;
}

.profiledetails {
  .ant-popover-inner-content {
    padding: 0px !important;
  }
  &__collapse {
    border-bottom: 1px solid #f2f2f2;
    // margin-bottom: 7px;
    .ant-collapse-content > .ant-collapse-content-box {
      overflow-y: auto;
      padding: 0px !important;
      height: auto;
      /* min-height: auto; */
      max-height: 62vh;
      overflow-x: hidden;
    }
  }
  &__divider {
    margin-top: 8px;
    border-top: 1px solid #e2ecf8;
  }

  &__content {
    // margin-bottom: 12px;
    &--divider {
    }
  }
  &__render {
    // margin-bottom: 15px;
    // margin-top: 8px;
    // min-height: auto;
    // max-height: 71vh;
    // overflow: scroll;

    .ant-collapse-content > .ant-collapse-content-box {
      overflow-y: auto;
      padding: 0px !important;
      min-height: auto;
      max-height: 62vh;
      overflow: scroll;
      overflow-x: hidden;
    }
  }

  &__main {
    margin-bottom: 15px;
    // margin-top: 8px;
    height: auto;
    // overflow-y: auto;
    // overflow-x: hidden;
    // border-bottom: 1px solid $gray;

    .ant-collapse-content > .ant-collapse-content-box {
      overflow-y: auto;
      padding: 0px !important;
      height: auto;
    }

    .ant-collapse-content {
      overflow: hidden;
      color: rgba(0, 0, 0, 0.85);
      width: 100%;
      background-color: #fff;
      border-top: 1px solid #d9d9d9;
    }
  }
  &__wrapper {
    // padding-top: 2px;
    // height: 70vh;
    // min-height: auto;
    // max-height: 77vh;
    // overflow: scroll;

    &--conatainer {
      width: 100%;
      height: 461px;
      /* left: 24px; */
      /* top: 80px; */
      padding: 20px 16px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 2px;
      overflow: hidden;
      overflow-y: auto;
    }
    &--reference {
      width: 100%;
      height: auto !important;
      /* height: 391px; */
      /* left: 24px; */
      /* top: 80px; */
      padding: 10px 0px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 2px;
      height: 71vh;
    }
  }
  &__row {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    background: #ffffff;
    box-shadow: inset 0px 0px 0px rgba(199, 199, 199, 0.25),
      inset 0px -0.5px 0px rgba(199, 199, 199, 0.25);

    &--label {
      width: 30%;
      padding-top: 10px;
      color: #1f1f1f;
    }
    &--data {
      width: 70%;
      padding-top: 10px;
      color: #8a8a8a;
    }
  }
  &__rowedit {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    width: 100%;
    left: 0px;
    // height: 32px;
    top: 0px;
    background: transparent;

    &--data {
      // width: calc(100% - 153px);
      // width: 50%;
      font-weight: normal;
      // padding-top: 10px;

      .ant-btn {
        font-size: 11px;
        height: 25px;
      }
      .ant-upload.ant-upload-select {
        display: flex;
        margin-left: 10px;
      }
    }
    &--label {
      width: 153px;
      // padding-top: 10px;
    }
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
}
.ant-form-vertical .ant-form-item {
  margin: 0px;
}

.profile .header {
  background: none;
  width: 100%;
}

.profile-wrapper {
  padding: 0px !important;
  position: absolute;
  top: 0px;
  width: calc(100% - 72px);
  left: 72px;
  .drawer {
    .contactsDetails__info {
      margin: 0px !important;
      padding: 0px !important;
    }
  }
  .profile_header {
    background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
    box-shadow: inset 0px -0.7px 0px rgba(0, 0, 0, 0.15);
    .header__main--text {
      font-size: 18px;
      font-weight: bold;
      padding: 12px 15px;
    }
  }
  .profile__wrapper {
    padding: 0px 15px;
  }
}
.mail-profile-header {
  button {
    display: flex;
    margin-left: auto;
    border: 0px;
    padding: 0px;
    height: auto;
    margin-bottom: 5px;
    span.anticon.anticon-key svg {
      font-size: 19px;
      color: #636363;
    }
  }
  button.ant-btn.ant-btn-link {
    margin-right: -2px;
    margin-bottom: 1px;
  }
  span.anticon.anticon-user-switch {
    font-size: 19px;
    color: #636363;
  }
}

.sister {
  width: 100% !important;
  &--label {
    width: 20% !important;
  }
  &--data {
    width: 80% !important;
  }
}

.profile__wrap--sister {
  padding: 30px 0px;
  .profile__info--wrapper {
    padding: 0px 16px;
    .profile__info--body {
      overflow: hidden;
      height: auto;
      .contactsDetails__info.mb-3 {
        margin-bottom: 30px;
      }
      .contactsDetails__info.mb-3 .contactsDetails--title.bold.w-100 {
        font-size: 21px !important;
        font-weight: 600;
        padding: 0px;
      }
      .profile__row {
        display: flex;
        align-items: center;
        justify-content: unset;

        .sister--label,
        .profile__row--label {
          width: 140px !important;
          padding: 0px;
          display: flex;
          align-items: center;
          span.profile__bodyTitle {
            width: 140px;
          }
        }
        .profile__row--data {
          padding-top: 0px !important;
        }
      }
    }
  }
}
.stage_form_to_Straight {
  margin-left: -10px;
}
.contactsDetails__info.mb-3.sisco-header-wapper {
  display: flex;
  align-items: center;
}
.sisco-action-button {
  margin-left: auto;
}
.clearance__form {
  margin-top: 1em;
}
.clearance__form .ant-form-item-label {
  text-align: left !important;
}
.clearance__form--sec {
  margin-bottom: 2em;
}
.clearance__form--header,
.clearance__form--footer {
  background: #f3f3f3;
}
.clearance__form--footer {
  display: flex;
  justify-content: flex-end;
}
.clearance__form--header-title {
  text-align: center;
  text-transform: upperCase;
  font-weight: 600;
  padding: 4px 0;
}
.clearance__form--btn {
  margin-left: 8px;
}
.clearance__form-tbl .ant-table-header table thead tr th {
  font-weight: bold;
}
.clearance_footer {
  background: #f2f2f2;
}

.clearance_footer > .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}
.clearance_add-btn {
  background: #67a1e4 !important;
  color: #fff !important;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  border: 0;
}
.clearance_footer {
  padding: 4px 0;
}
.clearance__form .ant-form-item:not(.clearance_footer .ant-form-item) {
  margin-bottom: 6px !important;
}
.clearance__form .ant-picker {
  padding: 0px 8px 0px 1px;
}

.exit-interview__row {
  padding: 0.2em 0;
  text-align: center;
  background-color: #d8ddeb;
}
.exit-interview__col {
  text-align: center;
}

.exit-interview__custom-rate {
  font-size: 24;
  color: "#FFD700";
}

.exitinterview-supervisor table thead tr th {
  font-weight: bold;
}

.employeeEvaluationRatingForm .ant-collapse-header {
  font-weight: bold;
  margin: 1em 0;
  background: #d8ddeb;
}

.profile-ellipsis-more {
  margin-left: 8px;
  cursor: pointer;
  background: #dbdbe9;
  border-radius: 24px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin: 0px !important;
  }
}

.profile-emailshare {
  box-shadow: 0px 0px 2px 0px #8e8989;
  margin-bottom: 20px;
}
