.document-panel-wrapper{
    .document-panel-collaspe{
        .ant-collapse-header {
            background: #d8ddeb;
            height:30px;
            display:flex;
            font-size: 14px;
            font-weight:600;
            color:#000;
            align-items:center;
            .ant-collapse-arrow{
                top:10px;
            }
        }
        .ant-collapse-content-box{
            padding:0px;
        }
        .document-panel-item{
            height:30px;
            padding:0px 20px;
            cursor: pointer;
            display:flex;
            align-items: center;
            &:nth-of-type(even){
               background:#f2f2f2;
            }
            &:hover{
             background:#ddd;
            }
            &.active{
                background: #67a1e4 !important;
                .document-type-name{
                    color:#fff;
                    .ref-name{
                        color:#fff;
                    }
                }
            
                &:hover{
                    background:#67a1e4 !important;
                }
            }
            .document-type-name{
               text-transform: capitalize;
               font-size: 12px;
            }
        }
    }
}

.folder-structure-file-upload-wrapper{
    .parent-folder-wrapper{
        .parent-folder-info-wrapper{
            cursor: pointer;
            .parent-folder-info{
                display:flex;
                column-gap: 5px; 
                align-items: center;

            }
        }
    }
    .sub-folder-file-upload-wrapper{
        margin-left: 20px;
        
            .sub-folder-info-wrapper{
                cursor: pointer;
               .sub-folder-info{
                   display: flex;
                   column-gap:5px;
                   align-items: center;
               }
            }
        
        &.hide{
            height: 0px;
            overflow-y: hidden;
        }
    }
    .close-open-folder{
        font-size:14px;
        color:#000 !important;
        span{
            color:#000 !important;
        }
    }
    .folder-icon{
        font-size:14px;
        color:#f8D775;
    }
    .folder-name{
        font-size:12px;
        color:#000;
        width:100%;
        font-weight: 600;
        line-height:14px;
        &.hover{
            color:#3472bf;
        }
    }
    .folder-radio-wrapper{
        width:100%;
        display: flex;
        label{
            font-size:12px;
            color:#000;
            font-weight: 600;
            width:100%;
        }
        .file-counter{
            font-size: 11px;
            font-weight: 600;
            color:#3472bf;
        }
        
    }
}
.file-drag {
    overflow: hidden;
}
.fileUpload-wrapper{
    margin-bottom:3px;
    .fileUpload-btn-wrapper {
        display: inline-block;
        background: #d8ddeb;
        padding: 1px 4px;
        border-radius: 2px;
        label{
            font-size: 12px;
            color:#333;
        }
    }
}
.file-list-wrapper-old{
    .folder-file-item {
        font-size: 12px;
        color: #3472bf;
    }
    ul{
        padding:0px;
        margin:0px;
    }
    a{
        .text-center-overflow-wrapper{
            span{
                font-size: 12px !important;
                color: #3472bf !important;
            }
           
        }
        
   
    }
}
.file-list-wrapper{
    a{
        .text-center-overflow-wrapper{
            span{
                font-size: 12px !important;
                color: #3472bf !important;
            }
        }
        
   
    }
    .folder-file-item {
        font-size: 12px;
        color: #3472bf;
    }
}
.document-form-wrapper{
    span.ant-select-selection-search input {
        border: 0px !important;
    }
    .ant-form-item-has-error input{
        border:0px !important;
    }
}


.drawer-form-row{
    .ant-select-multiple{
        span.ant-select-selection-item {
            margin-left: 7px;
        }
    }
   
}

.share-document-form{
    .tox-tinymce{
        border:1px solid #ccc !important;
    }
}
.document-share-wrapper{
    text-align: center;
}
.document-share-wrapper p {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: 600;
}
.share-status-wrapper{
    display:flex;
    column-gap: 10px;
    justify-content: center;
}
.share-as-email {
    background: green;
    color: #fff;
    font-size: 15px;
    padding: 2px 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
}
.share-as-email:hover{
    color:#fff;
}
.document-code-info-wrapper {
    display: flex;
    column-gap: 5px;
    align-items: center;
    font-size: 10px;
    color: #777;
}
p.endData {
    margin: 0px;
    padding: 4px 0px;
}