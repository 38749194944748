.contactDetails {
  padding: 5px 0;
  margin-top: 105px;

  &__data {
    display: flex;
    width: 78%;
    flex-direction: row;
    justify-content: space-between;
    &--left {
      width: 40%;
    }
    &--right {
      width: 54%;
    }
  }
  &__infoheader {
    padding-left: 19px;
  }
  &__components {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
  }
  &__chat {
    width: 24%;
  }
  &__info,
  &__subinfo {
    display: flex;
    background-color: #ffffff;
    margin-bottom: 8px;
    font-size: 14px;
    border-bottom: 1px solid #f2f2f2;
  }
  &__button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 54px;
    cursor: pointer;
  }
}
.note_add_button {
  display: flex;
  justify-content: end !important;
}
.contactDetails__header {
  width: 100%;
  padding-top: 4px;
  height: 29px;
  display: flex;
  padding: 3px 10px 3px 3px;
  // margin-bottom: 8px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  // border-top: none;
  // box-sizing: border-box;
  // border-radius: 4px;
  // .side__search__wrapper {
  // 	display: flex;
  // 	justify-content: space-around;
  // 	/* margin-left: 59px; */
  // 	float: right;
  // 	margin-right: 7px;
  // }
}
.contact__Detailsfav {
  line-height: 19px;
  width: 19px;
  padding-left: 1px;

  font-size: 11px;
}

.ContactDetails__header {
  height: 34px !important;
  width: 100% !important;
  padding: 24px 12px;
  padding-left: 9px;
  display: flex;
  align-items: center;

  // box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15);
  margin: 0px !important;
  // border-bottom: 0.5px solid #ebebeb;
}
.contactdetails__showall {
  padding: 1px;
  font-size: 10px;
  // width: 56px;
  // height: 20px;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  color: #afafaf;
  // margin: 0px 10px 0px 4px;
  text-align: center;
}

// .contactDetails__dropdown {
// 	padding: 4px 12px 4px 0px;
// }
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 0px;
  text-align: left !important;
  padding-left: 23px;
  height: 32px !important;
  padding: 11px 7px;
}

.contacts {
  .ant-table-content {
    .ant-table-row {
      cursor: pointer;
    }
  }

  .form__groups {
    border-style: none;
    border-style: none;
    // margin-left: -11px;
    //border-left: 1px solid #c4c4c4;
    height: 21px;
    padding-left: 2px;
  }
  .form__mail {
    border-style: none;
    height: 17px;
    padding-left: 2px;
    width: 100%;
    min-width: 50%;
    border: none;
    border-radius: 5px;
  }
  .anticon.anticon-plus > svg {
    height: 11px;
    margin-top: 2px;
  }
  // .ant-table-container table > thead > tr:first-child th:last-child {
  // 	border-top-right-radius: 5px;
  // }
  // .ant-table-container table > thead > tr:first-child th:first-child {
  // 	border-top-left-radius: 5px;
  // }
  .ant-table-body {
    height: 40vh;
    cursor: pointer;
    overflow: auto;
  }

  .ant-divider-horizontal {
    margin: 0 0 8px 0;
    display: none;
  }

  .ant-input-wrapper {
    position: relative;
    .ant-input-group-addon {
      position: absolute;
      right: 32px;
      top: 1px;
      z-index: 1;
      display: none;
    }
    .ant-input-search {
      border: none;
    }

    .ant-input {
      padding: 0 0px 0 2px !important;
      background-color: white;
      border: none;
      // margin-bottom: 6px;
      // width: 233px;
    }

    .ant-btn {
      height: 36px;
      border: 0;
      background-color: #e9e9e9;
      border-radius: 0 5px 5px 0 !important;
    }
  }

  .ant-input-search > .ant-input-group > .ant-input-affix-wrapper {
    border: none;
    // width: 103%;
    padding: 0px;
    padding-left: 4px;
    padding-right: 8px;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: none;
    border-right-width: none;
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
  }

  .ant-input-search > .ant-input-group > .ant-input-group-addon {
    left: auto !important;
  }
  .contactDetails__label {
    width: 148px;
    margin-right: 5px;
    // padding-left: 12px;

    &--infoNotes {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 9px;
      align-items: center;
    }
  }
  .contactsDetails__infonotes {
    // height: 56px;
    background: #f5f5f5;
  }
  .contactDetails__labeldata {
    width: calc(100% - 148px);
  }

  .contactDetails__infoheader {
    display: flex;
    background-color: #ffffff;
    /* margin-bottom: 8px; */
    font-size: 14px;
    border-bottom: 1px solid #f2f2f2;
  }
  &Details {
    // padding-left: 25px;
    // padding-top: 8px;
    .ant-image {
      height: 96px;
      width: 96px;
      border-radius: 130px;
      overflow: hidden;
      border: 1px solid $gray;

      &.ant-image-error {
        display: none;
      }

      .ant-image-img {
        width: 100%;
        object-fit: cover;
      }
    }

    &__main {
      margin-bottom: 11px;
      padding-bottom: 11px;
      height: auto;
      // min-height: 40vh;
      // max-height: 92vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 2px;
      .ant-collapse-content > .ant-collapse-content-box {
        // overflow-y: auto;
        padding: 0px !important;
        height: auto;
      }
      .ant-collapse-content {
        overflow: unset;
      }

      .ant-collapse-icon-position-right
        > .ant-collapse-item
        > .ant-collapse-header {
        height: 22px;
        padding-left: 18px;
        background: #fafafa;
        border: none;
        color: #000000;
        font-size: 14px;
        line-height: 1px;
        font-weight: 600;
        padding-top: 11px;
        font-size: 11px;
      }
      .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-arrow
        svg {
        margin-top: -22px;
        height: 32px;
      }
      .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header
        .ant-collapse-extra {
        float: right;
        margin-top: -14px;
        height: 22px;
      }
    }

    &__info,
    &__subinfo {
      display: flex;
      background-color: transparent;
      // margin-bottom: 8px;
      font-size: 12px;
      height: 22px;
      // border-bottom: 1px solid #f2f2f2;
    }

    .mb-3 {
      margin-bottom: 3px;
      background: #f3f3f3;
      padding: 5px 2px 2px 0px;
      height: 27px;
    }

    &__infoMain {
      padding: 5px 0;
      height: auto;

      &:last-child {
        border-bottom: 0;
      }
    }

    .contactDetails__header {
      width: 425px;
    }
    &--titleMain {
      margin-bottom: 6px;
    }

    &--title {
      //text-align: right;
      margin-right: 10px;
    }
    &--headersubtitle {
      text-align: left;
      color: #afafaf;
    }
    &--subtitle {
      text-align: left;
      color: #000000;
      font-weight: normal;

      // color: $primary;
    }

    .sub {
      // color: #606060;
      font-weight: 600;
    }

    .bold {
      font-weight: normal;
    }

    .w-100 {
      // min-width: 49px;
      color: #616161;
      line-height: 16px;
      bottom: 95.03%;
      font-size: 12px;
    }
  }

  &__label {
    color: #303030;
    font-size: 14px;
    font-weight: 600;
    line-height: 2.5;
  }

  &__drawers {
    display: flex;
    align-items: baseline;

    .form__group {
      width: 100%;
      margin-right: 10px;
    }

    .ant-btn {
      width: 10px;
    }
  }

  &__personDrawer {
    .ant-image {
      border-radius: 50%;
      overflow: hidden;
    }

    .label__center label {
      padding-left: 30px;
    }

    // .ant-row.ant-form-item:nth-child(2) {
    // 	display: none;
    // }
  }

  &__companyDrawer .ant-upload-list-picture-card .ant-upload-list-item,
  .ant-upload.ant-upload-select-picture-card {
    padding: 0;
    width: 96px;
    height: 96px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__personDrawer {
    .ant-upload-list-picture-card .ant-upload-list-item,
    .ant-upload.ant-upload-select-picture-card {
      padding: 0;
      border-radius: 50%;
      width: 104px;
      height: 104px;
      overflow: hidden;
    }
  }
}

.contact__buttons {
  font-size: 14px;
  border: none;
  font-weight: 400;
  width: 13vh;
  background-color: transparent;
  border: none;

  &--userDrawer {
    text-align: left;
    height: auto;
    font-size: 14px;
    border: none;
    font-weight: 400;
    width: 13vh;
    background-color: transparent;
    border: none;
    padding: 0px;
  }
}
.contactDetails__document {
  margin-top: -3px;
}
.contactsDetails__info__lable {
  margin-bottom: 3px;
  background: #f3f3f3;
  line-height: 10px;
  display: flex;
  align-items: center;
  height: 26px;
  width: 100%;
  padding: 0px 10px;
}
.contact__btn-danger {
  border-radius: 6px;
  font-size: 14px;

  color: #d30d21;
  border: none;
  height: 26px;

  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);

  &:hover {
    background-color: #f3f3f3;
    color: red;
    border: none;
  }
}

.contact__header__btn-primary {
  height: 23px;
  width: 20px;
  display: flex;
  padding: 3px;
  justify-content: center;
  color: white;
  align-items: center;
  background: #c53d38;
  &:hover {
    background: #c53d38;
    color: white;
    border-color: white;
  }
  // border: 0.5px solid #ab3531;
  // box-sizing: border-box;
  // border-radius: 2px;
}

.contact__details__image .ant-image {
  height: 96px;
  background: #f2f2f2;
  width: 96px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact__details__image {
  width: 148px;
}
.person__image {
  width: 148px;

  float: left;
}
.merge {
  display: flex;
  width: 20px;
  &--contact {
    padding-right: 7px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: break-spaces;
    display: -webkit-box;
    line-height: 19px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

//media queries contact

.right__notes {
  @media (max-width: 780px) {
    display: none;
  }
  &.active {
    display: block;
  }

  &--show {
    display: none;
    @media (max-width: 780px) {
      display: flex;
    }
  }
}

.contact__details__render {
  position: relative;
  margin-bottom: 9px;
  width: 100%;
  @media (max-width: 780px) {
    width: 90% !important;
  }
}
// .test3 {
// 	@media (max-width: 780px) {
// 		width: 100% !important;
// 	}
// }
.contact__editnotes {
  height: 100%;
  width: 30%;
  border-left: 1px solid #f2f2f2;
  @media (max-width: 780px) {
    width: 10%;
  }
}

.contactDetails__split {
  display: flex;
  width: 100%;
  height: 100vh;

  &--pagination {
    width: 299px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    @media (max-width: 1100px) {
      width: 255px !important;
      transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
  }

  &--pagination-collapse {
    width: 50px;
    transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    @media (max-width: 1100px) {
      width: 50px !important;
      transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
  }

  &--right {
    width: calc(100% - 299px);
    transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    @media (max-width: 1100px) {
      width: calc(100% - 255px);
      transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
  }

  &--right-collapse {
    width: calc(100% - 0px);
    transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    @media (max-width: 1100px) {
      width: calc(100% - 0px);
      transition: width 0.5s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
  }
}
//for document Module
.document-right {
  width: 100% !important;
  padding: 0px;

  &__view-edit {
    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;

      .col-1-of-2 {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

//for responsivess of ContactView header (CompanyName)
.contactView {
  width: 90%;
  float: left;
  &__header {
    display: flex;
    .contactsDetails__infoheader {
      @media (max-width: 920px) {
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.right-header {
  width: calc(100% - 148px);
}

span.contactsDetails--title.bold.w-100 {
  font-size: 12px !important;
  font-weight: 500;
  line-height: 17px;
  padding-left: 10px;
}

.contacts .contactsDetails {
  padding: 0px 7px;
}

.edittender__header div {
  display: flex;
  align-items: center;
}

.organizer.contacts .reference__info {
  height: 23px;
  display: flex;
  line-height: 19px;
}
.rotoWrapper span.referenceDetails--title {
  color: #06123e;
}

.contact__content__wrapper.contacts__personDrawer {
  .ant-form-item-control-input-content input {
    height: 30px;
  }
  .ant-col.ant-col-sm-19.ant-col-md-19.ant-col-lg-19 {
    padding-right: 0px !important;
  }
  .ant-col.ant-col-24 {
    padding-right: 0px !important;
  }
  .ant-col.ant-col-8 {
    padding-right: 0px !important;
  }
  .ant-col.ant-col-12 {
    padding-right: 0px !important;
  }
  .ant-input {
    padding: 0px;
    text-indent: 10px;
  }
  input#control-hooks_companyContactId {
    text-indent: 10px;
  }
  span.ant-select-selection-placeholder {
    padding-left: 10px !important;
    line-height: 29px !important;
    margin-top: 2px;
  }
  .contactsDetails__info__lable {
    span.ant-checkbox-inner {
      margin-top: 3px;
    }
  }
}
.person-drawer {
  height: 100%;
  .drawer__content {
    padding: 0px;
  }
  .drawer__content--wrapper {
    height: 100%;
    overflow: auto;
    .contact__content__wrapper.contacts__companyDrawer {
      height: 100%;
    }
  }
  .control-hooks {
    .contactsDetails .mb-3 {
      padding: 0px !important;
    }
  }
  .contactsDetails__info {
    display: flex;
    border: none;
    margin-bottom: 14px;
    font-size: 14px;
    align-items: center;
  }
  .drawer__footer {
    position: fixed;
    .drawer__footer--update {
      width: auto;
      column-gap: 10px;
      align-items: center;
    }
  }
  .person_user_form {
  }
  .ant-picker {
    padding: 0px !important;
    text-indent: 10px;
  }
  .ant-picker-input {
    height: 30px;
    input {
      text-indent: 10px;
    }
    span.anticon.anticon-calendar {
      padding-right: 11px !important;
    }
  }
  .contactDetails__labeldata {
    padding: 2px 11px;
    span {
      padding: 0px;
    }
  }
}
.person-add-details-wrapper {
  .contactsDetails__info.mb-3 {
    margin-bottom: 0px;
  }
  span.contactsDetails--title.bold.w-100 {
    padding: 0px;
  }
}
.person_user_form {
  margin-bottom: 15px;
  span.contactsDetails--title.bold.w-100 {
    padding: 0px;
    font-size: 14px !important;
    font-weight: 800;
  }
}

.company-details-form-col {
  margin-bottom: 15px;
  padding: 0px !important;
  .ant-col.ant-col-24 {
    padding: 0px !important;
  }
}
.ant-col.compant-upload-wrapper-col.ant-col-xs-24.ant-col-sm-5.ant-col-md-5.ant-col-lg-5.ant-col-xl-5.ant-col-xxl-5 {
  padding: 0px !important;
}
.company-label-wrapper {
  button.ant-btn.ant-btn-dashed.ant-btn-block.contact__buttons {
    display: flex;
    align-items: center;
    box-shadow: none;
  }
  .ant-col.ant-col-24 {
    padding: 0px !important;
  }
  span.contactsDetails--title.bold.w-100 {
    padding: 0px;
    font-weight: 800;
    font-size: 14px !important;
  }
}
.company-background-wraper-col {
  padding: 0px !important;
  span.contactsDetails__info__lable {
    font-weight: 800;
    font-size: 14px !important;
  }
  .contactDetails__label {
    padding-left: 0px !important;
    padding-right: 10px;
  }
  .ant-upload.ant-upload-select {
    display: flex;
    margin-left: 3px !important;
  }
  .contactsDetails__info,
  .contactsDetails__subinfo {
    height: 26px;
  }
}
.company-document-wrapper-col {
  .contactDetails__label {
    padding: 0px !important;
    margin: 0px !important;
  }
  span.contactsDetails--title.bold.w-100 {
    padding: 0px !important;
  }
  .ant-upload.ant-upload-select.ant-upload-select-text {
    margin-left: 6px !important;
  }
}
.notes-wrapper-col {
  .contactDetails__label {
    padding: 0px !important;
  }
  span.contactsDetails--title.bold.w-100 {
    padding: 0px;
  }
}
.person-info-details-wrapper-col {
  margin-bottom: 15px;
  .ant-form-item-control-input {
    min-height: 30px;
  }
}
.person-add-details-wrapper {
  .contact__buttons {
    display: flex;
    align-items: center;
  }
  .ant-col.ant-col-24 {
    padding: 0px !important;
  }
  span.contactsDetails--title.bold.w-100 {
    font-size: 14px !important;
    font-weight: 800;
  }
}
.person-basic-wrapper-col {
  span.contactsDetails__info__lable {
    font-size: 14px !important;
    font-weight: 800;
  }
  .contactDetails__label {
    padding-left: 10px !important;
  }
  span.contactsDetails--title.bold.w-100 {
    padding: 0;
  }
  .contactDetails__labeldata {
    padding-right: 10px !important;
  }
  .contactsDetails__info {
    margin-bottom: 18px !important;
  }
}
.person-whole-wrapper-col {
  .ant-row {
    width: 100%;
  }
}
.person-document-wrapper-col {
  .contactDetails__label {
    padding: 0px !important;
  }
}
@media only screen and (max-width: 986px) {
  //  .notes-wrapper-col {
  // 	flex: 0 0 100% !important;
  // 	max-width: 100% !important;

  //  }

  //  .person-whole-wrapper-col{
  // 	flex: 0 0 100% !important;
  // 	max-width: 100% !important;
  // 	.ant-row{
  // 		width: 100%;
  // 	}
  //  }
  .notes-wrapper-col {
    position: static;
    padding-bottom: 40px !important;
    textarea {
      height: 120px !important;
    }
  }
}

.companies--bottom-wrapper {
  .company_header {
    padding: 7px 0px;
    display: flex;
    justify-content: space-between;
    div {
      width: auto;
    }
    .referenceDetails__label:nth-child {
      margin-left: auto;
    }
    button.referenceDetails--title.header_print_btn {
      color: #67a1e4;
    }
  }
}
.companies--bottom-wrapper {
  display: flex !important;
  flex-flow: row wrap;
  justify-content: space-between;
  .remarks_history {
    width: 49%;
  }
  .company_person {
    width: 49%;
  }
}
.company-table-wrapper {
  margin-top: 15px;
  .company-table {
    height: 45vh;
    overflow-y: auto;
  }
  .latest-remark-wrapper {
    display: flex;
    .ant-space.ant-space-horizontal.ant-space-align-center {
      margin-left: auto;
    }
  }
  table {
    thead {
      position: sticky;
      top: 0px;
      z-index: 99;
    }
    tbody {
      td {
        &:first-child {
          width: 8% !important;
        }
        &:nth-child(2) {
          width: 30% !important;
        }
        &:nth-child(3) {
          width: 8% !important;
        }
        &:nth-child(4) {
          width: 8% !important;
        }
        &:nth-child(5) {
          width: 8% !important;
        }
        &:nth-child(6) {
          width: 8% !important;
        }
      }
    }
  }
}

.company-bottom-table {
  height: 25vh;
  overflow-y: auto;

  table {
    thead {
      position: sticky;
      top: 0;
      z-index: 99;
    }
  }
}
.referenceDetails__label.person-info-wrap {
  padding: 15px 0px;
}
.person_info {
  .company-details-table {
    height: 20vh;
    overflow-y: auto;

    table {
      thead {
        position: sticky;
        top: 0;
        z-index: 99;
      }
    }
  }
}

@media only screen and (max-width: 1350px) {
  .wrapper_company {
    height: 100vh;
    overflow: auto;
    .companies--bottom-wrapper {
      display: inherit;
    }
  }
}

.contact__editnotes {
  .editnotes-wrapper {
    height: 100%;
    .contactDetails__label--infoNotes {
      height: 33px;
    }
    .right__notes {
      height: 100%;
      width: 100%;
      .editnote-form-wrapper {
        height: 100%;
        .ant-row.ant-form-item {
          height: 95%;
          div {
            height: 100%;
            textarea#control-hooks_notes {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
.ant-form ant-form-vertical {
  height: 100%;
}
.ant-row ant-form-item {
  height: 100%;
}
.ant-form-item-control-input-content {
  height: 100%;
}
.ant-form-item-control-input {
  height: 100%;
}
.ant-col.ant-form-item-control {
  height: 100%;
}
.ant-row.ant-form-item {
  height: 100%;
}
form#control-hooks {
  height: 100%;
}
.company-person-edit-btn {
  font-size: 16px;
  font-weight: 600;
  display: inline;
  cursor: pointer;
  background: #67a1e4;
  color: #fff;
  padding: 7px 15px;
  border-radius: 4px;
}

.global-form-flex {
  display: flex;
  width: 100%;
}
.editnotes-wrapper {
  span.contactsDetails--title.bold.w-100 {
    padding: 0;
  }
  span.contactsDetails--subtitle.bold {
    padding: 2px 8px;
  }
}
.ant-select-item-option-content {
  // text-transform: capitalize;
}
.ant-select.ant-select-multiple.ant-select-show-search
  span.ant-select-selection-placeholder {
  margin-left: 0px !important;
}
.compant-person-render {
  width: calc(100% - 350px);
  .contact-person-company-wrapper {
    margin-top: 10px;
    height: calc(100% - 50px);
    form {
      height: 100%;
      overflow-y: auto;
    }
    .row-wrapper {
      display: flex;
      flex-flow: row wrap;
      height: 100%;
      width: 100%;
      button.ant-btn.contact__buttons {
        padding-left: 0;
        text-align: left;
      }
      .contact-person-company-form-wrapper {
        width: 100%;
        padding: 0px 7px;
        .global-form-flex {
          column-gap: 15px;
        }

        .personal-info-form-wrapper {
          width: 100%;
          .ant-select-selection-placeholder {
            margin-left: 7px;
          }
        }

        .ant-row.ant-form-item.form__group {
          padding: 0px;
        }
        .company-label-wrapper {
          margin-top: 20px;
          .contactsDetails__info__lable {
            margin-bottom: 0px;
          }
        }
        .extra-form-wrapper {
          .background-form-wrapper {
            padding: 10px 10px;
            .global-form-flex {
              flex-flow: row wrap;
            }
            .ant-upload.ant-upload-select.ant-upload-select-text {
              margin-left: 0px;
            }
          }
        }
      }
      .contact-person-company-editor-wrapper {
        .global-form-group {
          height: 100%;
        }
        .ant-col.ant-form-item-control {
          height: 100%;
          .ant-form-item-control-input {
            height: 100%;
            .ant-form-item-control-input-content {
              height: 100%;
              .ant-input-textarea.ant-input-textarea-show-count {
                height: 100%;
              }
            }
          }
        }
        position: fixed;
        top: 0;
        right: 0;
        width: 350px;
        height: calc(100% - 50px);
        .editor-header-wrapper {
          background: #f2f2f2;
          height: 33px;
          display: flex;
          align-items: center;
          padding: 0px 7px;
          h3 {
            margin: 0px;
            font-size: 12px;
            font-weight: 800;
          }
        }
        textarea {
          border: 0px;
          outline: none;
          padding: 7px !important;
          text-indent: -1px;
          height: calc(100% - 33px);
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-top: 0px;
        }
        .ant-row.ant-form-item.form__group {
          padding: 0;
        }
      }
      .global-form-footer {
        background: #f2f2f2;
        padding: 7px 7px;
        position: fixed;
        right: 0px;
        bottom: 0px;
        width: 100%;
        display: flex;
        column-gap: 10px;
        width: calc(100% - 371px);
        .cancel-btn {
          margin-left: auto;
        }
      }
      .global-form-group {
        &.star {
          margin-top: 2px;
        }

        input {
          height: 30px;
        }
        .ant-picker {
          padding: 0px;
          padding-left: 8px;
          padding-right: 8px;
          input {
            text-indent: 0px;
          }
        }
        button.ant-btn.ant-btn-dashed.ant-btn-block.contact__buttons {
          padding: 0;
        }
        button.ant-btn.ant-btn-dashed.contact__buttons {
          padding-left: 0px !important;
          text-align: left;
        }
        .remove-field-wrapper
          span.anticon.anticon-minus-circle.contact__btn-danger {
          width: 23px !important;
          background: none;
        }
      }
      button.ant-btn.ant-btn-dashed.ant-btn-block.contact__buttons {
        padding: 0px;
      }
      .company-person-label-field-wrapper {
        .global-form-flex {
          flex-flow: row wrap;
          padding: 0px 10px;
          .global-form-group {
            &.mt:first-child {
              margin-top: 10px;
            }
            .ant-row.ant-form-item {
              width: 100%;
            }
            .global-form-input {
              width: calc(100% - 138px - 23px);
            }
          }
        }
      }
    }
  }
}

.mar-select-input span.ant-select-selection-placeholder {
  margin-left: 7px !important;
}
.company-person-label-header-wrapper {
  background: #f5f5f5;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  .company-person-label-header {
    color: #06123e;
    font-size: 14px;
    font-weight: 800;
  }
  .expert-checkbox-wrapper {
    margin-left: auto;
  }
}
.global-group {
  display: flex;
  column-gap: 10px;
  justify-content: space-between;
  width: 100%;

  &.w-32 {
    .global-form-group {
      width: 33%;
      input {
        width: 100%;
      }
    }
  }
  &.w-49 {
    .global-form-group {
      width: 49.67%;
      input {
        width: 100%;
      }
    }
  }
}
.global-form-group {
  &.calc {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .global-form-label {
      width: 138px;
      display: flex;
      align-items: center;
      .ant-row.ant-form-item {
        width: 100%;
        display: block;
        .ant-select-selection-item {
          text-transform: capitalize;
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
    .global-form-input {
      width: calc(100% - 138px);
      .ant-input-number.ant-input-number-sm {
        width: 100%;
      }
    }
  }
  &.advance-btn-search-wrapper {
    justify-content: flex-start;
  }
}
.expert-panel-wrapper {
  &.mt-10 {
    margin-top: 12px;
  }
  button.ant-btn.ant-btn-dashed.ant-btn-block.reference__btns--add {
    text-align: left;
    padding: 0px !important;
    display: flex;
    align-items: center;
    background: none;
  }
  .expert-plus-wrapper {
    .ant-form-item-control-input {
      min-height: 15px;
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 10px;
  }
  .global-form-flex {
    flex-flow: row wrap;
  }
  .expert-group {
    flex-flow: row wrap;
    width: calc(100% - 37px) !important;
    .global-form-input {
      width: calc(100% - 112px - 27px) !important;
    }
  }
  .ant-collapse-header {
    height: 30px;
    display: flex;
    align-items: center;
    background: #d8ddeb;
    font-weight: 800;
    padding: 0 10px !important;
    margin: 0 !important;
    .ant-collapse-arrow {
      top: 11px !important;
      svg {
        transform: rotate(0deg) !important;
      }
    }
  }
  .ant-collapse-item.ant-collapse-item-active {
    .ant-collapse-arrow {
      svg {
        transform: rotate(90deg) !important;
      }
    }
  }
}
.ant-select-multiple
  .ant-select-selection-search:first-child
  > .ant-select-selection-search-input {
  margin-left: 0px !important;
}
.side__search__wrapper.side-contact-search-filter {
  background: #67a1e4;
  display: flex;
  justify-content: center;
  height: 20px;
  border-radius: 4px;
  margin-right: 5px;
  width: 30px;
  span.anticon.anticon-filter {
    color: #fff;
  }
}
// .contact__editnotes .editnotes-wrapper .right__notes .editnote-form-wrapper .ant-row.ant-form-item div textarea#control-hooks_notes {

//     outline: none;
//     padding: 7px !important;
//     text-indent: -1px;
//     height: 100vh;
//     border: 1px solid rgba(0, 0, 0, 0.1);
//     border-top: 0px;
// }
@media only screen and (max-width: 1500px) {
  .compant-person-render {
    width: calc(100% - 300px);
    .contact-person-company-editor-wrapper {
      width: 300px !important;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .compant-person-render {
    width: calc(100% - 250px);
    .contact-person-company-editor-wrapper {
      width: 250px !important;
    }
  }
}
.hr-module-wrapper .ant-tabs-tab {
  padding: 10px 15px;
}
.cross-section-advance-filter {
  margin: 5px;
  /* height: 19px; */
}
// .ant-form-item .ant-mentions, .ant-form-item textarea.ant-input {
//     /* height: 100vh; */
//     height: 95vh;
// }

textarea#control-hooks_notes {
  height: 95vh;
}
