.sidebar-width-calc {
  width: calc(100% - 299px);
}
.organizer.fullCalendar.approval-wrapper {
  width: calc(100% - 350px);
  transition: 0.5s;
  &.full-width {
    width: 100%;
  }
}
.approval-leave-wrapper {
  display: flex;
  flex-flow: row wrap;
  .approval-leave-left-wrapper {
    width: 100%;
  }
  .approval-leave-right-wrapper {
    transition: 0.5s;
    &.hide-approval-leave-right-wrapper {
      transform: translateX(350px);
    }
    width: 350px;
    position: FIXED;
    right: 0;
    top: 0;
    height: 100%;
    border-left: 1px solid #ddd;
    .leave-details-header-wrapper {
      background: #fafafa;
      height: 48px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ddd;
      h2 {
        margin: 0px;
        font-size: 16px;
        font-weight: 600;
        color: #06123e;
        padding-left: 10px;
      }
    }
    .leave-details-info-wrapper {
      height: calc(100% - 95px);
      .leave-details-info-items {
        height: 100%;
        .leave-details-info-item {
          height: 100%;
          .single-leave-details-wrapper {
            height: 100%;
            padding: 20px 10px;
            .single-leave-item-wrapper {
              display: flex;
              column-gap: 10px;
              flex-flow: row wrap;
              align-items: center;
              min-height: 35px;
              border-bottom: 1px solid #f5f5f5;
              &:first-child {
                border-top: 1px solid #f5f5f5;
              }
              .single-leave-item-title {
                width: 100px;
                font-size: 13px;
                font-weight: 600;
                color: #000;
              }
              .single-leave-item-info {
                width: calc(100% - 110px);
                font-size: 12px;
                column-gap: 5px;
                .single-supervison-wrapper {
                  display: flex;
                  flex-flow: row wrap;
                  row-gap: 10px;
                  column-gap: 5px;
                  span {
                    background: #f5f5f5;
                    padding: 2px 5px;
                    font-size: 11px;
                  }
                }
              }
            }
          }
          .leave-details-info-form {
            height: 100%;
            form {
              height: 100%;
              overflow-y: auto;
              .drawer-form-row {
                height: 100%;
              }
            }
          }
        }
      }
    }
    label {
      display: block;
      width: 100%;
      text-align: left;
    }
  }
}
.approval-wrapper {
  overflow-y: auto;
}
.application_status_name {
  // border:1px rgb(189, 186, 186) solid;
  color: #fff;
  background-color: #33416e;
  padding: 3.5px 5px;
  border-radius: 12px;
  font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 11px;
  font-weight: 500;
}
.leave-summary-wrapper {
  padding: 0px 7px;
  margin-top: 15px;
  margin-bottom: 20px;
  &.w-75 {
    width: 675px;
  }

  .leave-summary-items {
    .leave-summary-header-items-wrapper {
      .leave-summary-header-items {
        display: flex;
        height: 35px;
        background: #d8ddeb;
        &.text-center {
          .leave-summary-header {
            justify-content: center;
          }
        }
        &.border-right {
          .leave-summary-header {
            border-right: 1px dashed #a5a3a3;
          }
        }
        &.col-5 {
          .leave-summary-header {
            width: calc(100% / 5);
            &:last-child {
              border: 0px;
            }
          }
        }
        &.col-6 {
          .leave-summary-header {
            width: calc(100% / 6);
            &:last-child {
              border: 0px;
            }
          }
        }
        &.col-7 {
          .leave-summary-header {
            width: calc(100% / 7);
          }
        }
        .leave-summary-header {
          display: flex;
          align-items: center;
          padding: 0px 7px;
          &.text-center {
            justify-content: center;
          }
          h2 {
            margin: 0px;
            font-size: 12px;
            font-weight: 600;
            color: #000;
          }
        }
      }
    }
    .leave-summary-details-items-wrapper {
      &.leave-details-items-wrapper {
        height: 300px;
        overflow-y: auto;
      }
      .leave-summary-details-items {
        display: flex;
        cursor: pointer;
        &:hover {
          background: #ddd !important;
        }
        &.active {
          background: #67a1e4 !important;
          color: #fff;
          &:hover {
            background: #67a1e4 !important;
          }
        }
        &:nth-of-type(even) {
          background: #f2f2f2;
        }
        &.text-center {
          .leave-summary-details {
            justify-content: center;
          }
        }
        &.border-right {
          .leave-summary-details {
            border-right: 1px dashed #a5a3a3;
          }
        }
        &.col-5 {
          .leave-summary-details {
            width: calc(100% / 5);
          }
        }
        &.col-6 {
          .leave-summary-details {
            width: calc(100% / 6);
          }
        }
        &.col-7 {
          .leave-summary-details {
            width: calc(100% / 7);
          }
        }
        .leave-summary-details {
          padding: 0px 7px;
          height: 40px;
          display: flex;
          align-items: center;
          &.text-center {
            justify-content: center;
            p {
              text-align: center;
            }
          }
          &:last-child {
            border: 0px;
          }
          p {
            margin: 0px;
            font-size: 12px;
            &.text-green {
              color: green;
            }
            &.text-red {
              color: red;
            }
          }
        }
      }
    }
  }
  .ant-table-wrapper.organizer__table.contact-table.leave-details-table {
    margin: 0px;
  }
  .leave-details-table {
    table {
      thead {
        th {
          background: #d8ddeb;
          padding: 5px 7px !important;
          font-size: 12px;
          font-weight: 600;
          color: #000;
        }
      }
      tbody {
        td {
          padding: 5px 7px !important;
          font-size: 12px;
        }
      }
    }
  }
}
.supervisor-list-wrapper {
  border: 1px solid #ccc;
  min-height: 36px;
  display: flex;
  align-items: center;
  padding: 0px 7px;
  column-gap: 8px;
  span {
    background: #ccc;
    background: #ddd;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 11px;
  }
}
@media only screen and (max-width: 1450px) {
  .sidebar-width-calc {
    width: calc(100% - 275px);
  }
  .leave-summary-wrapper {
    &.w-75 {
      width: 100%;
    }
  }
  .organizer.fullCalendar.approval-wrapper {
    width: calc(100% - 300px);
  }
  .approval-leave-wrapper {
    .approval-leave-right-wrapper {
      width: 300px;
    }
  }
  .sidebars__menu {
    width: 275px;
  }
}
@media only screen and (max-width: 1250px) {
  .sidebar-width-calc {
    width: calc(100% - 230px);
  }
  .organizer.fullCalendar.approval-wrapper {
    width: calc(100% - 250px);
  }
  .approval-leave-wrapper {
    .approval-leave-right-wrapper {
      width: 250px;
    }
  }
  .sidebars__menu {
    width: 230px;
  }
}

.sidebar-width-calc {
  .react-kanban-board {
    .ant-card-bordered {
      min-width: 300px;
    }
    .react-kanban-column {
      div:first-child {
        position: sticky;
      }
      div:last-child {
        max-height: 700px;
        &:hover {
          overflow: auto;
          scrollbar-color: black;
        }
      }
      // div:last-child:hover{
      //     overflow: auto;
      //     scrollbar-color: black;
      // }
    }
  }
}
.sidebar-width-calc {
  .react-kanban-board {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgb(153, 153, 153);
    }
  }
}
.end-data-wrapper {
  display: flex;
  justify-content: center;
  background: #fafafa;
  align-items: center;
  p {
    margin: 0px;
    height: 40px;
    font-size: 16px;
    font-weight: 800;
    display: flex;
    align-items: center;
  }
}

.employee-evaluation-tbl table thead tr th {
  font-size: 13px;
  font-weight: bold;
  background-color: #d8ddeb;
}

.employe-ev-form {
  padding: 0 0.8em;
}

.employe-ev-form .ant-form-item-label {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0.4em 0.4em;
  background: #f6f2f2;
}

.employe-ev-form .ant-form-item-label label {
  display: inline;
}

.employe-ev-form .ant-form-item-control textarea {
  resize: none;
}

.employee-ev-radioGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.employee-ev-radioGroup-wrapper {
  padding: 0 0.8em;
}

.employee-ev-radioGroup-wrapper .ant-form-item-label {
  text-align: left;
}

// .employee-ev-radioGroup label {
//   width: 90px;
// }

.employee-ev-rating {
  font-size: 1.1em;
  font-weight: bold;
}

.employeeEvalutationBtn .ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px;
}

// added
.approve-leave {
  table {
    .ant-table-thead {
      tr {
        th {
          font-weight: 600 !important;
          background-color: #d8ddeb !important;
        }
      }
    }
  }
}
