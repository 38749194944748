.ant-drawer-body {
  padding: 0;
}

.drawer {
  overflow: hidden;

  .contactDetails__label {
    width: 27%;
    padding-left: 15px;
  }
  .contactsDetails__infonotes {
    height: 56px;
    background: #f5f5f5;
    margin-bottom: 3px;
  }
  .contactDetails__labeldata {
    width: 100%;
  }
  .contacts {
    .btn-danger {
      font-size: 13px;
      border: none;
      font-weight: 400;
      color: #d30d21;
      &:hover {
        color: #d30d21;
        border: none;
      }
    }

    &Details {
      &__main {
        margin-bottom: 15px;
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;
      }

      &__info {
        display: flex;
        border: none;

        margin-bottom: 14px;
        font-size: 14px;
      }
    }
  }

  .ant-drawer-close {
    padding: 15px;
    color: $white;
  }

  &__content {
    overflow: auto;
    overflow-x: hidden;
    height: 80%;
    &--wrapper {
      padding-top: 0px !important;
    }

    .color__wrap {
      display: flex;
      align-items: end;

      span.colorTag {
        margin-left: 10px;
      }
    }
  }
  .ant-input,
  .ant-input-number-input,
  .ant-input-number {
    border-radius: 0;
    // height: 26px;

    &:focus,
    &:active {
      box-shadow: none;
      outline: none;
      // border-right-width: 0px !important;
      border-radius: 0;
    }
  }
  &__header {
    color: $white;
    width: 100%;
    height: 40px;
    padding: 0px 9px;
    // background-color: $primary;
    background-color: #d8ddeb;
    display: flex;
    justify-content: left;
    align-items: center;

    &--title {
      font-size: 16px;
      font-weight: 800;
      color: #06123e;
      margin: 0;
      line-height: 1;
    }
  }

  &__footer {
    padding: 3px;
    display: flex;
    margin-top: 39px;
    background-color: $gray;
    justify-content: space-between;
    align-items: center;
    width: 551px;
    position: absolute;
    bottom: 0;
    right: 0;

    &--delete {
    }

    &--update {
      display: flex;
      column-gap: 10px;
      padding-right: 12px;
    }
  }

  .contactsDetails {
    padding: 0px 0;
    width: 100%;
  }

  &.email {
    .drawer__header {
      justify-content: space-between;
    }

    .ant-btn-link {
      color: $white;
      line-height: 1;

      &:hover {
        color: $primary-dark;
      }
    }

    .btn-primary {
      width: auto;
    }

    .anticon-caret-right {
      transform: translateY(1.5px);
    }

    .ant-drawer-close {
      display: none;
    }

    .file {
      input {
        padding: 5px 10px !important;
      }
    }

    .email__drawerflex {
      button {
        margin-left: 10px;
        height: 38px;
        background-color: $gray;
        border-color: $gray;

        &:hover {
          background-color: $primary;
          border-color: $primary;
          color: $white;
        }
      }
    }
  }
}
.custom-search input {
  height: 32px !important;
  line-height: 32px !important;
}
.custom-search input[type="search"] {
  font-size: 15px !important;
  border-radius: 5px !important;
}
.custom-search .ant-input {
  font-size: 15px !important;
}
.custom-search .ant-input::placeholder {
  font-size: 15px !important;
}
.custom-search button {
  background-color: #3879c5 !important;
  border-radius: 1px 5px 5px 1px !important;
}
.drawer .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 29px;
}

#applicantLeave_leaveApplicationTypeId {
  .form__group label span {
    height: 0% !important;
  }
}

.priority-wrapper {
  width: 16%;
  display: flex;
  flex-flow: row wrap;
  justify-content: right;
}
@media only screen and (max-width: 1500px) {
  .priority-wrapper {
    width: 20%;
  }
}
@media only screen and (max-width: 1300px) {
  .priority-wrapper {
    width: 23%;
  }
}

.right-side-popup {
  .gray__form.gray-margin {
    background: #fff;
    padding: 0;
  }
  header.ant-layout-header.drawer__header {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 9px;
  }
  button.ant-drawer-close {
    padding: 0 !important;
    margin: 0 !important;
    height: 35px;
    padding-right: 15px !important;
  }
  .right__notes {
    .contactsDetails__infonotes {
      height: auto;
      background: none;
    }
  }
  .ant-form.ant-form-horizontal {
    .ant-form-item {
      display: flex;
      flex-flow: row wrap;
      .ant-form-item-label {
        width: 100%;
        text-align: left;
        label {
          width: 100%;
        }
        label::after {
          display: none;
        }
      }

      .ant-form-item-control {
        width: 100%;
        .ant-select.ant-select-borderless.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
          background-color: #fff;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
    }
  }
  .side-popup-action-wrapper {
    display: flex;
    justify-content: right !important;
    column-gap: 10px;
    margin-top: 15px;
  }
  .popup-form-wrapper {
    .form__group label {
      margin-bottom: 0px !important;
      font-weight: 500;
    }
    .ant-select.ant-select-multiple.ant-select-allow-clear.ant-select-disabled.ant-select-show-search {
      margin-bottom: 6px;
    }
    .wrapper-row {
      display: flex;
      column-gap: 10px;
      width: 100%;
      .ant-col.ant-col-lg-12 {
        width: 49%;
        max-width: 49%;
      }
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      ):before {
      display: none;
    }
    .ant-col.ant-col-lg-24,
    .ant-col.ant-col-lg-12 {
      padding: 0 !important;
    }
    .ant-form-item-control-input {
      min-height: 35px;
    }
    .ant-picker {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    input {
      margin: 0 !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
    textarea {
      margin: 0px !important;
    }
  }
}
.disable-btn {
  margin-top: 3px !important;
}
.complain-action-wrapper {
  display: flex;
  column-gap: 10px;
  justify-content: right;
  margin-top: 20px;
}
.complain-box-popup {
  .gray__form {
    padding: 0px !important;
    input {
      color: #000;
      height: 40px;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 0px;
    }
    textarea#remarks {
      margin-left: 0px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 40px;
      .ant-select-selection-item {
        line-height: 38px !important;
        color: #000 !important;
        font-size: 14px !important;
      }
      .ant-select-item-option-content {
        height: 20px;
      }
    }
  }
  .add__user__form
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-item,
  .add__user__form
    .ant-select-single.ant-select-show-arrow
    .ant-select-selection-placeholder {
    margin-left: 11px !important;
    font-size: 14px;
    line-height: 38px;
    color: #000;
  }
}
span.anticon.anticon-close svg {
  color: #06123e;
}

.ant-col.complain-action-wrapper.ant-col-lg-24 button {
  margin: 0 !important;
}
.drawer__footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: right;
  column-gap: 10px;
}
.model-footer {
  position: absolute;
  justify-content: left;
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 7px 0px;
  column-gap: 10px;
  // padding-right: 9px;
  padding: 0px 9px;
  background: #f2f2f2;

  bottom: 0;
  button {
    &:first-child {
      margin-left: auto;
    }
  }
}
.ant-drawer-content {
  overflow: hidden;
}
.modal-footer-popup {
  // position: absolute;
  justify-content: left;
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 7px 0px;
  column-gap: 10px;
  padding-right: 9px;
  background: #f2f2f2;

  bottom: 0;
  button {
    &:first-child {
      margin-left: auto;
    }
  }
}
.application_template_code_input {
  .ant-form-item-control-input {
    .ant-input {
      height: 500px;
    }
  }
}
.approval-form-wrapper {
  .ant-drawer-header {
    padding: 8px 9px;
    background: #d8ddeb;
    height: 40px;
    .ant-drawer-close {
      padding: 14px 9px;
    }
    .ant-drawer-title {
      font-size: 16px;
      font-weight: 800;
      color: #06123e;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 33px !important;
  }
}
.global-header-wrapper {
  display: flex;
  height: 48px;
  align-items: center;
  padding: 10px 7px;
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  box-shadow: inset 0px -0.7px 0px rgb(0 0 0 / 15%);
  .header-info {
    h3 {
      margin-bottom: 0px;
      margin-bottom: 0px;
      color: #303030;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .header-action-wrapper {
    margin-left: auto;
    display: flex;
    column-gap: 10px;
    button.ant-btn.global-create-btn {
      background: #67a1e4 !important;
      color: #fff;
      border: 0;
      border-radius: 5px;
      padding: 0px 10px;
    }
  }
}
.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  padding: 0px 7px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.global-custom-box-wrapper {
  overflow-y: auto;
  height: 100%;
  .drawer-form-wrapper {
    height: calc(100% - 98px);
    overflow-x: hidden;
  }
}
.upload_leaveApplication {
  cursor: pointer;
}
.upload_file_loan {
  .form__group {
    span {
      cursor: pointer;
    }
  }
}
.outside_email_class {
  font-size: 14px !important;
  color: #000;
  height: auto;
  margin-bottom: 0px !important;
}

.drawer-form-wrapper {
  overflow-y: auto;
  .drawer-form-row {
    padding: 10px 9px;
    display: flex;
    flex-flow: row wrap;
    row-gap: 7px;
    .drawer-form-group {
      width: 100%;
      .ant-picker {
        padding: 0 8px;
        input {
          text-indent: 0px;
        }
      }
      .ant-input-number.ant-input-number-sm {
        width: 100%;
      }
      .ant-form-item-control-input-content label {
        margin-bottom: 0px !important;
      }

      .drawer-flex {
        display: flex;
        column-gap: 20px;
        align-items: center;
        .ant-row.ant-form-item {
          width: 50%;
        }
      }

      .drawer-flex-tada {
        display: flex;
        column-gap: 20px;
        align-items: center;
        .ant-row.ant-form-item {
          width: 100%;
        }
      }

      .ant-row.ant-form-item.form__group {
        padding: 0px;
      }
      .ant-form-item-control-input {
        min-height: auto;
      }

      span.ant-select-selection-placeholder {
        line-height: 32px !important;
        font-size: 14px !important;
        font-weight: 400;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single
        .ant-select-selector
        .ant-select-selection-placeholder {
        line-height: 32px !important;
        font-size: 12px !important;
        font-weight: 400;
      }
      .ant-form-item-label {
        padding: 0;
        label {
          font-size: 14px !important;
          color: #000;
          height: auto;
          margin-bottom: 0px !important;
        }
      }

      input {
        height: 33px;
        padding: 0;
        &::placeholder {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .drawer-group {
      display: flex;
      width: 100%;
      column-gap: 10px;
      .drawer-left {
        width: calc(100% - 27px);
      }
      .drawer-right span {
        width: 15px !important;
      }
    }
  }
  button.ant-btn.ant-btn-dangerous.ft-delete-btn {
    margin-right: auto;
    margin-left: 10px;
  }
}

.drawer-form-common-wrapper {
  .drawer-form-row {
    padding: 10px 9px;
    .form__group {
      display: flex !important;
      align-items: center !important;
      margin-bottom: 1em !important;
      .ant-form-item-label {
        text-align: left !important;
      }
    }
  }
}
.right-side-popup
  .popup-form-wrapper
  .ant-select.ant-select-multiple.ant-select-allow-clear.ant-select-disabled.ant-select-show-search {
  margin: 0px;
}
.guest-entry-row-wrapper {
  .drawer-form-row {
    padding: 0px;
    margin-bottom: 15px;
  }
}

.main-drawer-wrapper {
  .ant-drawer-body {
    overflow: hidden;
  }
}
.form_email_share {
  .add__user__form {
    padding: 7px;
  }
}

.margin-select-placeholder {
  .ant-select-selection-placeholder {
    margin-left: 7px;
  }
}

.basic-details-tab {
  height: 80vh;
  overflow-y: auto;
}
.sister-company-tab {
  height: 80vh;
  overflow-y: auto;
}
