.Admin__edit__buttons {
  border-radius: 10px;
  border: 1px solid #ceeaf9;
  background: #ceeaf9;
  color: #2a9bdb;
  font-size: 11px;
}

.toggle__arrow {
  transform: translateY(-3px);
  margin-bottom: 10px;
  display: none;
  cursor: pointer;
}

.sidebars {
  display: flex;
  flex-flow:row wrap ;
  background: white;

  &__menu {
    min-height: 100vh;
    position: sticky;
    width: 299px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 1;
    top: 0px;
    &--email {
      position: absolute;
      top: 111px;
      background: #fff;
      left: 0px;
      transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
      z-index: 10;
      // visibility: hidden;
      &:hover {
        // visibility: visible;
        transition: all 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
        // .displayHidden {
        // }
      }
    }
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      // background-image: linear-gradient(180deg, #cef3ff 0%, #ffd9d9 100%);
      background: #ffffff;
      opacity: 0.38;
      z-index: -1;
    }
    .displayHidden {
      visibility: hidden;
    }
    &--header {
      width: 84px;
      height: 24px;
      margin: 12px;
      h4 {
        color: #242424;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
      }
    }

    &--info {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 0 10px;
      margin-bottom: 10px;

      h4 {
        display: block;
        font-size: 20px;
        font-weight: 600;
        color: #303030;
        margin: 0;
        letter-spacing: 1px;
      }

      button.btn-big {
        padding: 8px, 12px;
        height: 28px;
        background: #c53d38;
        border: 0.5px solid #ab3531;
        box-sizing: border-box;
        border-radius: 2px;
        font-size: 10px;
        line-height: 12px;
        color: #ffffff;
        font-weight: 500;
      }
      .btn-refresh {
        background: #ffffff;
        margin-right: 10px;
        border: 0.5px solid #ebebeb;
        box-sizing: border-box;
        padding: 0px;
        line-height: 12px;
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
          0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
        border-radius: 94px;
        height: 24px;
        padding: 14px !important;
        width: 24px;
      }
    }

    &--smallInfo {
      padding: 0 10px;

      button {
        padding: 8px;
      }
    }

    &--items {
      // padding: 0 8px;
      background-color: transparent;
    }

    &--item {
      padding: 6px 16px !important;
      line-height: 30px !important;
      margin: 0 0 6px 0 !important;
      color: #6d6d6d;
      font-size: 13px;
      height: 18px;
      display: flex;
      align-items: center;
      // border-radius: 30px;
      height: 30px !important;
      width: auto !important;
      transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);

      img {
        transition: filter 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
      }

      span {
        // max-width: 128px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &::after {
        border: 0 !important;
      }

      &:hover {
        background-color: rgba($primary, 0.7);
        color: #fff !important;

        img {
          filter: brightness(0) invert(1);
        }
      }
    }

    &ref {
      padding: 0px 10px;

      &--wrap {
        padding: 15px 0 0 0;
        border-top: 1px solid #ddd;
        text-align: left;
      }

      &--items {
        margin-bottom: 0;
        height: 130px;
        overflow-y: scroll;
        padding: 5px 10px 5px 0;
      }

      &--title {
        color: #303030;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      &--item {
        list-style: none;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }

        a {
          background-color: rgba(233, 116, 62, 0.15);
          border-radius: 45px;
          padding: 8px 20px;
          color: #e9733e;
          font-size: 14px;
          display: flex;
          position: relative;
          overflow: hidden;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            width: 0%;
            z-index: -1;
            background-color: #e9733e;
            transition: width 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
          }

          &:hover::before {
            width: 100%;
          }

          &:hover {
            color: $white;
          }
        }
      }
    }

    .ant-menu-item-selected {
      background-color: rgba($primary, 0.7) !important;
      // border-radius: 30px;
      color: #fff !important;
      border: 0;
      height: 30px;
      .badge {
        color: #fff !important;
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
    .ant-menu-submenu-title-selected {
      color: #2563aa;
      background-color: #f5f5f5;
    }
  }

  &.email {
    .side-padding {
      // padding-bottom: 1.5rem;
    }

    .sidebars__menu--items {
      height: 59vh;
      overflow-y: auto;
      overflow-x: hidden;
      min-width: 230px;
      max-width: 230px;

      .sidebars__menu--item {
        justify-content: left;
        margin: 0 !important;
        color: #6d6d6d;
        font-weight: 500;
        padding-left: 0px !important;
        padding-top: 7px !important;
        font-size: 12px;
      }
      .side-drop-item {
        padding-left: 39px !important;
      }
    }
    .sidebars__menu--body {
      height: auto;
      overflow-y: auto;
      min-width: 200px;
      max-width: 200px;
      box-shadow: inset 0px 0px 0px rgba(199, 199, 199, 0.25),
        inset 0px -0.5px 0px rgba(199, 199, 199, 0.25);
      background:#fafafa;
      .sidebars__body--item {
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 0 !important;
        height: 30px;
        color: #6d6d6d;
        padding-left: 35px !important;
        font-weight: 500;
        font-size: 12px;
        padding-right: 12px;
      }
    }

    .sidemenu-scroll {
      // height: calc(100vh - 95px);
      overflow-y: auto;
      box-shadow: 0px 0px 3px rgba(199, 199, 199, 0.5);
    }

    .ant-menu-submenu {
      margin-bottom: 10px;
      box-shadow: inset 0px 0px 0px rgba(199, 199, 199, 0.25),
        inset 0px -0.5px 0px rgba(199, 199, 199, 0.25);
      .ant-menu-submenu-title {
        height: 30px;
        padding: 6px 12px !important;
        line-height: 1;
        border-radius: 30px;
        color: #6d6d6d;
        transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
        position: relative;
        margin: 0;

        span {
          // vertical-align: text-bottom;
          // max-width: 12px;
          // font-size: 12px;
          // overflow: hidden;
          // margin-right: 12px;
          // text-overflow: ellipsis;
          // width: 20px;
          // height: auto;
  
        }

        .badge--notification {
          border: none;
          width: 30px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          color: #ffffff;
          padding: 2px 0;
          text-align: center;
          border-radius: 20px;
          font-weight: bold;
          font-size: 11px;
          color: #67a1e4;
        }

        .ant-menu-submenu-arrow:before,
        .ant-menu-submenu-arrow::after {
          background-image: linear-gradient(90deg, #6c6c6c, #6c6c6c);
        }

        .ant-avatar img {
          transition: filter 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
        }
      }

      .badge--notificationSmall {
        width: 30px;
        height: 13px;
        position: absolute;
        top: 47%;
        right: 0;
        transform: translateY(-50%);
        // background-color: #a2a2a2;
        // border-color: #a2a2a2;
        // color: $black;
        // border-radius: 20px;
        border: none;
        color: #67a1e4;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-menu-submenu-title:hover,
      &.ant-menu-submenu-open .ant-menu-submenu-title {
        background-color: #f5f5f5;
        color: #2563aa;
        border-radius: 0px;
        font-weight: 500;
        font-size: 12px;

        .ant-menu-submenu-arrow:before,
        .ant-menu-submenu-arrow::after {
          background: #2563aa;
        }

        .ant-avatar img {
         
        }
      }
    }

    .ant-menu-submenu > .ant-menu {
      background-color: transparent;
    }

    .ant-menu-submenu ul li img {
      width: 16px;
    }
  }
}

//Sidebar Icons
.sidebars.email {
  .img {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
  .ant-avatar-square {
    width: 25px;
    height: auto;
    margin-right: 10px;

    img {
      object-fit: contain;
      filter: brightness(0.7);

    }
  }

  // .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  // 	right: inherit;
  // 	transform: translateX(-47px);
  // }

  .ant-image {
    line-height: 1;
  }
}

ul.ant-menu.ant-menu-light.sidebars__menu--items.sidebars__menu--body.ant-menu-root.ant-menu-inline li:hover {
  // background: #f2f2f2;
  background: #eaeaea !important;
  color:#6d6d6d !important;
  span{
    img{
      -ms-transform: scale(1.3); /* IE 9 */
      -webkit-transform: scale(1.3); /* Safari 3-8 */
      transform: scale(1.3); 
    }
  }

}
li.ant-menu-item.sidebars__menu--item.side-drop-item.active{
  background:#d8ddeb;
}
ul#sub1\$Menu:hover {
  background: #fff;
}
a.icons__wrapper.active span svg {
  filter: brightness(0);
}