.complaint-list {
	&__wrapper {
		padding: 14px 16px;
		background: #f4f4f4;
		border-radius: 4px;
		margin-bottom: 24px;
		&:last-child {
			margin: 0px;
		}
	}
	&__row {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}

	.col-1-of-2 {
		// width: calc((100% - 0) / 2);
	}
}

.textRight {
	width: 80%;
	margin-right: 6px;
	color: #000000;
	font-weight: bold;

	&--description {
		font-weight: normal;
		color: rgba(0, 0, 0, 1);
		width: calc(100% - 113px);
	}
}
.textLeft {
	width: 20%;
	color: #000000;
	font-weight: normal;

	text-align: right;
	&--icon {
		width: 113px;
		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			background: #3879c5;
			border-radius: 4px;
			background: #3879c5;
			color: #f5f5f5;
		}
		.ant-select-arrow {
			color: white;
		}
	}
}
.flexend {
	display: flex;
	column-gap: 10px;
}
.completion-remarks {
    margin-left: 10px;
	margin-top: 20px;
}
// .drawer-complain-remarks{
// 	height: 92px;
	
// }