.Change__password{
        margin-left: 62px;
    margin-right: 40px;
    margin-top: 74px;


    .Current__password{
        display:flex;
        width:100%;
        height:60px;
        margin:2px 2px 2px 2px;
        flex-direction: row;
    }
    .New__password{

        display:flex;
        height:60px;
        width:100%;
        margin:2px 2px 2px npm2px;
        flex-direction: row;
    }
    .Change__password__label{

        width: 70px;
    height: 17px;
    color: #6d6d6d;
    /* font-family: sans-serif; */
    font-size: 11px;
    font-weight: 550;
    font-style: normal;
    letter-spacing: normal;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    white-space: nowrap;
    margin-right: 40px;
    }
    .change__password__form__submitbutton{
            margin-left: 120px;
    margin-top: 40px;
    }
}
