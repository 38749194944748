.global-internal-message-body {
  .chatmail__header--leftcontent {
    display: flex;
    align-items: center;
    width: 100%;
    .global-chat-hide {
      margin-left: 10px;
    }
  }
  .emailDetails__body--chat.hide {
    top: 48px;
    transition: none;
    height: calc(100% - 48px);
    .chatmail__text {
      font-size: 16px;
      font-weight: 600;
      color: #06123e;
    }
    .emailDetails__body--chatwrap {
      .chat--messageBox {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
    .chat--messageBox {
      &.smallChatHeight {
        bottom: -284px !important;
      }
    }
  }
  .email_internal_chatbox {
    .ant-space-item {
      img {
        cursor: pointer;
        margin-top: 5px !important;
      }
    }
  }
  .chatmail__header--wrapper-hide {
    position: absolute;
    right: 0;
    top: 48px;
    height: calc(100% - 48px);
    background: #f5f5f5;
    .ant-image-img {
      margin-left: 11px;
      width: 17px;
      margin-top: 10px;
    }
  }
  #replyChatBox {
    background: #fff;
  }
}
.globa-chat-wrapper {
  position: relative;
  // height: 100%;
  // overflow: hidden;
  &.showChatHeight {
    height: calc(100% - 330px) !important;
  }
  &.smallChatHeight {
    height: calc(100% - 42px) !important;
  }
  .global-chat-header {
    background: #f5f5f5;
    height: 35px;
    padding: 0px 10px;
    .global-chat-row {
      height: 100%;
      display: flex;
      align-items: center;
      column-gap: 10px;
      .global-message-icon {
        margin-right: auto;
        span {
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }
  }
}
.global-chat-info-wrapper {
  padding: 10px;
  overflow-y: auto;
  // position: absolute;
  height: calc(100% - 240px);
  width: 100%;
  &.showChatHeight {
    height: calc(100% - 241px);
  }
  &.smallChatHeight {
    height: calc(100% - 245px);
  }
  .global-chat-items {
    display: flex;
    flex-flow: row wrap;
    row-gap: 10px;
    .global-chat-item {
      width: 90%;
      padding: 0px 7px;
      padding-top: 5px;
      padding-bottom: 12px;
      &:hover .global-chat-item-wrapper .global-reply-reply-all-btn-wrapper {
        display: block;
      }
      &.left {
        background: #f5f5f5;
        margin-right: auto;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      &.right {
        background: #d6e9ff;
        margin-left: auto;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }
      .global-chat-item-info-header {
        display: flex;
        align-items: center;
        .global-chat-left {
          display: flex;
          width: 60%;
          &:hover .onHover__cc__to_details {
            display: block;
          }
          .global-chat-name-wrapper {
            position: relative;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            span {
              font-size: 14px;
            }
            .global-chat-owner,
            .global-chat-to {
              color: #67a1e4;
            }
            .global-chat-cc {
              color: #6d6d6d;
            }
          }
        }
        .global-chat-right {
          display: flex;
          column-gap: 3px;
          margin-left: auto;
          span {
            font-size: 11px;
            color: #6d6d6d;
          }
          .orderNumber-wrapper {
            display: flex;
            flex-flow: row wrap;
            .orderNumber {
              color: #67a1e4 !important;
            }
          }
        }
      }
      .global-chat-details {
        // overflow: scroll;
        // height: 50px;
        p {
          margin: 0px;
          font-size: 13px;
          line-height: 16px;
          color: #616161;
          width: 94%;
        }
      }
      .global-chat-item-wrapper {
        // height: 85px;
        position: relative;
        .global-reply-reply-all-btn-wrapper {
          position: absolute;
          bottom: -7px;
          right: 2px;
          display: none;
          z-index: 99;
          .global-chat-action-wrapper {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: #fff;
            transition: 0.5s;
            &:hover {
              // height: 55px !important;
              height: 75px !important;
              border-radius: 10px;
              width: 90px;
              animation: fadeIn 0.6s;
              -webkit-animation: fadeIn 0.6s;
              -moz-animation: fadeIn 0.6s;
              -o-animation: fadeIn 0.6s;
              -ms-animation: fadeIn 0.6s;
              opacity: 1;
              padding: 0px;
              overflow: hidden;
              display: block;
              box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
              .global-chat-icon {
                display: flex;
                justify-content: space-between;
                &:hover {
                  background: #f2f2f2;
                  .chatReply-desc {
                    color: #67a1e4;
                  }
                  img {
                    filter: grayscale(100%) sepia(76%) brightness(76%)
                      hue-rotate(167deg) saturate(415%) contrast(102%)
                      invert(3%);
                    -webkit-filter: grayscale(100%) sepia(52%) brightness(125%)
                      hue-rotate(167deg) saturate(411%) contrast(100%)
                      invert(1%);
                  }
                }
                &.none-icon {
                  display: flex;
                  align-items: center;
                }
                .chatReply-desc {
                  display: block;
                }
              }
            }
            .global-chat-icon {
              display: flex;
              align-items: center;
              padding: 0px 7px;
              &::first-child {
                margin-top: 1px;
              }
              &.none-icon {
                display: none;
                transition: 1s;
              }
              .chatReply-desc {
                display: none;
                font-size: 11px;
              }
              img {
                width: 11px;
              }
            }
          }
        }
        .global-attachment-wrapper {
          position: relative;
          height: 20px;
          margin-top: 7px;
          border-top: 1px solid #dccc;
          .email-attachments.quick-msg-attach.chat-attachment-item {
            position: absolute;
            bottom: -5px;
          }
        }
      }
    }
  }
}
.business-chat-wrapper {
  height: calc(100% - 40px);
  &.showChatHeight {
    height: calc(100% - 364px);
  }
  &.smallChatHeight {
    height: calc(100% - 80px);
  }
}
.task-view-wrapper {
  padding: 10px;
}
.global-chat-form {
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 0px 20px;
  width: 100%;
  height: auto;
  .flex-row {
    display: flex;
    flex-flow: row wrap;
    .ant-row {
      width: calc(100% - 55px);
      overflow: hidden;
      input {
        width: 100%;
        border: 1px solid #ddd;
        height: 37px;
        border-radius: 4px;
        outline: none;
        text-indent: 10px;
        padding: 0px !important;
      }
    }
    .global-chat-action-button {
      width: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 37px;
    }
  }
}
.task-view-wrapper {
  position: relative;
  //   height:205px;
  height: 265px;
  overflow-y: auto;
  .flex-row {
    display: flex;
    grid-row-gap: 10px;
    row-gap: 0px;
    flex-flow: row wrap;
    justify-content: space-between;
    .task-info-item {
      width: 49%;
      strong {
        font-size: 14px;
      }
      p {
        font-size: 13px;
        margin-bottom: 5px;
      }
    }
  }
  .task-edit-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    a {
      font: 16px;
    }
  }
}

.task-edit-wrapper {
  margin-left: auto;
  p {
    margin: 0;
    cursor: pointer;
  }
}
.task-view-wrapper .flex-row {
  height: 100%;
  overflow-y: auto;
}
@media only screen and (max-width: 1400px) {
  .global-internal-message-body {
    .global-chat-info-wrapper {
      .global-chat-items {
        .global-chat-item-info-header {
          .global-chat-left {
            width: 58% !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1300px) {
  .global-internal-message-body {
    .global-chat-info-wrapper {
      .global-chat-items {
        .global-chat-item-info-header {
          .global-chat-left {
            width: 50% !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1100px) {
  .global-internal-message-body {
    .global-chat-info-wrapper {
      .global-chat-items {
        .global-chat-item-info-header {
          .global-chat-left {
            width: 40% !important;
          }
        }
      }
    }
  }
}
