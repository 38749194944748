.honor__card {
  border-radius: 5px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  &--image {
  }

  &--details {
    width: 100%;

    h2 {
      font-size: 20px;
      margin-bottom: 0px;
      font-weight: 600;
    }

    p {
      color: rgb(82, 81, 81);
      font-size: 14px;

      &.author {
        color: $black;
      }
    }
  }

  &--wrap {
    margin: auto;
    width: 150px;
  }

  &--circle {
    height: 52px;
    width: 52px;
    border-radius: 50%;
    border: 1px solid #ddd;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.ant-row.honor-row {
  padding: 0px 7px;
  margin-top: 15px !important;
}

.company-table {
  table {
    tbody {
      tr {
        td {
          &:first-child {
            width: 10% !important;
          }
        }
      }
    }
  }
}

.ant-table-wrapper.organizer__table.contact-table.company-bottom-table {
  margin-top: 0px;
}

.guest-entry-row-wrapper {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  padding: 15px 7px;

  .gray__form.gray-margin {
    margin: 0px;
    padding: 0px;
  }

  .guest-entry-left-col-wrapper {
    width: 49%;
  }

  .guest-entry-right-col-wrapper {
    width: 49%;

    .gray__form {
      padding: 10px 0;
    }
  }

  .drawer-form-row {
    padding: 0px;
  }
}

.company-add-update-action-wrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;

  .update-company-action {
    margin-left: auto;
  }

  .company-add-save-btn {
    margin: 0px 10px;
  }
}

.notes-wrapper-col
  .ant-row.ant-form-item.form__group.ant-form-item-has-success {
  padding: 0;
}

.notes-wrapper-col {
  position: fixed;
  top: 0;
  right: 7px;
  width: 100%;
  height: 100%;
  flex: 0 0 20%;
  max-width: 20%;

  .ant-row.ant-form-item.form__group {
    padding: 0;
    height: calc(100% - 71px);

    .ant-col.ant-form-item-control {
      height: 100%;
    }

    .ant-form-item-control-input {
      align-items: inherit;
      height: 100% !important;

      .ant-input-textarea.ant-input-textarea-show-count {
        height: 100%;

        textarea {
          height: 99%;
        }
      }
    }
  }
}

form.ant-form.ant-form-horizontal.hr-email-info-wrapper {
  overflow-y: auto;
}

.office-attachment-wrapper {
  position: relative;

  &:hover {
    .office-attachment-dropdown {
      display: block;
      z-index: 1;
    }
  }

  .office-attachment-length {
    background: #fff;
    box-shadow: 0px 0px 2px 0px;
    padding: 0px 8px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    font-size: 11px;
    height: 15px;
    cursor: pointer;
  }

  .office-attachment-dropdown {
    background: #fff;
    position: absolute;
    top: 18px;
    left: 0px;
    box-shadow: 0px 0px 2px 0px;
    // width: 250px;
    padding: 5px;
    border-radius: 5px;
    min-height: 50px;
    display: none;

    z-index: 99;

    .office-attachment-item {
      display: flex;
      column-gap: 5px;
      height: 17px;

      .anticon.anticon-paper-clip {
        font-size: 12px;
      }

      a {
        display: flex;
        //text-overflow: ellipsis;
        line-height: 14px;
        font-size: 12px;
        width: 100%;
        //overflow: hidden;
        white-space: nowrap;

        .office-attachment-overflow {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // max-width: 80%;
          width: 160px;
        }
      }
    }
  }
}
.stage_attachment_business {
  z-index: 1;
  position: absolute;
  background-color: rgb(233, 233, 233) !important;
  color: black !important;
}

.other-ref-form-wrapper {
  margin-top: 10px;

  .ant-form {
    overflow: hidden;
  }

  .other-ref-items {
    .other-ref-item {
      display: flex;
      column-gap: 20px;

      .global-form-group {
        width: 40%;

        input {
          width: 100%;
        }

        &.other-ref-action-wrapper {
          width: 4%;
          display: flex;
          align-items: end;
          column-gap: 10px;
          button {
            height: 25px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .global-action-btn-wrapper {
    display: flex;
    margin-top: 15px;

    .save-btn {
      margin-left: auto;
    }
  }
}
.other-ref-form {
  &.w-50 {
    width: 50%;
  }
  .global-form-flex {
    padding: 15px 0px;
    flex-flow: row wrap;
  }
}

.task-drawer {
  .ant-drawer-header {
    padding: 16px 14px;
  }
}
