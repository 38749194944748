@font-face {
    font-family: 'sf_pro_displayregular';
    src: url('/assets/images/font/sfprodisplay-light-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
	font-family: 'sf_pro_displayregular';
	src: url('/assets/images/font/sfprodisplay-regular-webfont.woff2') format('woff2'), url('/assets/images/font/sfprodisplay-regular-webfont.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
    font-family: 'sf_pro_displayregular';
    src: url('/assets/images/font/sfprodisplay-medium-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'sf_pro_displayregular';
    src: url('/assets/images/font/sfprodisplay-semibold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
	font-family: 'sf_pro_displayregular';
	src: url('/assets/images/font/sfprodisplay-bold-webfont.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'sf_pro_displayregular';
	src: url('/assets/images/font/sfprodisplay-mediumItalic-webfont.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'sf_pro_displayregular';
	src: url('/assets/images/font/sfprodisplay-regularItalic-webfont.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}
