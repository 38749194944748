.ant-btn-primary {
  background: green !important;
  border-color: green;
  color: #fff !important;
  &:hover {
    background-color: green;
    border-color: green;
    color: $white;
  }
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background: green;
  border-color: green;
}

.organizer__tables .ant-table-tbody > tr > td,
.organizer__tables .ant-table tfoot > tr > td,
.organizer__tables .ant-table tfoot > tr > th {
  padding: 5px;
}

.organizer__tables .ant-table-thead > tr > th {
  padding: 8px;
}

.organizer__tables .ant-table-column-sorters {
  padding: 0;
}

.organizer__tables .ant-table-thead > tr > th {
  background-color: rgba(245, 245, 245, 1);
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #00000012;
}

.organizer__tables {
  .ant-table-body {
    height: 77vh !important;
    overflow-y: scroll;
    cursor: pointer;
  }
}

.organizer__tables .ant-table-tbody > tr > td {
  color: #303030;
  font-size: 11px;
  text-align: center;
}

.ant-btn {
  font-size: 15px;
}

.ant-picker {
  // padding: 6px 11px;
  // border-radius: 5px;
  width: 100%;
}

.ant-pagination {
  margin-top: 20px;
  @media (max-width: 992px) {
    margin-top: 0;
  }
}

.ant-select {
  width: 100%;
}

.organizer__tables .ant-table-cell .ant-tag {
  line-height: 1.5;
}

.organizer__tables > tr > td:first-child {
  width: auto !important;
  min-width: auto !important;
  max-width: 14em;
}

//tabs
.ant-tabs-nav {
  margin: 0 !important;
  &-list {
    //width: 300px;
    .ant-tabs-tab {
      width: 50%;
      margin: 0;
      font-weight: 500;
      color: #6d6d6d;
      transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #1e3fae;
      font-weight: 500;
    }
    .ant-tabs-ink-bar {
      background: #1e3fae;
    }
    .ant-tabs-tab-btn {
      width: 100%;
      text-align: center;
    }
  }
}

.emailInfo .ant-tabs-nav-more {
  display: none;
}
span.ant-select-selection-item {
  font-weight: 500 !important;
}

.organizer__table .ant-table-tbody > tr > td,
.organizer__table .ant-table tfoot > tr > td,
.organizer__table .ant-table tfoot > tr > th {
  padding: 5px;
}

.organizer__table .ant-table-thead > tr > th {
  padding: 8px;
}

.organizer__table .ant-table-column-sorters {
  padding: 0;
}

.organizer__table .ant-table-thead > tr > th {
  text-align: center;
  background-color: #d8ddeb;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  color: #06123e;
  font-weight: bold;
  line-height: 6px;
  white-space: nowrap;
  border-top-right-radius: 0px;
}

.organizer__table .ant-table-tbody > tr > td {
  color: #303030;
  width: 48px !important;
  font-size: 11px;
  font-weight: 300;
  text-align: center;
  border: none;
  // white-space: break-spaces;
  // display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}

// added style for hr module thead
.hr__table .ant-table-tbody > tr > td,
.hr__table .ant-table tfoot > tr > td,
.hr__table .ant-table tfoot > tr > th {
  padding: 5px;
}

.hr__table .ant-table-thead > tr > th {
  padding: 8px;
}

.hr__table .ant-table-column-sorters {
  padding: 0;
}

.hr__table .ant-table-thead > tr > th {
  text-align: center;
  // background-color: #adc6f2;
  background-color: #c3c3c3;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  color: #06123e;
  font-weight: bold;
  line-height: 6px;
  white-space: nowrap;
  border-top-right-radius: 0px;
}

.hr__table .ant-table-tbody > tr > td {
  color: #303030;
  width: 48px !important;
  font-size: 11px;
  font-weight: 300;
  text-align: center;
  border: none;
  // white-space: break-spaces;
  // display: -webkit-box;
  // -webkit-line-clamp: 1;
  // -webkit-box-orient: vertical;
  // overflow: hidden;
}

// .ant-table-thead > tr > th:first-child {
//   width: 8em;
//   min-width: 8em;
//   max-width: 8em;
//   word-break: break-all;
// }

.ant-table-thead > tr > td:first-child {
  width: 8em;
  min-width: 8em;
  max-width: 8em;
  word-break: break-all;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #c7c7c7;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 63%;
}

.ant-row {
  margin: 0px;
}

// tbody tr td:first-child {
// 	width: 8em;
// 	min-width: 8em;
// 	max-width: 8em;
// 	word-break: break-all;
// }
.call_table .ant-table-tbody > tr > td {
  height: 0px !important;
}

// .ant-table .ant-table-tbody > tr > td:first-child {
//   //  width: 8em;
//   //   min-width: 8em;
//   //   max-width: 8em;
//   //   word-break: break-all;
//   width: 14em;
//   // border: none;
//   min-width: 14em;
//   max-width: 14em;
//   word-break: break-all;
// }

// .ant-table
//   .ant-table-tbody

.ant-table .ant-table-thead > tr > td:first-child {
  width: 8em;
  min-width: 8em;
  max-width: 8em;
  word-break: break-all;
}
.leave__table tbody > tr > td:first-child {
  width: auto !important;
  min-width: auto !important;
}
// .organizer__table .ant-table-tbody > tr > td:nth-child(2) {
// 	text-align: left;
// }
// .organizer__table .ant-table-thead > tr > th:nth-child(2) {
// 	text-align: left;
// }
.ant-collapse {
  border: none !important;
}

.ant-collapse > .ant-collapse-item {
  // margin-top: 7px;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border: none;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
  display: flex;
  justify-content: flex-start;
}

//for input in ant design textbox and other
.ant-select-single.ant-select-sm:not(
    .ant-select-customize-input
  ).ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(
    .ant-select-customize-input
  ).ant-select-show-arrow
  .ant-select-selection-placeholder {
  font-size: 12px;
}

// .ant-picker-input>input {
// 	font-size: 11px;
// }
.ant-picker-input > input {
  font-weight: 600;
  font-size: 12px;
}

.ant-select {
  width: 100%;
  font-weight: 600;
  font-size: 12px;
}

.ant-input {
  font-weight: 600;
  font-size: 12px;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: rgba(245, 125, 32, 1);
  border: none;
}
// e="modal--test">
// 			<Modal className="modal
.modal {
  .ant-modal-body {
    padding: 0px;
  }
  .ant-modal-header {
    padding: 0px;
  }
}
.emailList__card--ref {
  display: flex;
  margin-bottom: 5px;
}
.reference-tags {
  border-radius: 8px;
  font-size: 10px;
  display: flex;
  align-items: center;
  line-height: normal;
  border: none;
  color: #007aff;
  margin: 0px;
  padding: 0px;
  justify-content: center;
  width: 100%;
  background: #d8ddeb;
  color: #000;
  padding: 0px 7px;
  height: 12px;
  .anticon[tabindex] {
    cursor: pointer;
    color: red;
    font-size: 7px;
  }
}

.ant-drawer-body {
  //overflow: hidden;
}

.drawer__content--wrapper .tox .tox-editor-container {
  overflow-y: auto;
}
.form__group {
  width: 100%;
}
.ant-form {
  width: 100%;
  padding-bottom: 5px;
}
span.anticon.anticon-ellipsis svg {
  margin-top: 4px;
}
.email-description {
  .p {
    margin-bottom: 0px !important;
  }
}
.ant-modal-body {
  padding: 0px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 3px 32px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}
.ant-upload-list.ant-upload-list-text {
  display: flex;
  align-items: center;
  row-gap: 10px;
  .ant-upload-list-item-info {
    position: relative;
  }
  .ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-text {
    margin: 0;
  }
  .ant-upload-list-item-card-actions {
    position: absolute;
    right: -2px;
  }
  .ant-upload-list-item-name {
    padding-right: 10px;
  }
}

.add__user__form {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0px !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    //margin-left:10px !important;
  }
  input#control-hooks_contactLabelDataValue {
    color: #000;
  }
  span.ant-modal-close-x {
    margin-top: 5px;
  }
}
.ant-select-selector {
  margin: 0px !important;
  padding: 0px !important;
}
.email-title-icon.reference-Mail {
  display: flex;
  height: 23px;
}
.email-title-icon {
  display: flex;
}
.infosMain div span a {
  display: flex;
  align-items: center;
}
.infosMain span {
  // display: flex;
}
.infosMain.email-to.display-none div {
  display: flex;
  align-items: center;
}
.infosMain.email-to div {
  // display: flex;
  line-height: 15px;
}
.emailList__card--desc {
  max-width: 94%;
}
.ant-message div {
  display: flex;
  justify-content: right;
}
.ant-btn-background-ghost.ant-btn-primary {
  padding: 0px !important;
}
// .organizer__table .ant-table-tbody > tr > td,
// .organizer__table .ant-table tfoot > tr > td,
// .organizer__table .ant-table tfoot > tr > th {
//   padding-left: 32px;
// }

.tox .tox-statusbar {
  display: none !important;
}
.tox-tinymce {
  border: 0px !important;
}

.tox .tox-menubar + .tox-toolbar,
.tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
  border-top: 1px solid #e6e6e6 !important;
}
.tox-toolbar__group button:last-child {
  width: 31px;
}
button.tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke {
  width: 70px;
}
.tox-toolbar__group
  button.tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke:first-child {
  width: 115px;
  margin-left: 11px;
  padding: 0px;
  &:hover {
    background: none;
  }
}
.tox-toolbar__group:last-child button {
  width: 41px;
}
button.tox-tbtn.tox-tbtn--enabled {
  width: 31px;
  background: none;
}
.tox-toolbar__group:last-child {
  margin-left: 20px;
}
.ant-btn-group.ant-dropdown-button.email-send-as {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.ant-space-item {
  display: flex;
  align-items: center;
}
.ant-space-item {
  margin-right: 0px !important;
}
.ant-btn-group.ant-dropdown-button.email-send-as button {
  margin: 0px !important;
  padding: 0px !important;
}
.drawer__content--wrapper {
  // position: fixed;
  width: 100%;
  overflow: hidden;
  left: 0;
  top: 50px;
}
header.ant-layout-header.email_compose__header {
  // position: fixed;
  top: 0;
  width: 100%;
}

.drawer__content--wrapper .ant-select-multiple .ant-select-selection-search {
  display: block !important;
  height: 32px !important;
  width: 40% !important;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  height: 100% !important;
}
/** email box body header **/
.test21 div {
  display: flex;
  column-gap: 2px;
}
.infosMain div {
  // display: flex;
  // column-gap: 2px;
}
.to-company-info {
  color: rgb(0, 122, 255);
  // font-weight: bold !important;
}
/** end of email box body header **/
/** pop up check icon hide **/
span.ant-form-item-children-icon {
  display: none;
}
.all-menu-list {
  padding-left: 0px !important;
}
/** end of popup check icon hide **/

.main_content p {
  padding: 0px !important;
  margin: 0px !important;
}

.addTask__form {
  .ant-form-item-explain,
  .ant-form-item-extra {
    clear: both;
    min-height: 0px !important;
    padding-top: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 30px;
}
.ant-form-item-control-input-content input {
  height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 30px;
}
.ant-form-item-control-input {
  min-height: 38px;
}
.ant-popover-title {
  padding: 0px 5px;
  display: flex;
  align-items: center;
}
.ant-input-textarea.ant-input-textarea-show-count textarea {
  margin-left: 0px;
}
.profiledetails {
  overflow: hidden !important;
}
.guest-actions {
  padding: 0px 5px;
}
.reference__view--wrapper {
  padding: 3px 0px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 18px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector:after {
  line-height: 30px;
}
.profiledetails .outline-grayborders {
  padding: 10px;
}
.contactDetails__labeldata span {
  padding: 2px 11px;
  display: block;
}
.profiledetails .infinite-scroll-component p {
  margin-bottom: 0px !important;
  padding: 5px 0px;
}
/** end of business module **/
.chatmail__header--wrapper-hide.chatmail__image {
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgb(216 221 235) !important;
}

li.ant-menu-item.ant-menu-item-only-child.sidebars__menu--item.ant-menu-item-selected {
  color: #06123e !important;
  font-weight: 600;
}
ul.ant-menu.ant-menu-light.sidebars__menu--items.ant-menu-root.ant-menu-inline
  li {
  padding-left: 20px;
}
.ant-menu-submenu-title {
  padding-left: 0px !important;
}

.ant-col.guest__divider.ant-col-lg-24 {
  padding: 0px 7px !important;
}
.ant-row {
  margin: 0px !important;
}
.contactDetails__header .ant-col.ant-col-24 {
  margin: 0px !important;
}
.ant-table-body {
  overflow-y: auto !important;
}
.contactDetails__dropdown {
  background: #c53d38;
  border-radius: 3px;
  color: #fff;
  height: 20px !important;
}
span.anticon.anticon-plus {
  color: #fff;
}
li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.sidebars__menu--item
  a {
  justify-content: inherit;
}
// .ant-form-item .ant-mentions, .ant-form-item textarea.ant-input {
//   height: 30px;
// }
button.ant-btn.contact__header__btn-primary {
  background: #c53d38 !important;
  border-radius: 3px;
  color: #fff;
  border: 0px;
  height: 20px !important;
}
.ant-table-pagination.ant-pagination {
  margin: 12px 0;
}
li.ant-menu-item.sidebars__body--item.active {
  background: #d8ddeb;
}
.ant-input:placeholder-shown {
  padding: 0px;
}
.ant-tooltip {
  max-width: 350px !important;
}

.organizer-sidebar .ant-tabs-tab {
  padding: 5px 0 !important;
  background: #fafafa;
  height: 32px;
}
.contacts .form__groups {
  padding: 0px !important;
}
.search-wrapper {
  display: flex;
  align-items: center;
  padding: 0px 7px;
  width: 100%;
  height: 32px;
  // border-bottom: 1px solid #d9d9d9;
  .ant-input-affix-wrapper {
    background: none;
  }

  input.ant-input {
    border: 0;
    outline: none;
    box-shadow: none;
    background: none;
    margin: 0px !important;
  }
  span.ant-input-wrapper.ant-input-group {
    height: 100%;
    display: flex;
  }
  span.ant-input-affix-wrapper {
    height: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }
  span.anticon.anticon-star {
    border-right: 1px solid rgb(200, 200, 200);
    padding-right: 7px;
    line-height: 20px;
    color: rgb(200, 200, 200);
  }
  svg {
    font-size: 11px;
  }
  button.ant-btn.contact__header__btn-primary {
    padding: 0px 5px;
    height: 24px;
  }
}
.border-b-1 {
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}
.p-0 {
  padding: 0px !important;
}
.ant-table-column-sorters {
  padding: 0px;
}
.ant-checkbox-inner {
  border: 1px solid #999 !important;
}

.size-checkbox-anteds span.ant-checkbox-inner {
  height: 13.5px !important;
  width: 13.1px !important;
  position: absolute;
  margin-top: -5px;
  margin-left: 0.4px;
  border-radius: 2.5px;
  border-color: #817979 !important;
}

.ant-table table {
  width: 100% !important;
}

.ant-collapse-content {
  border: 0px !important;
}
span.anticon.anticon-down {
  margin-right: 0px;
}
td.fc-daygrid-day.fc-day.fc-day-fri.fc-day-today {
  background: #d8ddeb !important;
}

.ant-tooltip-inner {
  background-color: #dbdbdb;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 12px;
  padding: 0px 3px !important;
  display: block;
  text-align: left;
}
.ant-checkbox-checked:after {
  border: 0px;
}
.ant-modal-header {
  padding: 10px 11px;
}
.todo-modal {
  .ant-modal-header {
    display: flex;
    justify-content: center;
    border-radius: 16px !important;
    .ant-modal-title {
      color: #495057 !important;
      font-weight: 700 !important;
    }
  }
  .ant-modal-content {
    border-radius: 16px !important;
  }
  .ant-modal-body {
    .ant-select-borderless {
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
    .modal-footer-popup {
      display: flex;
      justify-content: end;
      background: white !important;
      button {
        backface-visibility: hidden;
        border-radius: 10px;
        border-style: none;
        box-shadow: none;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica,
          Arial, sans-serif;
        font-size: 15px;
        font-weight: 500;
        height: 35px;
        letter-spacing: normal;
        line-height: 1.5;
        outline: none;
        overflow: hidden;
        padding: 1px 20px;
        position: relative;
        text-align: center;
        text-decoration: none;
        transform: translate3d(0, 0, 0);
        transition: all 0.3s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: top;
        white-space: nowrap;
        margin-left: 15px;
        &:first-child {
          background-color: #f3f6f9;
          color: black;
          &:hover {
            background-color: #cfcfcf;
          }
        }
        &:last-child {
          background-color: #33416e !important;
          color: black;
        }
      }
    }
  }
}
.add_task_button {
  margin-top: 10px;
  margin-left: 8px;
}
.ant-modal-close-x {
  width: 35px;
  line-height: 42px;
  font-size: 15px;
}
.ant-modal-footer {
  padding: 10px 11px !important;
}
ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  padding-bottom: 0px !important;
  margin-bottom: 0;
}
.replyChatHeight .ant-form-item-explain.ant-form-item-explain-error {
  display: none;
}
.mail-quick-message .ant-form-item-explain.ant-form-item-explain-error {
  display: none;
}
.ant-tooltip-arrow-content {
  background-color: #dbdbdb !important;
  box-shadow: 0px 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.ant-form-item-explain.ant-form-item-explain-error {
  display: none;
}
.ant-tooltip-inner {
  min-height: auto;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding: 0px !important;
}
.ant-tooltip-arrow-content {
  top: -20px;
}
.dashboards .ant-col {
  padding-left: 0px !important;
}
.dashboards .ant-col:last-child {
  padding-right: 0px !important;
}
button.ant-btn.ant-btn-icon-only.ant-input-search-button {
  border: 0px;
}
textarea {
  padding: 5px 7px !important;
  text-indent: 0px;
}

footer.ant-layout-footer.drawer__footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: right;
  column-gap: 10px;
}
.ant-select-multiple .ant-select-selection-placeholder {
  left: 8px;
}

input {
  padding: 0px !important;
  text-indent: 7px;
  font-size: 12px !important;
  &::placeholder {
    font-size: 12px !important;
  }
}
.w-100 {
  width: 100%;
}
.flex-flow {
  flex-flow: row wrap;
}
.ant-select-multiple .ant-select-selection-search {
  margin-left: 7px !important;
}
.ant-select.ant-select-multiple.ant-select-allow-clear.ant-select-show-search {
  span.ant-select-selection-item {
    margin-left: 7px;
  }
}
textarea.ant-input.ant-input-disabled {
  background: #fff;
}
.mt-10 {
  margin-top: 10px;
}

span.ant-select-selection-placeholder {
  margin-left: 7px !important;
}
.ant-select-selector {
  span.ant-select-selection-placeholder {
    padding-left: 0px !important;
  }
}
.ant-select-selection-search-input {
  text-indent: 0px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 7px;
}
span.ant-select-selection-item {
  padding-left: 7px !important;
}
.ant-col.ant-col-6.inline-quick-email {
  width: 23%;
  flex: 0 0 17%;
  max-width: 25%;
}

.flex-box.quick-addEmail.test {
  margin-top: 10px;
}

.ant-input-disabled {
  text-indent: 0;
  padding: 5px 7px !important;
}

.ant-form-item-has-error textarea,
.ant-form-item-has-error input {
  border: 1px solid red !important;
}

.reference__labeldata.missing-wrapper {
  display: flex;
  button.ant-btn.ant-btn-icon-only {
    display: flex;
    margin-left: 5px;
  }
  span {
    display: flex;
  }
}
.select-zindex {
  z-index: 99;
}

.red-star {
  color: red;
  font-size: 15px;
  margin-left: 2px;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #78b4f3 !important;
}

// .menu__item {
//   height: 25px;
//   line-height: 25px;
// }
// .menu__item__new {
//   height: 20px;
//   line-height: 20px;
// }

.profiledetails .ant-menu-submenu-title {
  height: 20px !important;
  line-height: 20px !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #67a1e4 !important;
}
