.main__body {
  width: 100%;
  z-index: 9;

  background: #fff;
}

.layout {
  background: $white;
  overflow: hidden;
  // height: 'auto';
  // padding: 16px;
  padding-top: 0px;

  .header__all {
    color: #303030;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
  }
}

.ant-select-dropdown {
  max-height: 400px;
  overflow-y: auto;
}
.border-bottom {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 25px;
}
.form__group {
  display: block;
  height: 100%;
  margin: 0;
  margin-bottom: 10px;
  &--pass {
    input {
      padding-left: 10px !important;
    }
  }
  .ant-form-item-label {
    overflow: inherit;
    padding: 0;
  }

  label {
    color: #303030;
    font-size: 14px;
    font-weight: 600;
    height: auto;
    margin-bottom: 6px !important;
    span {
      padding: 1px;
      padding-right: 4px;
      color: #303030;
      font-size: 14px;
      font-weight: 600;
    }

    &::before {
      right: -15px;
      position: absolute;
      top: 0;
    }

    &:after {
      display: none;
    }
  }

  .ant-picker,
  input,
  .ant-select-selector {
    // padding: 0 10px !important;
    border-radius: 0px !important;
    width: 100%;
  }

  .ant-input-password {
    padding: 0 11px 0 10px !important;
    // border-radius: 5px !important;
  }

  .ant-select-selection-placeholder {
    // line-height: 38px !important;
  }

  .ant-input {
    width: 100%;
    border-radius: 0px !important;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;

    // margin-left: 4px;

    // &:hover {
    //     border: 1px solid $primary;
    // }
  }

  &--table {
    color: #a2a2a2;
    tr {
      margin-bottom: 5px;
      &:hover,
      &.active {
        background-color: #cccccc;
      }
    }
    td,
    th {
      text-align: left;
      padding: 8px;
    }
  }
}

.guest-actions.email-action a {
  display: block;
  padding: 5px;
  color: rgba($black, 0.85);
  font-weight: 500;
  transition: all 200ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: $gray;
  }
}

.image-load-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-load-flexs {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  width: 100%;
}

.default-profile-name {
  height: 50px;
  width: 50px;
  background: #67a1e4;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.table-wrapper {
  min-height: 60vh;
  overflow: auto;

  .table {
    margin-bottom: 0;
    width: 100%;

    &__buttons {
      display: flex;

      button {
        width: 25px;
        margin-right: 5px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }

    td {
      color: #303030;
      font-size: 11px;
      text-align: center;
    }

    .AddDecision {
      color: $primary;
      cursor: pointer;

      &:hover {
        background-color: $primary-light;
      }
    }

    th {
      background-color: #486cdf;
      text-align: center;
      color: $white;
      font-size: 12px;
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    thead {
      background-color: $primary;
      tr {
        th {
          white-space: nowrap;

          & > span {
            display: inline-table;
            vertical-align: middle;
            margin-left: 6px;
            margin-top: -3px;
            img {
              display: table;
              height: 12px;
              width: 12px;
              margin: -5px 0;
              //ie
              @media screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                & {
                  line-height: 7px;
                }
              }
              //edge
              @supports (-ms-ime-align: auto) {
                & {
                  line-height: 7px;
                }
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f9f9f9;
        }
        td {
          vertical-align: baseline;
          @media (max-width: 600px) {
            & > div,
            & > div > div {
              width: 100%;
              .react-datepicker-wrapper {
                width: 100%;
              }
              .css-3rh5y4-container {
                max-width: 100% !important;
              }
              input {
                min-width: 100% !important;
              }
            }
            & > button.btn {
              margin-bottom: 5px !important;
            }
          }
          //IE
          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            & {
              vertical-align: middle !important;
            }
          }
          .form-group {
            margin-bottom: 0;
          }
          input {
            padding: 6px 8px;
            &.small-input {
              width: 95px;
            }
            &.calendar-input {
              width: 105px;
            }
          }

          & > .btn {
            font-weight: 500;
            font-size: 13px;
            height: 30px;
            border-radius: 3px;
            padding: 5px 10px;
            i {
              font-size: 13px;
            }
            &:not(:last-child) {
              margin-right: 5px;
            }
            &.btn-outlined-released {
              border: 1px solid #cdcdcd;
              color: #cdcdcd;
              background-color: #fff !important;
            }
            &.btn-primary {
              display: inline-flex;
              align-items: center;
              img {
                margin-right: 2px;
              }
            }
          }

          .btn.btn-primary.btn-outlined-danger img {
            width: 11px;
          }
        }
      }

      @media (max-width: 1023px) {
        tr {
          &.tr-xs {
            background-color: #fff;
            &.td-last-child-xs {
              border-bottom: 1px solid #eee;
            }
            & > td:nth-child(2) {
              font-weight: 700 !important;
            }
          }
          .pill {
            margin-left: 0;
          }
          // td {
          //   display: none;
          // }
          th:first-child,
          td:first-child {
            padding: 10px 5px !important;
            text-align: center;
            img {
              padding: 5px;
              font-size: 10px;
              height: 22px;
              width: 22px;
              line-height: 11px;
              border-radius: 2px;
              background-color: #1daa5f;
              color: #fff;
              &.expand-minus {
                background-color: #e41257;
              }
            }
          }
          td:nth-child(1),
          td:nth-child(2),
          td:nth-child(3) {
            display: table-cell;
          }
        }
      }
      @media (max-width: 1023px) {
        tr {
          &.tr-xs {
            td {
              padding: 5px 15px !important;
              .btn {
                margin-bottom: 5px !important;
              }
            }
          }
        }
      }
      // @media (min-width: 1024px) {
      //   tr {
      //     td:first-child {
      //       display: none;
      //     }
      //     &:hover {
      //       background-color: #f5f5f5;
      //     }
      //     &.tr-xs {
      //       display: none;
      //     }
      //     td:first-child {
      //       display: none;
      //     }
      //   }
      // }
    }
  }

  .pagination {
    border-top: 1px solid #e5e5e5;
    margin-top: 0;
    padding-top: 20px;
  }
}

.filterTooltips {
  .guest-act {
    display: block;
    color: #606060;
    padding: 7px 0px;

    &:hover {
      color: $primary;
    }
  }

  .anticon.anticon-check {
    margin-right: 10px;
    color: #22af77;
  }

  .ant-divider-horizontal {
    margin: 0;
  }
}
.overflow-hidden {
  height: calc(100% - 46px) !important;
  overflow: hidden !important;
}

//loader
.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #ce4233;
  letter-spacing: 0.2em;
  &::before,
  &::after {
    content: "";
    display: block;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}

@keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px;
  }
  50% {
    height: 8px;
    width: 40px;
  }
  100% {
    left: 235px;
    height: 30px;
    width: 15px;
  }
}

.space-None {
  margin: 0px !important;
}

.display-none {
  display: none;
}

// .editor {
//   p {
//     margin: 0px !important;
//   }
// }

.test {
  ol,
  ul,
  dl {
    padding-left: 43px;
  }
}
.replyChat {
  ol,
  ul,
  dl {
    padding-left: 43px;
  }
}
.popUpbuttons {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  order: 2;
  width: 49px;
  margin-top: 5px;

  &--btn-success {
    color: #fff;
    background-color: #449d44;
    border-color: #398439;
    border: none;
    font-size: 12px;
    padding: 2px 5px;
    /* font-size: 12px; */
    line-height: 1.5;
    padding: 0px;
    /* border-radius: 3px; */
    height: 17px;
    width: 17px;
  }
  &--btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    border: none;
    font-size: 12px;
    padding: 2px 5px;
    /* font-size: 12px; */
    line-height: 1.5;
    padding: 0px;
    /* border-radius: 3px; */
    height: 17px;
    width: 17px;
  }
}

.popOver {
  &--title {
    padding: 3px 14px;
    margin: 0;
    font-size: 12px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
  }
  &--content {
    padding: 5px 9px;
  }
}
.ant-popover-inner-content {
  padding: 0px !important;
}

.inbox {
  width: 125px !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.global-form-wrapper {
  .global-form-row {
    padding: 10px 9px;
    display: flex;
    flex-flow: row wrap;
    row-gap: 8px;
    .global-form-group {
      width: 100%;
    }
  }
  input {
    height: 33px;
  }
  select {
    height: 33px;
  }
}
.ant-tooltip-inner {
  .from-email-address {
    display: block;
  }
  .from-company-name {
    display: block;
  }
  .from-name {
    display: block;
  }
}

.ant-picker.ant-picker-small.ant-picker-focused input {
  border: 0px !important;
}
.error-wrapper {
  color: red;
  font-size: 12px;
  font-weight: 600;
}
.add_task_button {
  margin: 20px 0px 10px 8px;
}
.sidebar-width-calc {
  // background-color: #eef0f7;
}
.button_add_task {
  appearance: none;
  backface-visibility: hidden;
  background-color: #33416e;
  border-radius: 10px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter, -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 1px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  margin-left: 15px;
}
//////////////////////////////////////// kanban board css\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
.react-kanban-column {
  margin-left: 20px;
  margin-top: 20px;
}
.spin_loader {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.kanban_task_header {
  display: flex;
  margin-top: 20px;
  .kanban_header {
    margin-left: 20px;
  }
}
// header
.header_top_maincard {
  margin-bottom: 3rem !important;
  margin-right: 1.5rem !important;
  display: flex !important;
  margin-top: -7px;
  width: 200px !important;
}
.text_div_header {
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
  .badge_icon_header-Start {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    --vz-bg-opacity: 1;
    background-color: #1cda4b;
    margin-left: 0.25rem !important;
    vertical-align: bottom !important;
    margin-bottom: 2px;
  }
  .badge_icon_header-On {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    --vz-bg-opacity: 1;
    background-color: #1cc3e0;
    margin-left: 0.25rem !important;
    vertical-align: bottom !important;
    margin-bottom: 2px;
  }
  .badge_icon_header-Completed {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    --vz-bg-opacity: 1;
    background-color: #18ecc9;
    margin-left: 0.25rem !important;
    vertical-align: bottom !important;
    margin-bottom: 2px;
  }
}
.switch_view {
  font-weight: 700;
  font-size: 14px !important;
  color: #495057;
}
//  body board
.ant-card-head-title {
  font-size: 15px !important;
  margin-bottom: 0 !important;
  flex-grow: 1 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #505e6b;
  font-weight: 600;
}
.ant-card-bordered {
  margin-bottom: 20px;
  border: 1px solid #e2dede !important;
}
.task__body {
  .ant-card-bordered {
    border-radius: 10px;
  }
}
.tag_and_assigner_display {
  display: flex;
  align-items: center !important;
  justify-content: space-between;
}
.card-body {
  .card-text {
    color: #878a99 !important;
  }
}
.assigner_display {
  img {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50% !important;
  }
  .avatar-group-item {
    margin-left: -12px;
    border: 2px solid white;
    border-radius: 50%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .assigner_display {
    padding-left: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.tag_display {
  .badge {
    color: #101011;
    background-color: rgb(44 83 215 / 41%);
    padding: 0.15em 0.25em;
    font-size: 0.75em;
    font-weight: 600;
  }
}
.card_menu {
  cursor: pointer;
}
.open_menu_card_title {
  cursor: pointer;
}

.hr_searchOutlined {
  color: #ffffff;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  border-radius: 3px;
  cursor: pointer;
  background: #67a1e4;
}

//card footer
.card_footer {
  margin-top: 20px;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top-style: dashed !important;
  border-top: 1px solid #e2dede;
  .footer_combined {
    display: flex;
    justify-content: space-between;
    // margin-bottom: -5px;
    margin-top: 10px;
    .footer_data_1 {
      color: #878a99 !important;
    }
    .footer_data_2 {
      color: #878a99 !important;
    }
  }
}

.text-align--right {
  text-align: right !important;
}

.odd-row {
  background-color: #f0f0f0;
}

.even-row {
  background-color: #ffffff;
}

// added
.custom-tab-model .ant-tabs-tab {
  width: 80px !important;
}

.circularplanning__tabs--contact .ant-tabs-tab {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.mail__circularcontent--lastUpdated {
  color: red;
  margin-right: 8px;
}
