.fullCalendar {
	background: white;
	height: 100vh;
	overflow: hidden;

	.calender-body {
		background-color: #fff;

		height: 100%;
		border-left: none;
		.calendar__filter {
			&--tabs {
				display: flex;
				align-items: center;
				height: 50px;
				justify-content: space-between;
				border-bottom: 1px solid #eee;
				&--dropdown {
					display: none;
				}
			}
			&--title {
				cursor: pointer;
				.ant-select-selector {
					border: 0px;
				}
				.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
					border-color: transparent;
					border: 0 !important;
					box-shadow: unset !important;
				}
			}
			&--details {
				height: 80vh;
				overflow-y: auto;
			}
			&--list {
				display: flex;
				background: #fafafa;
				min-height: 60px;
				padding: 10px;
				margin-bottom: 15px;
				margin-top: 15px;
			}
			&--date {
				width: 28%;
				border-right: 1px solid #e0e0e0;
				padding-right: 10px;
				margin-right: 10px;
			}
			&--desc {
				width: 72%;
				&-showmore {
					font-size: 12px;
					text-align: right;
					margin-left: 10px;
					border-bottom: 1px dotted #d5d6d6;
				}
			}
		}
	}
}

.ant-select-dropdown.calender__filter--dropdown {
	min-width: 200px !important;
}
.fc .fc-toolbar.fc-header-toolbar {
	margin-bottom: 0.5rem;
	margin-top: 7px;
}
.fc {
	height: 100vh;
}
// .fc-h-event {
//     background-color: #67a1e4 !important;
// 	border-color:#67a1e4 !important;
// }
.ant-btn.calendar-create {
    background: #67a1e4 !important;
    color: #fff !important;
    padding: 0 10px;
    font-size: 13px;
    border-radius: 6px;
    height: 28px;
}

.ant-checkbox-wrapper-checked.private .ant-checkbox-checked .ant-checkbox-inner{
	background-color: #8daadb !important;
    border-color: #8daadb  !important;
}
.ant-checkbox-wrapper-checked.business .ant-checkbox-checked .ant-checkbox-inner{
	border-color:#f4b183 !important;
	background-color: #f4b183 !important;
}
.ant-checkbox-wrapper-checked.public .ant-checkbox-checked .ant-checkbox-inner{
	border-color:#a8d08d !important;
	background-color: #a8d08d !important;
}
.ant-checkbox-wrapper-checked.task .ant-checkbox-checked .ant-checkbox-inner{
	border-color:#cba5e4 !important ; //#e45253 
	background-color:#cba5e4 !important;
}
.fc-event-time {
    color: #111;
}
.fc-day-today {
    background-color: #d8ddeb !important;
}
.fullCalendar{
	.fc-button-group button:hover {
		background: #67a1e4 !important;
		color: #fff !important;
	}
	button.fc-today-button.fc-button.fc-button-primary:hover {
		color: #fff !important;
	}
}
.fc-today-button:hover {
	background: #67a1e4 !important;
	color: #fff !important;
}
.fc-button.fc-button-primary.fc-button-active{
	background: #67a1e4 !important;
	color: #fff !important;
}
.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #bebebe;
}
