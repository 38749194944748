.blog__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: lightgray;
}

#blogDiv {
  // height: "600px",
  // overflowY: "scroll",
  height: 80vh;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  background: #f5f5f5;
}

.blog-img-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.blog-username {
  color: #676363;
  margin-right: 2em;
}
