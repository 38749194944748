.cead-container {
  display: flex;
  background-color: "white";
}

.cead-side-content {
  position: fixed;
  // width: 300px; /* Adjust the width as needed */
  top: 0;
  bottom: 0;
  background-color: #f1f1f1; /* Adjust the background color as needed */
}

.cead-main-content {
  flex-grow: 1;
  // margin-left: 290px; /* Adjust the margin to match the width of the side content */
  overflow-y: auto;
  background-color: #f1f1f1;
  height: 100vh;
}

.cead-date-picker {
  .ant-picker {
    height: 38%;
  }
}

.cead-stage-on-add {
  cursor: pointer;
}

.upload-width {
  .ant-upload-list.ant-upload-list-text {
    width: 800px;
    height: auto;
  }
}

.bid-pointer-cursor {
  cursor: pointer;
}

.cead-details-text {
  font-size: 12;
  color: #777;
}

.cead-details-text-no-data {
  color: #777;
  text-align: center;
  font-size: 14;
}

.an-s-th {
  padding: 5px 10px 5px 15px;
  font-size: 13px;
  // text-align: left;
}

.an-s-td {
  padding: 5px 0px 5px 0px;
  font-size: 12px;
  // text-align: right;
}

.an-sn-hide {
  opacity: 0.25;
  background: rgb(161, 163, 1) !important;
}

.custom-table thead th {
  background-color: black;
  color: white;
}

.cead-s-i {
  text-align: center;
}

.cead-search {
  width: 250px;
  margin: 0 5px 5px 0;
}

.search-fields {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.3s;
  z-index: 0;
  .close-icon {
    cursor: pointer;
  }
}

.search-fields.cead-t-show {
  opacity: 1;
  transform: translateY(0px);
  z-index: 1;
}

.c-w-r {
  margin: 5;
  .c-add-w-r {
    background: #67a1e4;
    margin-bottom: 10px;
    color: white;
    float: right;
  }
  .c-w-r-c-t thead th {
    background-color: #d8ddeb;
    color: #000000;
    font-weight: bold;
  }
}

.c-w-r-f-label {
  padding-bottom: 10px;
  p {
    font-weight: bold;
  }
}

.btn-w-r {
  // float: right;
  text-align: right;
  margin: 15px;
  .btn-w-r-c {
    margin-right: 10px;
  }
}

.acc-s-cell-int {
  text-align: right !important;
}

.w-r-e-b {
  height: 25px;
  float: right;
  margin: 5px 5px 5px 0px;
  background-color: rgb(86, 86, 187) !important;
  div {
    margin-top: -5px;
    color: #fff;
  }
}

.wr--txtarea {
  height: 60px;
  resize: none;
}
