.badge {
	padding: 2px 10px;
	border-radius: 5px;
	margin-right: 6px;
	font-size: 13px;
	border-width: 1px;
	border-style: solid;

	&-normal {
		background-color: #d7433e7a;
	}

	&-complete {
		color: #22af77;
		border-color: #22af77;
		background-color: #22af7769;
	}

	&-gray {
		border-color: #e9e9e9;
		background-color: #e9e9e9;
	}

	&-outline {
		background: #f2f2f2;

		&--default {
			color: #6d6d6d;
			border: 1px solid #e9e9e9;
		}

		&--defaultGreen {
			color: #129f5d;
			border: 1px solid #129f5d9c;
		}

		&--normal {
			color: #d7443e;
			border: 1px solid #d7443e;
		}

		&--active {
			color: #22af77;
			border-color: #22af77;
		}

		&--public {
			color: #1e3fae;
			border: 1px solid #1e3fae;
		}

		&--private {
			color: #e7b426;
			border: 1px solid #e7b426;
		}

		&--high {
			border-color: #ff5c33;
			color: #ff5c33;
		}

		&--lightred {
			border-color: #d7443e;
			color: #d7443e;
		}
	}
}

.grey-bottom {
	border-bottom: 1px solid #d9d9d9;
}
