.appointment-select-form{
    min-height: 100px;
    border: 1px solid #d8d5d5;
    padding: 5px 10px;
    .appointment-candidate{
        border-bottom: 1px solid #d8d5d5;
        padding-bottom: 5px;
        span{
            margin-right: 10px;
        }
    }
}