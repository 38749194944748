//index,js

.businessmodal {
  height: 66vh;

  .ant-modal-body {
    padding: 0px;
  }
  .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header {
    height: 22px;
    // padding-left: 18px;
    background: #d8ddeb;
    border: none;
    font-size: 14px;
    line-height: 1px;
    font-weight: 600;
    padding-top: 12px;
    font-size: 13px;
    font-weight: bold;
    color: #06123e;
    white-space: nowrap;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    overflow-y: auto;
    padding: 0px !important;
    height: 18vh;
    overflow-x: hidden;
    max-height: 80vh;
    min-width: auto;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    float: right;
    margin-top: -14px;
    height: 22px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    margin-top: -22px;
    transform: rotate(-90deg);
    height: 34px;
  }
}

.extensions {
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    height: 22px;
    padding-left: 18px;
    background: #d8ddeb;
    border: none;
    font-size: 14px;
    line-height: 1px;
    font-weight: 600;
    padding-top: 12px;
    font-size: 13px;
    font-weight: bold;
    color: #06123e;
    white-space: nowrap;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
    height: auto;
    overflow-x: hidden;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    float: right;
    margin-top: -14px;
    height: 22px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    margin-top: -22px;
    transform: rotate(-90deg);
    height: 32px;
  }
}
.profiledetails {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;

    @media (max-height: 600px) {
      height: 69vh;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 11px;
    background: #d8ddeb;
    border: none;
    color: #000000;
    font-size: 11px;
    align-items: center;
    border-radius: 4px;

    &--text {
      line-height: 23px;
    }
    &--buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
      /* width: 41px; */
      width: 50px;
      // &:active {
      // 	transform: translateY(-3px);
      // }
      &:hover {
        // box-shadow: ;
      }
    }
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    height: 29px;
    // display: flex;
    // justify-content: end;
    // align-items: center;
    padding-left: 7px;
    background: #d8ddeb;
    // border: 0.5px solid #c7c7c7;
    // box-sizing: border-box;
    border: none;
    font-size: 14px;
    line-height: 8px;
    font-weight: 600;
    font-size: 13px;
    font-weight: bold;
    color: #06123e;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:hover {
      background: #2563aa;
      color: #ffff;
      font-size: 14px;
    }
  }

  .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header {
    height: 30px;
    // display: flex;
    // justify-content: end;
    // align-items: center;
    padding-left: 8px;
    background: #d8ddeb;
    // border: 0.5px solid #c7c7c7;
    // box-sizing: border-box;
    border: none;
    font-size: 14px;
    line-height: 1px;
    font-weight: 600;
    padding-top: 12px;
    font-size: 13px;
    font-weight: bold;
    color: #06123e;
    white-space: nowrap;

    &:hover {
      background: #2563aa;
      color: #ffff;
      font-size: 14px;
    }
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-extra {
    float: right;
    margin-top: -11px;
    height: 22px;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    margin-top: -22px;
    transform: rotate(0deg);
  }
}

.reference {
  height: 28px;
  // border-bottom: 0.5px solid #c7c7c7;
  display: flex;
  padding: 5px;
  padding-top: 6px;
  cursor: pointer;
  // background: #f5f5f5;

  // &:nth-child(even) {
  // 	background-color:blue;
  // }

  &.active {
    background-color: #67a1e4 !important;
  }

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__btns {
    background: $white;
    width: 100%;
    text-align: right;
    margin-top: -2px;

    &--add {
      color: #67a0e2;
      font-size: 12px;
      border: none;
      background-color: transparent;
      font-weight: 400;
      width: 19%;
      padding-left: 0px;
      text-align: right;
      margin-top: -5px;
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0 7px 0px;

    &--contents {
      display: flex;
      flex-direction: row;
      width: 100%;
      white-space: nowrap;
      justify-content: space-between;
    }
  }
  &__body {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    width: auto;
    line-height: 21px;

    &--right {
      font-size: 11px;
      margin-right: 5px;
    }
    &--5main {
      color: #303030;
      // line-height: 13px;
      // font-weight: 600;
      font-size: 10px;
    }
    &--5right {
      color: #303030;
      font-size: 10px;
      line-height: 16px;
      font-weight: normal;
    }
    &--5date {
      font-size: 10px;
      line-height: 21px;
      color: #303030;
    }

    &--8main {
      color: #f57d20;
      line-height: 13px;
      font-weight: 600;
      font-size: 13px;
    }
    &--8right {
      color: #f57d20;
      font-size: 10px;
      line-height: 16px;
      font-weight: normal;
    }
    &--8date {
      color: #f57d20;
      font-size: 10px;
      line-height: 13px;
    }

    &--endmain {
      color: #1f1f1f;
      line-height: 13px;
      font-weight: 600;

      font-size: 13px;
    }
    &--endright {
      color: #1f1f1f;
      font-size: 10px;
      line-height: 16px;
      font-weight: normal;
    }
    &--enddate {
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }

    // &--right,
    // &.fivedays {

    // }
    // &.eightdays {
    // 	color:#F57D20;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;
    // }
    // &.enddays{
    // 	color: #333333;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;

    // }
    // .span{
    // 	color:	#C4314B;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;

    // }
  }
  &__main {
    width: 55%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;

    &--text {
      overflow: hidden;
      width: 38%;
      text-overflow: ellipsis;
    }

    &--middle,
    .span {
      // color: #c4314b;
      color: #303030;
      // line-height: 13px;
      // font-weight: 600;
      // font-size: 13px;
      white-space: break-spaces;
      display: -webkit-box;
      line-height: 19px;
      font-weight: normal;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__end {
    display: flex;
    flex-direction: row;
    // padding-top: 8px;
    white-space: nowrap;

    line-height: 21px;
    width: 49%;
    text-align: left;

    &.noDate {
      align-items: flex-end;
      width: 35%;
      justify-content: center;
      margin-left: 9px;
    }

    &--date,
    .span {
      font-size: 10px;
      margin-right: 2px;
      // line-height: 2px;
      /* display: flex; */
      /* align-items: center; */
      // color: #c4314b;
      color: #303030;
    }
    &--days,
    .span {
      font-size: 10px;
      // line-height: 13px;
      /* display: flex; */
      /* align-items: center; */
      color: #303030;
    }
  }

  //reference view portion
  &__view {
    // background: #fafafa;
    // border: 1px solid #f5f5f5;
    box-sizing: border-box;
    border-radius: 4px;

    &--wrapper {
    }
  }
  &__labeldata {
    width: calc(100% - 183px);
  }
  &__header {
    height: 22px;
    margin-bottom: 6px;
    border-bottom: 0.5px solid #ebebeb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--text {
      color: #3879c5;
      font-size: 12px;
      font-weight: 600;
      padding: 1px 0px 0px 8px;
    }
    &--rank {
      color: #8eb9eb;
      font-size: 11px;
      width: 11px;
      height: 11px;
      text-align: center;
      padding: 1px;
      font-weight: 600;
      margin-right: 6px;
    }
  }
  &__info {
    height: 20px;
    display: flex;

    &.noWrap-content {
      height: auto;
    }

    &--text {
      font-size: 12px;
      display: block;
      margin-top: 13px;
      float: right;
      line-height: 12px;
      color: #f57d20;
      margin-right: 7px;
      cursor: pointer;
    }
  }
  &__label {
    width: 153px;
    margin-right: 10px;
  }

  //ant overwrite
}
//ant tabs overwrite

.referencetabs {
  .ant-tabs-nav-list .ant-tabs-tab {
    margin-right: 19px;
    color: #616161;
    font-size: 15px;
    font-weight: normal;
    height: 51px;
  }
}

//edittender drawer

.edittender {
  height: auto !important;
  background: #f8faf7;
  margin-bottom: 8px;

  // .span.ant-radio {
  // 		padding-right: 8px;
  // 		padding-left: 8px;
  // 		font-size: 12px;
  // 	}

  &__wrapper {
    padding: 10px 10px 10px 10px;
    background: #f3f5f8;
    // margin-bottom: 12px;
  }

  &__header {
    background: #ebebeb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 25px;
    padding: 0px 9px;
    margin-bottom: 10px;
    &--checkbox {
      justify-content: right;
    }
  }
  &__body {
    // padding-top: 15px;

    .contactsDetails__info {
      background: transparent;
    }
  }
  &__primaryText {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #06123e;
    width: 154px;
  }
  &__secondaryText {
    font-size: 10px;
    color: #b3b3b3;
    font-weight: normal;
  }
}

//reference viewTender
.reference {
  &__right {
    display: flex;
    position: relative;

    &--chat {
      @media only screen and (max-width: 960px) {
        width: 425px !important;

        position: absolute;
        height: 95vh;
        right: 0;
        background: rgba(242, 242, 242);
      }
    }
  }
  &Details {
    &__info {
      background-color: #ffffff;
      // margin-bottom: 8px;
      font-size: 14px;
      display: flex;
      height: 100%;
      align-items: center;
      // border-bottom: 0.5px solid #ebebeb;
    }
    &__label {
      width: 153px;
      margin-right: 10px;
    }

    &__labeldata {
      width: calc(100% - 153px);
    }
    &__reference {
      display: flex;
      width: calc(100% - 21vw);
      /* width: 216%; */

      &--add {
      }
      &--revisions {
        background: #f5f5f5;
        height: 24px;
        margin-right: 12px;
        align-items: center;
        display: flex;
        border-radius: 7px;
        justify-content: space-around;
        width: auto;
        padding: 2px, 8px, 2px, 8px;
        padding: 2px 8px 2px 8px;

        &.mailSection {
          height: 15px;
        }
      }
    }

    &--title {
      color: #616161;
      font-weight: normal;
      font-size: 12px;
      padding-left: 8px;
      white-space: nowrap;
      // line-height: 37px;
      text-align: center;
      font-weight: 500;
    }
    &--subtitle {
      color: #000000;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
      font-weight: 600;
      white-space: nowrap;
      &.noWrap-content {
        white-space: normal;
      }
    }
  }
}

//project Pagination

.project {
  height: 29px;
  // border-bottom: 0.5px solid #c7c7c7;
  display: flex;
  padding: 5px;
  padding-top: 6px;
  cursor: pointer;
  background: #ffffff;

  &.active {
    background-color: #67a1e4 !important;
    .project__body--title span {
      color: #fff;
    }
    .project__body--code {
      color: #fff;
    }
    .project__body--title {
      color: #fff;
    }
    .project__main--middle,
    .project__main .span {
      color: #fff;
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0 7px 0px;

    &--contents {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
  &__body {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    width: 32%;
    margin-right: 13px;

    &--right {
      font-size: 11px;
      margin-right: 5px;
    }

    &--code {
      color: #333333;
      font-size: 10px;
      white-space: nowrap;
      line-height: 16px;
      font-weight: normal;
    }

    &--main {
      color: #333333;
      line-height: 16px;
      font-weight: 500;
      font-size: 12px;
    }

    &--date {
      color: #f57d20;
      font-size: 10px;
      line-height: 13px;
    }

    // &--right,
    // &.fivedays {

    // }
    // &.eightdays {
    // 	color:#F57D20;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;
    // }
    // &.enddays{
    // 	color: #333333;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;

    // }
    // .span{
    // 	color:	#C4314B;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;

    // }
  }
  &__main {
    width: 100%;
    &--middle,
    .span {
      width: 100%;
      color: #000000;
      font-weight: normal;
      line-height: 16px;
      font-size: 10px;
      height: auto;
      white-space: break-spaces;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__end {
    width: 28%;
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    padding-right: 5px;

    &--date,
    .span {
      font-size: 10px;
      line-height: 2px;
      color: #333333;
    }
  }
}

.nulldata {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectdetails__main--wrapper {
  padding: 9px 17px 0px 17px;
}

// .test {
// 	@media only screen and (max-width: 600px) {
// 		background: red;
// 		width: 100%;
// 	}
// }

// docs

//reference viewTender
.reference {
  &__right {
    display: flex;
    position: relative;

    &--chat {
      @media only screen and (max-width: 960px) {
        width: 425px !important;

        position: absolute;
        height: 95vh;
        right: 0;
        background: rgba(242, 242, 242);
      }
    }
  }
  &Details {
    &__info {
      background-color: #ffffff;
      // margin-bottom: 8px;
      font-size: 14px;
      display: flex;
      height: 100%;
      align-items: center;
      // height: 23px;
      // border-bottom: 0.5px solid #ebebeb;
    }

    &__labeldata {
      width: calc(100% - 153px);
      line-height: 26px;
    }
    &__reference {
      display: flex;
      width: calc(100% - 21vw);
      /* width: 216%; */

      &--add {
      }
      &--revisions {
        background: #f5f5f5;
        height: 24px;
        margin-right: 12px;
        align-items: center;
        display: flex;
        border-radius: 7px;
        justify-content: space-around;
        width: auto;
        padding: 2px, 8px, 2px, 8px;
        padding: 2px 8px 2px 8px;

        &.mailSection {
          height: 15px;
        }
      }
    }

    &--title {
      color: #616161;
      font-weight: normal;
      font-size: 12px;
      padding-left: 8px;
      white-space: nowrap;
      // line-height: 37px;
      text-align: center;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    &--subtitle {
      color: #000000;
      font-weight: normal;
      font-size: 12px;
      cursor: pointer;
      font-weight: 600;
      white-space: nowrap;
      &.noWrap-content {
        white-space: normal;
      }
    }
  }
}

//project Pagination

.project {
  height: 29px;
  // border-bottom: 0.5px solid #c7c7c7;
  display: flex;
  padding: 5px;
  padding-top: 6px;
  cursor: pointer;
  background: #ffffff;

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0 7px 0px;

    &--contents {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
    }
  }
  &__body {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    width: 32%;
    margin-right: 13px;

    &--right {
      font-size: 11px;
      margin-right: 5px;
    }

    &--code {
      color: #333333;
      font-size: 10px;
      white-space: nowrap;
      line-height: 16px;
      font-weight: normal;
    }

    &--main {
      color: #333333;
      line-height: 16px;
      font-weight: 500;
      font-size: 12px;
    }

    &--date {
      color: #f57d20;
      font-size: 10px;
      line-height: 13px;
    }

    // &--right,
    // &.fivedays {

    // }
    // &.eightdays {
    // 	color:#F57D20;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;
    // }
    // &.enddays{
    // 	color: #333333;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;

    // }
    // .span{
    // 	color:	#C4314B;
    // 	font-size: 12px;
    // 	line-height: 16px;
    // 	font-weight: normal;

    // }
  }
  &__main {
    width: 100%;
    &--middle,
    .span {
      width: 100%;
      color: #000000;
      font-weight: normal;
      line-height: 16px;
      // font-size: 11px;
      height: auto;
      white-space: break-spaces;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  &__end {
    width: 28%;
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    padding-right: 5px;

    &--date,
    .span {
      font-size: 10px;
      line-height: 2px;
      color: #333333;
    }
  }
}

.nulldata {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectdetails__main--wrapper {
  padding: 9px 17px 0px 17px;
}

// .test {
// 	@media only screen and (max-width: 600px) {
// 		background: red;
// 		width: 100%;
// 	}
// }

// Document Newscutting
.news_cutting_info {
  width: 100%;
  padding-top: 5px;
  font-size: 10px;
  padding-bottom: 5px;
  background-color: #d8ddeb;
}
.doc_main_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 4px;
}
.news_cutting_file {
  margin-left: 15px;
  margin-top: 22px;
}
.doc_input_label {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.tender_cutting_form {
  margin-top: 15px;
}
.newsCutting_btn {
  display: grid;
  grid-template-columns: 71px 109px 75px;
  justify-content: end;
  margin-top: 20px;
  column-gap: 10px;
}

.tender_cutting_file {
  margin-left: 18px;
  margin-top: 16px;
  margin-right: 5px;
}
.pdf_container {
  height: 490px;
  margin-top: 8px;
}
// pdf container
.rpv-default-layout__toolbar {
  display: none;
}

.rpv-default-layout__sidebar.rpv-default-layout__sidebar--ltr {
  display: none;
}

// Tender docs
.doc_input_label1 {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.doc_input_label2 {
  display: grid;
  grid-template-columns: 3fr 0.3fr;
}

.upload_tender_file {
  display: flex;
  justify-content: space-between;
}
.tender_file_btn {
  display: grid;
  grid-template-columns: 71px 75px;
  justify-content: end;
  column-gap: 10px;
  margin-top: 5px;
}
.tender_info_details {
  position: relative;
}
.tender_info_details::before {
  content: "";
  position: absolute;
  border: 1px solid rgb(207, 198, 198);
  width: 100%;
  height: 98%;
  top: 10px;
  left: 0px;
  border-top: none;
}
.tender_info_remarks {
  height: 100px;
  margin: 3px 0 6px 8px;
}

/* Defining a custom border on all
            sides except the top side */
.tender_info_details {
  flex-direction: column;

  margin: 20px 2px;
  padding: 8px;
  margin-left: 7px;
}

/* Defining the style of the 
			heading/legend for custom fieldset */
.tender_info_details h1 {
  font: 16px normal;
  margin: -7px -8px 0;
}

/* Using float:left allows us to mimic
			   the legend like fieldset. The 
			   float:right property can also be 
			   used to show the legend on right side */

.tender_info_details h1 span {
  float: left;
}

/* Creating the custom top border to make
				it look like fieldset defining small 
				border before the legend. The width 
				can be modified to change position 
				of the legend */
.tender_info_details h1:before {
  border-top: 1px solid rgb(207, 198, 198);
  content: " ";
  float: left;
  margin: 8px -6px 0 1px;
  width: 16px;
}

/* Defining a long border after the 
			legend, using overflow hidden to 
			actually hide the line behind the 
			legend text. It can be removed
			for a different effect */

.tender_info_details h1:after {
  border-top: 1px solid rgb(207, 198, 198);
  content: " ";
  display: block;
  height: 24px;
  left: 0px;
  margin: 0 1px 0 0;
  overflow: hidden;
  position: relative;
  top: 8px;
}
.tender_cutting_form {
  .ant-checkbox-wrapper .ant-checkbox {
    margin-top: 1px;
    margin-right: 4px;
  }
}

// company
.company_header {
  display: flex;
  justify-content: space-between;
  background-color: #eee;
}
.company_details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
// .company_person {
//   display: grid;
//   grid-template-rows: 37% 63%;
// }
.header_print_btn {
  border: none;
  font-size: 12px;
}

.ant-modal-content {
}
.ant-modal-body {
  padding: 10px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 3px 32px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

// .organizer__table .ant-table-tbody > tr > td,
// .organizer__table .ant-table tfoot > tr > td,
// .organizer__table .ant-table tfoot > tr > th {
//   padding-left: 32px;
// }
.ant-modal-close-x {
  width: 31px;
  line-height: 45px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  padding: 0px;
}

.profiledetails__wrapper .ant-collapse-extra {
  margin-left: auto;
}
.profiledetails__header.main_header_profile_details.custom_header_profile_details {
  position: absolute;
  width: calc(100% - 50px) !important;
  top: 0px;
  z-index: 111;
  padding: 2px 11px;
  border-radius: 0px;
}
.profiledetails__content {
  margin-top: 29px;
}

.contacts .ant-tabs-content-holder {
  position: relative;
}
.contacts .ant-tabs-nav {
  padding: 0px 7px;
  height: 48px;
  background: linear-gradient(0deg, #fafafa, #fafafa), #ffffff;
  box-shadow: inset 0px -0.7px 0px rgb(0 0 0 / 15%);
}
.contacts .ant-tabs-content-holder {
  padding-right: 2px !important;
}
.reference__info {
  height: 22px;
  display: flex;
  line-height: 19px;
}
textarea#control-hooks_description {
  // height: 30px !important;
}

.profiledetails .ant-btn {
  font-size: 14px;
}
.profiledetails
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header:hover {
  background: #67a1e4 !important;
}
.sidebars__menu--item:hover {
  background-color: #67a1e4 !important;
}

.reference.active .reference__body--5right,
.reference.active .reference__body--5main span {
  color: #fff;
}
.reference.active .reference__main {
  color: #fff;
}
.reference.active .reference__body--5date {
  color: #fff;
}

span.remaining-wrapper {
  color: #67a1e4;
}
.other_contact_Details .reference__info {
  height: 16px !important;
}
.other_contact_Details .reference__label {
  width: 139px;
}

.other_contact_Details .reference-edit {
  padding-bottom: 5px;
}
.referenceDetails__labeldata {
  display: flex;
}
.ant-btn.ant-btn-link.btn-edit.bttn-edit {
  transform: rotate(90deg);
  padding: 6px !important;
  width: auto;
}
.btn-edit.bttn-edit {
  margin-left: auto;
}
.action-btn-wrapper {
  display: flex;
  column-gap: 20px;
  justify-content: right;
  margin-top: 10px;
}
.action-btn-wrapper button {
  width: 80px;
  height: 40px;
  font-size: 14px;
}
.saveAction {
  background: green;
  color: #fff;
}

.organizer__table.contact-table.business-table {
  padding: 0px;
}

.popup-wrapper {
  .businessmodal {
    height: auto !important;
    padding: 10px 10px;
    .ant-collapse-content > .ant-collapse-content-box {
      height: auto;
    }
  }
  .ant-modal-footer {
    padding: 10px 10px;
  }
}

.task-panel span {
  font-size: 13px !important;
}
.custom-tabs-wrapper {
  .reference__right--chat {
    .emailDetails__body--chat {
      height: calc(100% - 48px);
      top: 48px;
      background: #fff;
      z-index: 999;
      transition: 0s;
    }
  }

  .global-internal-message-body {
    .chatmail__header--wrapper-hide.chatmail__image {
      position: fixed;
      top: 48px;
      right: 0;
      width: 35px;
      background: #f8f8f8;
      height: calc(100% - 48px);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 10px;
      img.ant-image-img {
        width: auto;
        margin-left: 23px;
      }
    }
    .emailDetails__body--chat {
      height: calc(100% - 48px) !important;
      top: 48px !important;
      transition: none;
      z-index: 999;
    }
  }
  // .emailDetails__body--infos{
  //   .emailDetails__body--chat,.chatmail__header--wrapper-hide.chatmail__image{
  //     top: 160px;
  //     height: calc(100% - 160px);
  //   }
  //   .emailDetails__body--chat.isCC, .chatmail__header--wrapper-hide.chatmail__image.isCC {
  //     top: 174px;
  //     height: calc(100% - 174px);
  //   }
  // }
}
.global-tabs-wrapper {
  .email-internal-hide-show {
    padding-left: 0px !important;
  }
  .summary-wrapper {
    width: calc(100% - 35px);
    padding: 0px 7px;
  }
  .chatOpen {
    width: calc(100% - 543px) !important;
    .custom_header_profile_details {
      width: calc(100% - 559px) !important;
    }
  }
}
@media only screen and (max-width: 1500px) {
  .global-tabs-wrapper {
    .chatOpen {
      width: calc(100% - 403px) !important;
      .custom_header_profile_details {
        width: calc(100% - 419px) !important;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .global-tabs-wrapper {
    .chatOpen {
      width: calc(100% - 402px) !important;
      .custom_header_profile_details {
        width: calc(100% - 417px) !important;
      }
    }
  }
}

.task__form {
  display: flex;
  width: 99.1%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  // border-top:1px rgb(158, 152, 152) solid;
  // border-bottom:1px rgb(158, 152, 152) solid;
  border: 1px rgb(158, 152, 152) solid;
  .add-task-form-item {
    margin-bottom: -7px;
  }
  .add-task-form-item:first-child {
    width: 18%;
  }
  .add-task-form-item:nth-child(2) {
    width: 14%;
  }
  .add-task-form-item:nth-child(3) {
    width: 14%;
  }
  .add-task-form-item:nth-child(6) {
    width: 15%;
  }
  .add-task-form-item:nth-child(7) {
    width: 13%;
  }
  .add-task-form-item:nth-child(8) {
    width: 15%;
  }
  .add-task-form-item.add-update-task-wrapper {
    display: flex;
    column-gap: 5px;
  }
}

@media only screen and (max-width: 1650px) {
  .task__form {
    .add-task-form-item:first-child {
      width: 17%;
    }
    .task__form .add-task-form-item:nth-child(2) {
      width: 13%;
    }
    .task__form .add-task-form-item:nth-child(6) {
      width: 14%;
    }
  }
}

@media only screen and (max-width: 1500px) {
  .task__form {
    .add-task-form-item:first-child {
      width: 16%;
    }
    .task__form .add-task-form-item:nth-child(2) {
      width: 12%;
    }
    .task__form .add-task-form-item:nth-child(6) {
      width: 13%;
    }
  }
}
@media only screen and (max-width: 1250px) {
  .task__form {
    .add-task-form-item:first-child {
      width: 15%;
    }
    .task__form .add-task-form-item:nth-child(2) {
      width: 12%;
    }
    .task__form .add-task-form-item:nth-child(3) {
      width: 13%;
    }
    .task__form .add-task-form-item:nth-child(6) {
      width: 12%;
    }
  }
}

h3.header__all.header__all-details {
  margin-top: 15px;
}
.profiledetails__content {
  margin-top: 35px;
}
span.referenceDetails--subtitle.noWrap-content div {
  line-height: 13px;
}

/** business **/
.full-block {
  width: 100%;
}
.business-form-wrapper {
  margin-top: 20px;
  width: 100%;
  .global-form-flex {
    flex-flow: row wrap;
    .global-form-label {
      width: 200px;
    }
    .global-form-input {
      position: relative;
      width: calc(100% - 200px);
      &.flex {
        display: flex;
        column-gap: 10px;
        .currency {
          width: 100px;
        }
        .budget-number {
          width: calc(100% - 110px);
        }
      }
    }
  }
  .global-form-footer {
    width: 100%;
    display: flex;
    grid-column-gap: 10px;
    column-gap: 10px;
    button.ant-btn.cancleAction {
      margin-left: auto;
    }
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
.project_add_form {
  height: 500px;
  overflow: auto;
}
.business-header {
  background: #d8ddeb;
  padding: 0px 7px;
  .businees-header-title {
    font-size: 14px;
    color: #111;
  }
}
.note-header {
  label {
    background: #f2f2f2;
    padding: 2px 10px;
    font-size: 12px;
    color: #111;
    font-weight: 600;
    border-radius: 4px;
    display: block;
  }
  margin-top: 15px;
  margin-bottom: 15px;
}
ul.search-project-title {
  position: absolute;
  z-index: 11;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
  list-style: none;
  li {
    padding: 5px 15px;
    font-size: 12px;
    &:hover {
      background: #f2f2f2;
    }
  }
}
/** end of business **/
.scope-of-work {
  span.ant-select-selection-item {
    &:first-child {
      margin-left: 7px !important;
    }
  }
}
.business-information-wrapper {
  .business-header {
    background: #d8ddeb;
    padding: 0px 7px;
    height: 26px;
    display: flex;
    align-items: center;
    .business-header-title {
      font-size: 12px;
    }
  }
  .business-tender-project-info-wrapper {
    position: relative;
    padding: 0px 7px;
    .ant-collapse-header {
      background: #d8ddeb;
      display: flex;
      align-items: center;
      height: 27px;
      .ant-collapse-extra {
        margin-left: auto;
      }
      .ant-collapse-arrow {
        top: 9px;
      }
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px 7px;
    }
    .business-tender-project-item {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      min-height: 26px;
      .business-tender-project-title {
        width: 150px;
        span {
          font-size: 12px;
          color: #777;
        }
      }
      .business-tender-project-desc {
        width: calc(100% - 150px);
        position: relative;
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          color: #111;
        }
        .missing-wrapper span {
          font-size: 11px;
        }
        .office-attachment-dropdown {
          display: none;
          background: #fff;
          // top: 22px;
          position: absolute;
          left: 0;
          box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%);
          padding: 2px 10px;
          min-width: 175px;
          border-radius: 4px;
          // top: 17px;
          top: 16px;
          z-index: 1;
        }
        &:hover {
          .office-attachment-dropdown {
            display: block;
            z-index: 1;
          }
        }
        .document-file-length-wrapper {
          background: #fff;
          box-shadow: 0px 0px 2px 0px;
          padding: 0px 8px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          font-size: 11px;
          height: 15px;
          cursor: pointer;
        }
      }
    }
    .business-tender-project-action {
      position: absolute;
      top: 0;
      right: 0;
      button {
        padding: 0px;
        color: #111;
      }
    }
  }
}
.award_status_title {
  background-color: #c4daf3;
}
.business_email_view {
  padding: 5px 25px 5px 25px !important;
  max-height: 700px;
  overflow: scroll;
  .businees_email_title_document {
    background-color: #c8d5e6;
    color: black;
    border-radius: 7px;
  }
  .business-tender-project-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    min-height: 26px;
    .business-tender-project-title {
      width: 150px;
      span {
        font-size: 12px;
        color: #777;
      }
    }
    .business-tender-project-desc {
      width: calc(100% - 150px);
      position: relative;
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
        color: #111;
      }
      .missing-wrapper span {
        font-size: 11px;
      }
      &:hover {
        .office-attachment-dropdown {
          display: block;
          z-index: 1;
        }
      }
    }
  }
}
.business_type_emailView {
  max-height: 800px;
  overflow: scroll !important;
}
.business_type_emailView_smallScreen {
  max-height: 400px;
  overflow: scroll !important;
}

.document-purchase-info-wrapper {
  padding: 7px 7px;
  &:nth-child(even) {
    background: #f4f4f4;
  }
}

.global-form-flex {
  flex-flow: row wrap;
  .global-form-label {
    width: 200px;
  }
  .global-form-input {
    position: relative;
    width: calc(100% - 200px);
    &.flex {
      display: flex;
      column-gap: 10px;
      .currency {
        width: 100px;
      }
      .budget-number {
        width: calc(100% - 110px);
      }
    }
  }
}
.roto-wrapper {
  width: 100%;
  margin-top: 10px;
}

.project-note-wrapper .ant-input-textarea.ant-input-textarea-show-count {
  margin-top: 10px;
}
.business-form-wrapper {
  padding: 0px 7px;
  .global-form-label {
    span.anticon.anticon-minus-circle.contact__btn-danger {
      margin-left: auto;
    }
  }
}
.participation-wrapper {
  background: #ebebeb;
  width: 100%;
  display: flex;
  padding: 0px 7px;
  align-items: center;
  height: 26px;
  .participation-title {
    font-size: 12px;
    span {
      color: #06123e;
      font-weight: 600;
    }
  }
  .checkbox-wrapper {
    margin-left: auto;
  }
}

.participation-company-wrapper button {
  width: auto;
  &:hover {
    background: none;
  }
}

.project-reference {
  background: #67a1e4;
  color: #fff !important;
  padding: 0px 10px;
  border-radius: 4px;
}
.project-reference-star {
  margin-left: auto;
}
.award-form-footer {
  margin-top: 5px;
  text-align: right;
}
.reference_others_listing {
  display: flex;
  justify-content: space-between;
  // .reference_listing_description{
  //   margin-left:-250px;
  // }
}
