
.chat-not-visible-height {
    height: calc(100vh - 150px);
}

.chat-visible-height {
    height: calc(100vh - 427.7px);
}

.groupChatId {
    background: rgb(245, 245, 245);
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.groupchat-message-body {
    // background: #F5F5F5;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.chatgroup-img {
    height: 30px;
    margin-right: 8px;
}

.groupchat-wrapper {
    display: inline-flex;
    align-items: center;
    background-color: #dcdcdc;
    padding: 4px 8px;
    margin: 0 12px;
    cursor: pointer;
}

.groupchat-title {
    font-weight: bold;
    margin: 0;
}


.emailDetails__body--infos-group {
    width: 100%;
    overflow-y: auto;
}

.groupchat-description {
    background: #F5F5F5;
}


.groupchat-message-center {
    // height: 50px;
    height: 64px;
    padding: 0 10px;
    line-height: 1.35;
    // text-align: center;

    .message-row {
        .group-message-title {
            display: inline-block;
            width: 76px;
            font-weight: 600;
        }

        .message-content {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}


// message
.groupchat-msg-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}