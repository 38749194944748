.btn-primary {
  border-radius: 6px;
  font-size: 13px;
  background-color: #67a1e4 !important;
  color: #fff !important;
  border: #67a1e4 !important;
  height: 28px;
  width: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);
  padding: 0px 10px;
  width: auto;
}

.btn-danger {
  border-radius: 6px;
  font-size: 12px;
  background-color: #dc35468c;
  color: #d30d21;
  border: 1px solid transparent;
  height: 26px;
  width: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);

  &:hover {
    background-color: $white;
    color: #d30d21;
    border: 1px solid #dc3546c5;
  }
}

.btn-success {
  border-radius: 6px;
  font-size: 14px;
  background-color: #139e49e5;
  color: $white;
  border: 1px solid transparent;
  height: 26px;
  width: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.35s cubic-bezier(0.65, 0.005, 0.35, 0.995);

  &:hover {
    background-color: $white;
    color: #139e49e5;
    border: 1px solid #139e49e5;
  }
}

.button__label {
  font-size: 14px;
  margin-left: 5px;
  font-weight: 500;
}

.btn-update {
  width: 74px;
  height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  background: #3879c5;
  color: white;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.32),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.28);
  border-radius: 4px;

  &__updateref {
    width: 74px;
    height: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    background: #3879c5;
    color: white;
    background: #3879c5;
    border-radius: 2px;
    margin-left: 10px;
  }
  &:hover {
    background-color: #3879c5;
    color: white;
  }
}

.update1 {
  width: 134px;
}
.btn-cancel {
  width: 74px;
  height: 32px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #242424;
  text-align: center;
  background: white;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.32),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.28);
  border-radius: 4px;
  margin-right: 8px;

  &__cancelref {
    width: 74px;
    height: 32px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #242424;
    text-align: center;
    background: white;
    border: 0.5px solid #c7c7c7;
    box-sizing: border-box;
    border-radius: 2px;
  }

  &:hover {
    background-color: white;
    color: #242424;
  }
}

.btn-edit {
  width: 39px;
  height: 32px;
  border: none;
  color: #1f1f1f;
  font-weight: 500;
  font-size: 14px;
}
.btn-internalChat {
  height: 20px;
  width: 20px;
  display: flex !important;
  padding: 6px;
  border: 0px;
  color: white;
  align-items: center;
  background-color: #67a1e4;
  &:hover {
    background-color: #67a1e4;
    color: white;
  }
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  background-color: transparent;
}
.btn__chat {
  &:hover {
    background: #e6e6e6;
    border-radius: 2px;
  }
  &--attatchment {
    cursor: pointer;
    width: 25px;
    text-align: center;
    &:hover {
      background: #e6e6e6;
      border-radius: 2px;
    }
  }
  &--cancel {
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
    cursor: pointer;
    width: 27px;
    text-align: center;
    &:hover {
      background: #e6e6e6;
      border-radius: 2px;
      transition: 0.7s;
      -webkit-transition: 0.7s;
      -moz-transition: 0.7s;
      -ms-transition: 0.7s;
      -o-transition: 0.7s;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  &--upChervon {
    transition: 0.7s;
    -webkit-transition: 0.7s;
    -moz-transition: 0.7s;
    -ms-transition: 0.7s;
    -o-transition: 0.7s;
    &:hover {
      transition: 0.7s;
      -webkit-transition: 0.7s;
      -moz-transition: 0.7s;
      -ms-transition: 0.7s;
      -o-transition: 0.7s;
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}
.Link {
  text-decoration: none;
}
.calendar-create {
  background: white;
  color: rgba(197, 61, 56, 1);
  font-weight: 600;

  border: none;
  &:link,
  &:visited {
    color: rgba(197, 61, 56, 1);

    //Change for the <button> element
    border: none;
    cursor: pointer;
  }

  &:hover {
    color: rgba(197, 61, 56, 1);
    border: none;
  }
  &:active,
  &:focus {
    background-color: transparent;
    color: rgba(197, 61, 56, 1);
    border: none;
  }
  &::after {
    content: none;
  }
}
.flex-box {
  display: flex;
}
.emailList__card--ref.paginationTag {
  width: auto !important;
}
.paginationTag {
  color: black;
  margin-right: 3px;
  background: #d8ddeb;
  padding: 0px 5px;
  border-radius: 6px;
  font-size: 8px;
  margin-left: 0px;
}

.plusIcon {
  color: #3879c5;
  font-weight: 700;
  font-size: 11px !important;
  display: flex;
  white-space: nowrap;
  /* margin-top: 4px; */
  align-items: center;
}
